import React from 'react'
import { styled } from '@mui/system'
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const BTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.white,
    padding: '8px 12px',
    borderRadius: 8,

    fontSize: '12px',
    lineHeight: '18px',
  },
}))
