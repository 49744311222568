import _ from 'lodash'

/**
 * Deep filters an object by removing fields with values of "" or -1.
 * It does not return keys with empty objects.
 * @param obj The object to filter.
 * @returns A new object with filtered fields.
 */
export function deepFilter(
  obj: Record<string, unknown>
): Record<string, unknown> {
  return _.omitBy(
    _.mapValues(obj, (value) => {
      if (_.isObject(value) && !_.isArray(value)) {
        const filteredValue = deepFilter(value as Record<string, unknown>)
        return _.isEmpty(filteredValue) ? undefined : filteredValue
      }
      return value === '' || value === -1 ? undefined : value
    }),
    _.isUndefined
  )
}
