import React, { useState } from 'react'
import { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg'
import { LoginForm } from '../components/login/LoginForm'
import { LoginDto } from '../types/auth/login.dto'
import { useAppDispatch } from '../hooks/store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loginUser } from '../store/action/auth'

export const LoginPage: FC = () => {
  const [isValidCredential, setIsValidCredential] = useState<boolean>(true)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const redirectTo = searchParams.get('to')
  const userId = searchParams.get('userId')

  const handleSubmit = async (payload: LoginDto) => {
    const res = await dispatch(loginUser(payload))
    if (res.meta.requestStatus === 'rejected') {
      setIsValidCredential(false)
    }
    if (res.meta?.requestStatus === 'fulfilled') {
      const redirectPath = redirectTo
        ? `${redirectTo}?userId=${userId}`
        : '/employee'
      navigate(redirectPath)
    }
  }

  const handleChange = () => setIsValidCredential(true)

  return (
    <Box
      sx={{
        pt: '120px',
        height: 500,
        margin: '0 auto',
        width: '100%',
        maxWidth: '360px',
        [theme.breakpoints.down('sm')]: {
          pt: '60px',
        },
      }}
    >
      <LogoIcon />
      <Box>
        <Typography
          mt={10}
          mb={1.5}
          color="text.primary"
          fontWeight={600}
          variant={'h4'}
          sx={{
            [theme.breakpoints.down('sm')]: {
              mt: '60px',
            },
          }}
        >
          Sign in
        </Typography>
        <Typography color="text.tertiary_600" variant={'subtitle1'}>
          Welcome back! Please enter your details.
        </Typography>
        <LoginForm
          isValidCredential={isValidCredential}
          setIsValidCredential={handleChange}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  )
}
