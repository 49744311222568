import React from 'react'

import { FC } from 'react'
import { Box, Typography } from '@mui/material'

type BHeadingProps = {
  title: string
  subTitle?: string
}
export const BHeading: FC<BHeadingProps> = ({ title, subTitle }) => {
  return (
    <Box>
      <Typography
        fontWeight={600}
        fontSize={30}
        lineHeight={'38px'}
        color={'text.primary'}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography mt={0.5} variant={'body1'} color={'text.tertiary_600'}>
          {subTitle}
        </Typography>
      )}
    </Box>
  )
}
