import apiClient from '../utils/api-client'
import { PartialApiUser, User } from '../types/user/user'
import { deepFilter } from '../utils/deep-filter'
import { FileService } from './file.service'
import { AxiosResponse } from 'axios'
import { GetUserParams } from '../types/user/get-user-params'
import { GetCountUserByTypeDto } from '../types/user/get-count-user-by-type'
import { UserDropDown } from '../types/user/user-drop-down'
import { mapFlatUserToUser, mapUserToFlatUser } from '../types/user/user.mapper'
import { PaginationResponse } from '../types/common/pagination-response'
import { FlatUser } from '../types/user/flat-user'
import { UploadUserFile } from '../types/user/upload-user-file'

export class UserService {
  static async getAll({
    signal,
    filter,
    searchQuery,
    paginationModel,
    sortModel,
  }: GetUserParams) {
    const queryParams = {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortField: sortModel[0]?.field,
      sortOrder: sortModel[0]?.sort,
      filter,
      searchQuery,
    }

    const { data } = await apiClient.get<
      AxiosResponse<PaginationResponse<User>>
    >('/users', {
      params: queryParams,
      signal: signal,
    })

    return {
      rows: data.rows?.map((user) => mapUserToFlatUser(user)),
      count: data?.count,
    }
  }

  static async getCountByType() {
    const { data } = await apiClient.get<AxiosResponse<GetCountUserByTypeDto>>(
      '/users/count-by-type'
    )
    return data
  }

  static async getDropDown() {
    const { data } =
      await apiClient.get<AxiosResponse<UserDropDown[]>>('/users/drop-down')
    return data
  }

  static async getById(id: number | string) {
    const { data } = await apiClient.get<AxiosResponse<User>>(`/users/${id}`)
    return mapUserToFlatUser(data)
  }

  static async create(data: Partial<FlatUser> & { avatar?: File }) {
    const filteredData: Partial<FlatUser> & { avatar?: File } = deepFilter(data)

    if (filteredData?.avatar) {
      filteredData.profilePictureUrl = await FileService.upload(
        filteredData.avatar,
        'public'
      )
    }

    const payload = mapFlatUserToUser(filteredData)

    const response = await apiClient.post<AxiosResponse>('/users', payload)
    return response.data
  }

  static async update(id: number | string, data: Partial<PartialApiUser>) {
    const response = await apiClient.patch<AxiosResponse>(`/users/${id}`, data)
    return response.data
  }

  static async uploadFile(data: UploadUserFile, userId: string) {
    const response = await apiClient.post<AxiosResponse>(
      `/users/upload/${userId}`,
      data
    )
    return response.data
  }
}
