import { FC, HTMLInputTypeAttribute, useEffect } from 'react'
import * as React from 'react'
import { StyledTextField } from '../../../TextFiled/TextField.styled'
import {
  textLeftAdornment,
  textLeftAdornmentStyles,
} from '../../FormInput/TextLeftAdornment'
import * as Yup from 'yup'
import { EditActions } from './EditActions'

export type InteractiveFieldInputProps = {
  name: string
  type?: HTMLInputTypeAttribute
  onSave: (data: string) => Promise<void>
  onCancel: () => void
  placeholder: string
  startAdornment?: string
  initialValue: string
  validationSchema?: Yup.Schema<unknown>
  error: string | null
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  setError: React.Dispatch<React.SetStateAction<string | null>>
  index?: number
  multiline?: boolean
}

export const InteractiveFieldInput: FC<InteractiveFieldInputProps> = ({
  placeholder,
  startAdornment,
  type = 'text',
  onSave,
  onCancel,
  initialValue,
  error,
  value,
  setValue,
  setError,
  multiline,
}) => {
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue, setValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (setError) {
      setError(null)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !multiline) {
      onSave(value)
    }
  }

  return (
    <StyledTextField
      fullWidth
      size="small"
      placeholder={placeholder}
      error={Boolean(error)}
      helperText={error}
      value={value}
      onChange={handleChange}
      type={type}
      autoComplete={type}
      onKeyDown={handleKeyDown}
      multiline={multiline}
      sx={{
        ...(startAdornment ? textLeftAdornmentStyles : undefined),
      }}
      InputProps={{
        ...(startAdornment && textLeftAdornment(startAdornment)),
        endAdornment: (
          <EditActions onSave={() => onSave(value)} onCancel={onCancel} />
        ),
      }}
    />
  )
}
