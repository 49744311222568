import { Box, styled } from '@mui/material'

export const BTabBadge = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  border: '1px solid',
  padding: '2px 8px',
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: 500,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.border?.primary,
}))
