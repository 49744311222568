import { Pagination, styled } from '@mui/material'

export const BPaginationStyled = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    margin: 0,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary_600,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.icon?.hover,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme?.appShadows?.secondary_grey_focus,
      },
    },
  },
  '& .MuiPagination-ul': {
    gap: '2px',
  },
  '& .MuiPaginationItem-ellipsis': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
