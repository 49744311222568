import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const AuthLayoutContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100vh',
  display: 'flex',
}))

export const AuthLayoutContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    minWidth: '640px',
  },
}))

export const AuthLayoutImageBox = styled(Box)({
  flex: 1,
  height: '100%',
  '& img': {
    height: '100%',
    objectFit: 'cover',
  },
})
