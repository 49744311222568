import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationResponse } from '../../types/common/pagination-response'
import { SalesCall } from '../../types/sales/sales-call'
import {
  getAllSalesCall,
  getDefaultSalesCallValues,
  getSalesAnalyzeCriteria,
  getSalesCallById,
  getSpeechToTextOptions,
} from '../action/sales-call'
import { Option } from '../../types/common/option'
import { GetDefaultCallValuesDto } from '../../types/sales/get-default-call-values-dto'
import { AnalyzeCriteria } from '../../types/sales/analyze-criteria'

interface Slice {
  list: SalesCall[]
  count: number
  speechToTextOptions: Option[]
  defaultCallValues: GetDefaultCallValuesDto | null
  salesCall: SalesCall | null
  analyzeCriteria: AnalyzeCriteria[] | null
}

const initialState: Slice = {
  list: [],
  count: 0,
  speechToTextOptions: [],
  defaultCallValues: null,
  salesCall: null,
  analyzeCriteria: null,
}

export const salesCallSlice = createSlice({
  name: 'salesCall',
  initialState,
  reducers: {
    clearSalesCall: (state) => {
      state.salesCall = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllSalesCall.fulfilled,
        (state, { payload }: PayloadAction<PaginationResponse<SalesCall>>) => {
          state.list = payload.rows
          state.count = payload.count
        }
      )
      .addCase(
        getSpeechToTextOptions.fulfilled,
        (state, { payload }: PayloadAction<Option[]>) => {
          state.speechToTextOptions = payload
        }
      )
      .addCase(
        getDefaultSalesCallValues.fulfilled,
        (state, { payload }: PayloadAction<GetDefaultCallValuesDto>) => {
          state.defaultCallValues = payload
        }
      )
      .addCase(
        getSalesCallById.fulfilled,
        (state, { payload }: PayloadAction<SalesCall>) => {
          state.salesCall = payload
        }
      )
      .addCase(
        getSalesAnalyzeCriteria.fulfilled,
        (state, { payload }: PayloadAction<AnalyzeCriteria[]>) => {
          state.analyzeCriteria = payload
        }
      )
  },
})
export const { clearSalesCall } = salesCallSlice.actions
export default salesCallSlice.reducer
