import DouLogo from '../assets/svg/dou.svg'
import LinkedinLogo from '../assets/svg/linkedin.svg'
import DjinniLogo from '../assets/svg/djinni.svg'

export const RECRUIT_SOURCE_OPTIONS = [
  {
    value: 'DOU',
    image: DouLogo,
  },
  {
    value: 'LinkedIn',
    image: LinkedinLogo,
  },
  {
    value: 'Djinni',
    image: DjinniLogo,
  },
  {
    value: 'Other',
  },
]
