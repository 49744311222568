import { PartialApiUser, User } from './user'
import { FlatUser } from './flat-user'
import { CompensationType } from '../compensation/compensation.enum'

export const mapFlatUserToUser = (
  flatUser: Partial<FlatUser>
): Partial<PartialApiUser> => {
  return {
    id: flatUser.id ? Number(flatUser.id) : undefined,
    email: flatUser.email,
    role: flatUser.role,
    profile: {
      user_id: flatUser.id ? Number(flatUser.id) : undefined,
      first_name: flatUser.firstName,
      last_name: flatUser.lastName,
      birthday: flatUser.birthday,
      profile_picture_url: flatUser.profilePictureUrl,
      bucket_url: flatUser.bucketUrl,
      position_level: flatUser.positionLevel,
      job_title: flatUser.jobTitle,
      phone_number: flatUser.phoneNumber,
      linkedin_url: flatUser.linkedinUrl,
      telegram: flatUser.telegram,
      additional_details: {
        emails: flatUser.additionalContactEmails,
        phone_numbers: flatUser?.additionalContactNumbers,
      },
      address: flatUser.address,
    },
    hiring_info: {
      user_id: flatUser.id ? Number(flatUser.id) : undefined,
      employment_type: flatUser.employmentType,
      recruitment_source: flatUser.recruitmentSource,
      payment_details: flatUser.paymentDetails,
      start_work_date: flatUser.startWorkDate,
      end_work_date: flatUser.endWorkDate,
      probation_end_date: flatUser.probationEndDate,
      report_to_id: flatUser.reportToId
        ? Number(flatUser.reportToId)
        : undefined,
      hr_id: flatUser.hrId ? Number(flatUser.hrId) : undefined,
      recruiter_id: flatUser.recruiterId
        ? Number(flatUser.recruiterId)
        : undefined,
    },
    compensation: {
      id: flatUser.compensationId ? Number(flatUser.compensationId) : undefined,
      user_id: flatUser.id ? Number(flatUser.id) : undefined,
      amount: flatUser.compensationAmount
        ? Number(flatUser.compensationAmount)
        : undefined,
      type: flatUser.compensationType,
      description: flatUser.compensationDescription,
      start_date: flatUser.compensationStartDate,
      end_date: flatUser.compensationEndDate,
    },
    project: {
      project_id: flatUser.projectId ? Number(flatUser.projectId) : undefined,
      project_role: flatUser.userProjectRole,
      start_date: flatUser.userProjectStartDate,
      end_date: flatUser.userProjectEndDate,
    },
  }
}

export const mapUserToFlatUser = (user: User): FlatUser => {
  const {
    id,
    email,
    profile,
    hiring_info,
    projects,
    compensations = [],
    role,
  } = user
  const latestProject = [...projects]?.reverse()?.[0]
  const latestCompensation = [...compensations]
    ?.reverse()
    .find(({ type }) => type === CompensationType.Salary)

  return {
    id: id.toString(),
    role,
    email,
    firstName: profile?.first_name ?? '',
    lastName: profile?.last_name ?? '',
    birthday: profile?.birthday,
    profilePictureUrl: profile?.profile_picture_url,
    bucketUrl: profile?.bucket_url ?? '',
    positionLevel: profile?.position_level,
    jobTitle: profile?.job_title ?? '',
    phoneNumber: profile?.phone_number,
    linkedinUrl: profile?.linkedin_url,
    telegram: profile?.telegram,
    additionalContactEmails:
      (profile?.additional_details?.emails as Array<string>) ?? [],
    additionalContactNumbers:
      (profile?.additional_details?.phone_numbers as Array<string>) ?? [],
    address: profile?.address,

    employmentType: hiring_info?.employment_type,
    recruitmentSource: hiring_info?.recruitment_source,
    paymentDetails: hiring_info?.payment_details,
    startWorkDate: hiring_info?.start_work_date,
    endWorkDate: hiring_info?.end_work_date,
    probationEndDate: hiring_info?.probation_end_date,
    reportToId: hiring_info?.report_to_id?.toString(),
    hrId: hiring_info?.hr_id?.toString(),
    recruiterId: hiring_info?.recruiter_id?.toString(),

    compensationId: latestCompensation?.id.toString() ?? '',
    compensationAmount: latestCompensation?.amount,
    compensationType: latestCompensation?.type,
    compensationDescription: latestCompensation?.description ?? '',
    compensationStartDate: latestCompensation?.start_date,
    compensationEndDate: latestCompensation?.end_date,

    projectId: latestProject?.id?.toString() ?? '',
    projectName: latestProject?.name,
    projectDescription: latestProject?.description,
    projectStartDate: latestProject?.start_date,
    projectEndDate: latestProject?.end_date,
    projectBucketUrl: latestProject?.bucket_url,
    projectClientId: latestProject?.client_id?.toString(),
    userProjectRole: latestProject?.UserProject?.project_role,
    userProjectStartDate: latestProject?.UserProject?.start_date,
    userProjectEndDate: latestProject?.UserProject?.end_date,
  }
}
