import { FC } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'
import { Grid, SxProps, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DoneIcon from '@mui/icons-material/Done'

type CountryItemProps = {
  countryCode: string
  phoneCode: string
  status?: 'open' | 'closed' | 'selected'
  onClick?: React.MouseEventHandler<HTMLDivElement>
  sx?: SxProps
}

export const CountryItem: FC<CountryItemProps> = ({
  onClick,
  status,
  countryCode,
  phoneCode,
  sx,
}) => {
  return (
    <Grid sx={sx} container alignItems={'center'} onClick={onClick}>
      <Box
        mr={1}
        width={20}
        height={20}
        borderRadius={'50%'}
        overflow={'hidden'}
      >
        <img
          loading="lazy"
          width="100%"
          height="100%"
          srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
          alt=""
        />
      </Box>
      <Typography
        mr={'auto'}
        fontSize={16}
        lineHeight={'24px'}
        color="textSecondary"
      >
        {countryCode} +{phoneCode}
      </Typography>

      {status === 'open' && (
        <KeyboardArrowUpIcon
          fontSize={'small'}
          sx={{ color: 'icon.fg_quinary_400' }}
        />
      )}
      {status === 'closed' && (
        <KeyboardArrowDownIcon
          fontSize={'small'}
          sx={{ color: 'icon.fg_quinary_400' }}
        />
      )}
      {status === 'selected' && (
        <DoneIcon fontSize={'small'} sx={{ color: 'button.primary_bg' }} />
      )}
    </Grid>
  )
}
