import { useAppDispatch, useAppSelector } from './store'
import { useEffect, useMemo } from 'react'
import { getAllProject } from '../store/action/project'
import { getDropDownData } from '../store/action/user'
import { projectListSelector } from '../store/selector/project.selector'
import { userDropDownSelector } from '../store/selector/user.selector'

export const useGetUserOptions = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getDropDownData())
    dispatch(getAllProject({ paginationModel: { pageSize: 8, page: 0 } }))
  }, [dispatch])

  const users = useAppSelector(userDropDownSelector)
  const projects = useAppSelector(projectListSelector)

  const userOptions = useMemo(
    () =>
      users.map(({ profile, id }) => ({
        value: id.toString(),
        image: profile?.profile_picture_url ?? 'image',
        label: `${profile?.first_name} ${profile?.last_name}`,
      })),
    [users]
  )

  const projectOptions = useMemo(
    () =>
      projects.map(({ name, id }) => ({
        value: id.toString(),
        label: name,
      })),
    [projects]
  )

  return {
    userOptions,
    projectOptions,
  }
}
