import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { FormInput } from '../FormInput'
import { format, isValid, parseISO } from 'date-fns'

type DayInputProps = {
  endDate: string
  name: string
  width?: string
  label: string
}

export const DayInput: FC<DayInputProps> = ({
  name,
  endDate,
  label,
  width = '100px',
}) => {
  return (
    <Box
      gap={'14px'}
      width={'fit-content'}
      display={'flex'}
      alignItems={'flex-end'}
    >
      <FormInput
        label={label}
        name={name}
        placeholder={'xx'}
        type={'number'}
        sx={{
          width,
          '& .MuiFormHelperText-root': {
            ml: '0',
            width: 'max-content',
          },
        }}
      />
      <Box p={'12px 0'}>
        <Typography
          color={'icon.fg_quinary_400'}
          variant={'subtitle1'}
          lineHeight={'24px'}
        >
          {isValid(parseISO(endDate)) ? format(endDate, 'MM/yyyy') : ''}
        </Typography>
      </Box>
    </Box>
  )
}
