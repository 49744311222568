import apiClient from '../utils/api-client'
import { GetAllProjectDto } from '../types/project/get-all-project.dto'
import { AxiosResponse } from 'axios'
import { GridPaginationModel } from '@mui/x-data-grid'

export class ProjectService {
  static async getAll({
    paginationModel,
  }: {
    paginationModel: GridPaginationModel
  }) {
    const queryParams = {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    }

    const { data } = await apiClient.get<AxiosResponse<GetAllProjectDto>>(
      '/projects',
      {
        params: queryParams,
      }
    )
    return data
  }
}
