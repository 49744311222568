import { styled } from '@mui/system'
import { Box, SvgIcon } from '@mui/material'

export const FileUploadWithLoaderContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border?.secondary}`,
  borderRadius: '12px',
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  gap: 12,
  boxSizing: 'border-box',
  overflow: 'hidden',
  position: 'relative',
  '&:hover': {
    borderColor: theme.palette.border?.brand,
  },
}))

export const FileUploadWithLoaderProgress = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  zIndex: 0,
  left: 0,
}))

export const FileUploadWithLoaderIcon = styled(SvgIcon)({
  width: '40px',
  height: '40px',
  zIndex: 1,
})

export const FileUploadWithLoaderNameContainer = styled(Box)({
  flex: 1,
  zIndex: 1,
})

export const FileUploadWithLoaderDeleteIcon = styled(SvgIcon)(({ theme }) => ({
  zIndex: 1,
  fill: 'none',
  '& path': {
    stroke: theme.palette.icon?.secondary_error,
  },
  '&:hover path': {
    stroke: theme.palette.border?.brand,
  },
}))
