import React from 'react'
import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'

type WrapProps = {
  children: ReactNode
}
export const InnerWrap: FC<WrapProps> = ({ children }) => {
  return (
    <Box
      sx={(theme) => ({
        padding: '24px',
        pt: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        boxSizing: 'border-box',
      })}
    >
      {children}
    </Box>
  )
}
