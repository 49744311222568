import {
  StepConnector,
  stepConnectorClasses,
  styled,
  Stepper,
  Box,
} from '@mui/material'

export const DialogTreeConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
    zIndex: 0,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.border?.brand,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.border?.brand,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: theme.palette.border?.primary,
  },
}))

export const DialogTreeStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '16px 24px',
  '& .MuiStepLabel-root .MuiStepLabel-alternativeLabel': {
    marginTop: '4px',
  },
}))

export const DialogTreeIconBox = styled(Box)(() => ({
  position: 'relative',
  zIndex: 1,
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const DialogTreeCompleteStepWrapper = styled(Box)(() => ({
  cursor: 'pointer',
  transition: 'opacity 0.3s ease',

  '&:hover': {
    opacity: 0.7,
  },
}))
