import React from 'react'
import { FC } from 'react'
import { DialogContent, DialogProps } from '@mui/material'
import { BTitle, BTitleProps } from '../bTitle/bTitle'
import { BDialogStyled } from './bDialog.styled'
import DefautModalBackground from '../../../../assets/employee_modal_bg.png'

export type BDialogProps = DialogProps &
  BTitleProps & {
    title: string
    subTitle?: string
    bgImage?: string
    icon?: FC
    onClose?: () => void
    width?: string
    pb?: string
  }

export const BDialog: FC<BDialogProps> = ({
  title,
  subTitle,
  icon,
  bgImage = DefautModalBackground,
  children,
  width,
  pb,
  inlineTitle,
  ...rest
}) => {
  return (
    <BDialogStyled
      {...rest}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: width,
          width: width,
        },
        ...rest?.sx,
      }}
    >
      <BTitle
        title={title}
        subTitle={subTitle}
        icon={icon}
        bgImage={bgImage}
        onClose={rest.onClose}
        pb={pb}
        inlineTitle={inlineTitle}
      ></BTitle>
      <DialogContent>{children}</DialogContent>
    </BDialogStyled>
  )
}
