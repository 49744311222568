import { styled } from '@mui/material/styles'
import { Box, Tab, Tabs } from '@mui/material'

export const FilterTabsContainer = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.border?.secondary,
  borderRadius: '8px',
}))

export const FilterTabsTabPanel = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  flexGrow: 1,
  minHeight: 0,
}))

export const FilterTabsTab = styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    boxShadow: '0px 1px 2px 0px #1018280F',
    borderRadius: '6px',
    padding: '7px 12px',
    minHeight: '20px',
    minWidth: 'fit-content',
    color: theme.palette.text.tertiary_600,
    '&:hover': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.action.focus,
    },
  },
}))

export const FilterTabsWrapper = styled(Tabs)(({ theme }) => ({
  border: 1,
  borderRadius: '10px',
  borderColor: theme.palette.border?.secondary,
  padding: 4,
  backgroundColor: theme.palette.background.default,
  minHeight: '20px',
  '& .MuiTabs-flexContainer': {
    gap: 4,
  },
  '& .MuiTab-root': {
    minWidth: 100,
    textTransform: 'none',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}))
