import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const UserDetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(6),
  rowGap: theme.spacing(4),
  overflow: 'auto',
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
}))

export const UserDetailColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  flex: 1,
  minWidth: '450px',
  height: 'fit-content',
}))

export const UserDetailSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export const UserDetailSectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  fontWeight: 600,
}))

export const UserDetailFieldGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}))
