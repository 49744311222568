import { Grid } from '@mui/material'
import React, { FC } from 'react'
import { ReactComponent as CheckedBg } from '../../../../assets/svg/checked_bg.svg'
import { format, isValid, parseISO, set } from 'date-fns'
import * as Yup from 'yup'
import { FormDialog } from '../../../ui/Dialog/FormDialog'
import { DayInput } from '../../../ui/Form/DateInput/DayInput'

export type ConfirmPaymentValues = {
  amount: number
  paidDate: string
  periodIdentifier: string
}

type ConfirmPaymentModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (updatedValues: ConfirmPaymentValues) => void
  periodIdentifier: string
  salaryDue: number
  bonusAmount: number
}

const validationSchema = Yup.object().shape({
  paidDay: Yup.number()
    .required('Day is required')
    .min(1, 'Must be at least 1')
    .max(31, 'Cannot exceed 31'),
})

export const ConfirmPaymentModal: FC<ConfirmPaymentModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  periodIdentifier,
  salaryDue,
  bonusAmount,
}) => {
  const paymentDate = new Date()

  const amount = salaryDue + bonusAmount
  const initialValue = {
    paidDay: undefined,
  }

  const paymentPeriod = isValid(parseISO(periodIdentifier))
    ? format(periodIdentifier, 'MMMM yyyy')
    : ''
  const subTitle = `${paymentPeriod} | Total amount: ${amount}. This action cannot be undone.`

  const handleSubmit = ({ paidDay }: { paidDay?: number }) => {
    onSubmit({
      paidDate: set(paymentDate, { date: paidDay })?.toISOString(),
      amount,
      periodIdentifier,
    })
  }
  return (
    <FormDialog
      title={'Confirm payment'}
      subTitle={subTitle}
      open={isOpen}
      icon={CheckedBg}
      onClose={onClose}
      pb={'0'}
      initialValue={initialValue}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
    >
      <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
        <DayInput
          endDate={paymentDate.toISOString()}
          name={'paidDay'}
          label={'Pay date*'}
        />
      </Grid>
    </FormDialog>
  )
}
