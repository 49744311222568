import React, { FC } from 'react'
import { FlatUser } from '../../../../types/user/flat-user'
import { BInteractive } from '../../../ui/Form/InteractiveField/BInteractiveField'
import { InteractiveDateField } from '../../../ui/Form/InteractiveField/InteractiveDateField'
import { InteractiveSelectField } from '../../../ui/Form/InteractiveField/InteractiveSelectField'
import { EMPLOYMENT_TYPE_OPTIONS } from '../../../../constants/employment-type.options'
import { InteractiveTextAriaField } from '../../../ui/Form/InteractiveField/InteractiveTextAriaField'
import { CompensationTable } from '../CompensationTable'
import { ProbationEndDate } from '../../../ui/Form/Select/ProbationEndDate'
import { PROBATION_PERIOD_OPTIONS } from '../../../../constants/probation-period.options'
import { differenceInCalendarMonths } from 'date-fns'
import { getById, updateUser } from '../../../../store/action/user'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { getProbationPeriod } from '../../../../utils/get-probation-period'
import {
  CompensationContainer,
  CompensationDetails,
  CompensationForm,
  CompensationFormItem,
} from './Compensation.styled'
import { latestMappedCompensationSelector } from '../../../../store/selector/compenstation.selector'
import { getUserAccessResource } from '../../../../utils/get-user-access-resource'
import { Action } from '../../../ui/Form/InteractiveField/components/InteractiveFieldLabel/InteractiveFieldLabel'
import { meSelector } from '../../../../store/selector/auth.selector'

type CompensationProps = {
  user: FlatUser
}

export const Compensation: FC<CompensationProps> = ({ user }) => {
  const dispatch = useAppDispatch()

  const latestMappedSalaryAmount = useAppSelector(
    latestMappedCompensationSelector
  )?.baseSalary
  const currentUser = useAppSelector(meSelector)

  const onSubmit = async <T extends keyof FlatUser>(
    data: FlatUser[T] | null,
    name: T
  ) => {
    const id = user.id
    await dispatch(updateUser({ id, name, data }))
    await dispatch(getById(id as string))
  }
  const { probationEndDate, startWorkDate } = user

  const probationEnd = probationEndDate ? new Date(probationEndDate) : null
  const startWork = startWorkDate ? new Date(startWorkDate) : null

  const initialProbation =
    probationEnd && startWork
      ? differenceInCalendarMonths(probationEnd, startWork).toString()
      : '0'

  const handleStartDateChange = async (data: string | null) => {
    if (!data) return
    await onSubmit(data, 'startWorkDate')
  }
  const getActions = (actions: Action[], isAdminOnly: boolean = false) => {
    return getUserAccessResource(actions, currentUser, isAdminOnly, user)
  }

  return (
    <CompensationContainer>
      <CompensationForm>
        <CompensationFormItem>
          <CompensationDetails>
            <InteractiveSelectField
              name={'employmentType'}
              placeholder={'Employment type'}
              actions={getActions(['edit'])}
              options={EMPLOYMENT_TYPE_OPTIONS}
              initialValue={user['employmentType']}
              onSubmit={(data) => onSubmit(data, 'employmentType')}
            />
            <BInteractive
              name={'compensationAmount'}
              placeholder={'Current Salary'}
              type={'number'}
              initialValue={latestMappedSalaryAmount?.toString()}
              actions={getActions(['edit'], true)}
              onSubmit={(data) => onSubmit(Number(data), 'compensationAmount')}
              startPreLabel={'$'}
            />
            <InteractiveDateField
              placeholder={'Start date'}
              initialValue={user['startWorkDate']}
              actions={getActions(['edit'], true)}
              onSubmit={handleStartDateChange}
            />

            <InteractiveSelectField
              name={'probationEndDate'}
              placeholder={'Probation period'}
              initialValue={initialProbation}
              options={PROBATION_PERIOD_OPTIONS}
              actions={getActions(['edit'], true)}
              onSubmit={(data) =>
                onSubmit(
                  getProbationPeriod(Number(data), user?.startWorkDate),
                  'probationEndDate'
                )
              }
            >
              {(value) => (
                <ProbationEndDate
                  width={'fit-content'}
                  selected={value ?? ''}
                  startWith={user?.startWorkDate ?? ''}
                />
              )}
            </InteractiveSelectField>
          </CompensationDetails>
        </CompensationFormItem>
        <CompensationFormItem>
          <InteractiveTextAriaField
            name={'paymentDetails'}
            placeholder={'Payment Details'}
            initialValue={user['paymentDetails']}
            actions={getActions(['edit'], true)}
            onSubmit={(data) => onSubmit(data, 'paymentDetails')}
          />
        </CompensationFormItem>
      </CompensationForm>
      <CompensationTable
        probationPeriodEnd={user['probationEndDate']}
        userId={user['id'] as string}
        baseSalary={user['compensationAmount']}
      />
    </CompensationContainer>
  )
}
