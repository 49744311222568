import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationResponse } from '../../types/common/pagination-response'
import { FileWithFlatCreator } from '../../types/file'
import { findFileByUserAccess } from '../action/file'

interface Slice {
  list: FileWithFlatCreator[]
  count: number
}

const initialState: Slice = {
  list: [],
  count: 0,
}

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        findFileByUserAccess.fulfilled,
        (
          state,
          { payload }: PayloadAction<PaginationResponse<FileWithFlatCreator>>
        ) => {
          state.list = payload.rows
          state.count = payload.count
        }
      )
      .addCase(findFileByUserAccess.rejected, (state) => {
        state.list = []
        state.count = 0
      })
  },
})

export default fileSlice.reducer
