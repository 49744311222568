import React, { FC } from 'react'
import { FlatUser } from '../../types/user/flat-user'
import {
  UserDetailFieldGroup,
  UserDetailSection,
  UserDetailSectionTitle,
} from './UserDetail/UserDetail.styled'
import { BInteractive } from '../ui/Form/InteractiveField/BInteractiveField'
import { InteractiveDateField } from '../ui/Form/InteractiveField/InteractiveDateField'
import { Action } from '../ui/Form/InteractiveField/components/InteractiveFieldLabel/InteractiveFieldLabel'

type PersonalDetailsProps = {
  user: FlatUser
  onSubmit: <T extends keyof FlatUser>(
    data: FlatUser[T] | null,
    name: T
  ) => Promise<void>
  getActions: (actions: Action[], isAdminOnly?: boolean) => Action[]
}

export const PersonalDetails: FC<PersonalDetailsProps> = ({
  user,
  onSubmit,
  getActions,
}) => {
  return (
    <UserDetailSection>
      <UserDetailSectionTitle variant="body1">
        Personal Details
      </UserDetailSectionTitle>
      <UserDetailFieldGroup>
        <BInteractive
          name="firstName"
          placeholder="First name"
          initialValue={user['firstName']}
          onSubmit={(data) => onSubmit(data, 'firstName')}
          actions={getActions(['edit'])}
        />
        <BInteractive
          name="lastName"
          placeholder="Last name"
          initialValue={user['lastName']}
          onSubmit={(data) => onSubmit(data, 'lastName')}
          actions={getActions(['edit'])}
        />
      </UserDetailFieldGroup>
      <InteractiveDateField
        placeholder="Birthday"
        initialValue={user['birthday'] as string | undefined}
        onSubmit={(data) => onSubmit(data, 'birthday')}
        actions={getActions(['edit', 'delete'])}
      />
    </UserDetailSection>
  )
}
