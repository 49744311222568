import { createAsyncThunk } from '@reduxjs/toolkit'
import { CreatePaymentPayload } from '../../types/payment/create-payment-payload'
import { PaymentService } from '../../service/payment.service'
import { Payment } from '../../types/payment/payment'

export const getPaymentByUser = createAsyncThunk<Payment[], string>(
  'compensation/getPaymentByUser',
  async (userId: string) => {
    return await PaymentService.getAllByUser(userId)
  }
)

export const createPayment = createAsyncThunk<void, CreatePaymentPayload>(
  'payment/createPayment',
  async (payload: CreatePaymentPayload) => {
    return await PaymentService.create(payload)
  }
)
