import { styled } from '@mui/material/styles'
import Popper from '@mui/material/Popper'

export const StyledPopper = styled(Popper)(({ theme }) => ({
  padding: '6px',
  boxShadow: '0px 12px 16px -4px #10182814',
  borderRadius: 8,
  border: '1px solid',
  borderColor: theme.palette.border?.secondary,
  margin: '16px 0 !important',
  left: '-14px !important',
  zIndex: theme.zIndex.modal,
  backgroundColor: theme.palette.background.default,
}))
