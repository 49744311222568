import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'
import { getTheme } from './utils/theme/get-theme'
import { ThemeProvider } from '@mui/material'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastProvider } from './providers/toast.provider'
import { InteractiveProvider } from './providers/interactive.provider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={getTheme('dark')}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <InteractiveProvider>
          <ToastProvider>
            <RouterProvider router={router} />
          </ToastProvider>
        </InteractiveProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
)

reportWebVitals()
