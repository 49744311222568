import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { FormInput } from '../../ui/Form/FormInput'
import { FormDateInput } from '../../ui/Form/DateInput/FormDateInput'
import React, { FC, useEffect } from 'react'
import { MultiStepModalProps } from '../../../types/multi-step-modal'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import {
  getDefaultSalesCallValues,
  getSpeechToTextOptions,
} from '../../../store/action/sales-call'
import { speechToTextOptionSelector } from '../../../store/selector/sales-call'
import { medicalInitialValues } from './medical.modal.config'

export const AddMedicalFirstStep: FC<
  MultiStepModalProps<typeof medicalInitialValues>
> = ({ initialValues, onSubmit, onClose, formRef }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .max(100, 'Title must be at most 100 characters long'),
    call_date: Yup.date().required('Call date is required').nullable(),
    doctor_details: Yup.string().required('Doctor details is required'),
    client_details: Yup.string().required('Client details are required'),
    additional_information: Yup.string(),
  })
  const options = useAppSelector(speechToTextOptionSelector)
  // const { salesCompanyDetails, salesPersonDetails } =
  //   useAppSelector(getDefaultSalesCallValueSelector) ?? {}
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getSpeechToTextOptions())
    // dispatch(getDefaultSalesCallValues())
  }, [dispatch])
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {() => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <FormInput
              multiline
              name={'title'}
              label={'Title'}
              placeholder={'Enter title'}
            />
            <FormDateInput name="call_date" label="Call date" />
            <FormInput
              name={'doctor_details'}
              label={'Doctor details'}
              placeholder={'Enter Doctor details'}
            />
            <FormInput
              name={'client_details'}
              label={'Client details'}
              placeholder={'Enter Client details'}
            />
            <FormInput
              name={'additional_information'}
              label={'Additional Information'}
              placeholder={'Enter Additional Information'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
