import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import * as React from 'react'
import { BAvatar } from '../../bAvatar'
import { getInitials } from '../../../../utils/get-initials'
import { styled } from '@mui/system'

type BMenuItemProps = {
  img?: string
  label: string | number
  selected?: boolean
}
export const StyledTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const BMenuItem: FC<BMenuItemProps> = ({ img, selected, label }) => {
  return (
    <Grid container alignItems={'center'} overflow={'hidden'}>
      {img && (
        <BAvatar sx={{ mr: 1 }} src={img} avatarSize={24}>
          {getInitials(label?.toString())}
        </BAvatar>
      )}

      <StyledTypography>{label}</StyledTypography>

      {selected && (
        <DoneIcon
          fontSize={'small'}
          sx={{ ml: 'auto', color: 'button.primary_bg' }}
        />
      )}
    </Grid>
  )
}
