import { RootState } from '../index'

export const userListSelector = (state: RootState) => state.users.list
export const userSelector = (state: RootState) => state.users.user
export const userDropDownSelector = (state: RootState) =>
  state.users.dropDownList
export const usersFormerCountSelector = (state: RootState) =>
  state.users.formerCount
export const usersActiveCountSelector = (state: RootState) =>
  state.users.activeCount
export const usersUpcomingCountSelector = (state: RootState) =>
  state.users.upcomingCount
export const usersCurrentCountSelector = (state: RootState) =>
  state.users.currentCount
