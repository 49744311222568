import React from 'react'
import { FC } from 'react'
import { Sidebar } from '../Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import { RootLayoutContainer, SidebarContainer } from './RootLayout.styled'

export const RootLayout: FC = () => {
  return (
    <RootLayoutContainer>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <Outlet />
    </RootLayoutContainer>
  )
}
