import { FlatUser } from './flat-user'
import { UserRole } from './user-role'

export interface UserWithProfile {
  id: number
  email: string
  role: UserRole
  profile: {
    first_name: string
    last_name: string
    profile_picture_url: string
  }
}

export const mapUserWithProfileToFlatUser = (
  user: UserWithProfile
): Partial<FlatUser> => {
  return {
    id: user.id.toString(),
    email: user.email,
    role: user.role,
    firstName: user.profile.first_name,
    lastName: user.profile.last_name,
    profilePictureUrl: user.profile.profile_picture_url,
  }
}
