import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { StyledInteractiveField } from '../StyledInteractiveField'

export const InteractiveFieldLabelContainer = styled(Box)(() => ({
  flex: 1,
  overflow: 'hidden',
}))

export const InteractiveFieldLabelStyled = styled(StyledInteractiveField)(
  () => ({
    overflow: 'hidden',
  })
)

export const InteractiveFieldLabelPlaceholder = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'multiline',
})<{ multiline: boolean }>(({ theme, multiline }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  position: 'absolute',
  top: multiline ? undefined : '50%',
  left: 0,
  paddingLeft: '16px',
  zIndex: 10,
  background: `linear-gradient(269.1deg, rgba(22, 27, 38, 0) 0.78%, ${theme.palette.background.paper} 42.02%)`,
  transform: multiline ? undefined : 'translateY(-50%)',
}))

export const InteractiveFieldLabelText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
}))
