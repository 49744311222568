import React, { FC, useState } from 'react'
import { Typography } from '@mui/material'
import { FileUpload, FileUploadProps } from '../FileUpload/FileUpload'
import { ReactComponent as FileIcon } from '../../../../../assets/svg/file.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/svg/trash.svg'
import { BLoader } from '../../../Loader/BLoader'
import {
  FileUploadWithLoaderContainer,
  FileUploadWithLoaderProgress,
  FileUploadWithLoaderIcon,
  FileUploadWithLoaderNameContainer,
  FileUploadWithLoaderDeleteIcon,
} from './FileUploadWithLoader.styled'

type FileUploadWithLoaderProps = FileUploadProps & {
  file: File | null
  uploadPercentage: number
  onDelete: () => void
}

export const FileUploadWithLoader: FC<FileUploadWithLoaderProps> = ({
  file,
  onDelete,
  uploadPercentage,
  ...rest
}) => {
  const formatFileSize = (size: number): string => {
    if (size >= 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + ' MB'
    } else if (size >= 1024) {
      return (size / 1024).toFixed(2) + ' KB'
    } else {
      return size + ' B'
    }
  }

  return (
    <>
      {file ? (
        <FileUploadWithLoaderContainer>
          <FileUploadWithLoaderProgress
            sx={{ width: `${uploadPercentage}%` }}
          />
          <FileUploadWithLoaderIcon inheritViewBox>
            <FileIcon />
          </FileUploadWithLoaderIcon>
          <FileUploadWithLoaderNameContainer>
            <Typography
              fontWeight={500}
              variant={'body2'}
              color={'text.secondary'}
            >
              {file.name}
            </Typography>
            <Typography variant={'body2'} color={'text.tertiary_600'}>
              {`${formatFileSize(file.size)} - ${uploadPercentage}% uploaded`}
            </Typography>
          </FileUploadWithLoaderNameContainer>
          {uploadPercentage < 100 ? (
            <BLoader />
          ) : (
            <FileUploadWithLoaderDeleteIcon
              inheritViewBox
              fontSize={'small'}
              htmlColor={'#FECDCA'}
              onClick={onDelete}
            >
              <TrashIcon />
            </FileUploadWithLoaderDeleteIcon>
          )}
        </FileUploadWithLoaderContainer>
      ) : (
        <FileUpload {...rest} />
      )}
    </>
  )
}
