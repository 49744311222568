import React, { FC } from 'react'
import { AnalyzeCriteria } from '../../types/sales/analyze-criteria'
import { SimpleDialog } from '../ui/Dialog/SimpleDialog'
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

type AnalyzeCriteriaDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  analyzeCriteria: AnalyzeCriteria[] | null
  selectedCriteria: string[]
  onToggleCriteria: (key: string) => void
}

export const AnalyzeCriteriaDialog: FC<AnalyzeCriteriaDialogProps> = ({
  open,
  onClose,
  onSubmit,
  analyzeCriteria,
  selectedCriteria,
  onToggleCriteria,
}) => (
  <SimpleDialog
    title="Analysis Criteria"
    open={open}
    onClose={onClose}
    onSubmit={onSubmit}
    isFullButtons={false}
    cancelLabel="Cancel"
    submitLabel="Analyze"
  >
    <Box p={3}>
      <FormGroup>
        {analyzeCriteria?.map(({ key, label, default_criteria }) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={default_criteria || selectedCriteria.includes(key)}
                onChange={
                  default_criteria ? undefined : () => onToggleCriteria(key)
                }
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </Box>
  </SimpleDialog>
)
