import { styled } from '@mui/material/styles'
import { Box, lighten, Typography } from '@mui/material'
import { BButton } from '../../../Button/BButton'

export const MonthPaginationContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '16px',
}))

export const MonthPaginationButton = styled(BButton)(({ theme }) => ({
  height: '28px',
  padding: '6px 12px',
  minWidth: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiButton-endIcon': {
    margin: 0,
  },
  '&:disabled': {
    backgroundColor: lighten(theme.palette.background.paper, 0.5),
  },
}))

export const MonthPaginationTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  fontWeight: 600,
  variant: 'body2',
  color: theme.palette.text.primary,
}))
