import * as React from 'react'
import * as Yup from 'yup'
import {
  Action,
  ActionHandlers,
  InteractiveFieldLabel,
} from './components/InteractiveFieldLabel/InteractiveFieldLabel'
import { InputWrap } from './components/InputWrap'
import {
  InteractiveFieldInput,
  InteractiveFieldInputProps,
} from './components/InteractiveFieldInput'
import { useInteractiveField } from '../../../../hooks/useInteractiveField'
import { FC, HTMLInputTypeAttribute } from 'react'

type BInteractiveProps = {
  name: string
  placeholder: string
  primary?: boolean
  startAdornment?: string
  startPreLabel?: string
  type?: HTMLInputTypeAttribute
  initialValue?: string
  validationSchema?: Yup.Schema<unknown>
  onSubmit: (params: string | null) => Promise<void>
  actions: Action[]
  handlers?: ActionHandlers
  initialEditing?: boolean
  onCancel?: () => void
  index?: number
  InputComponent?: FC<InteractiveFieldInputProps>
}

export const BInteractive = ({
  placeholder,
  name,
  startAdornment,
  type = 'text',
  initialValue = '',
  primary,
  validationSchema,
  onSubmit,
  actions,
  handlers = {},
  initialEditing,
  onCancel,
  InputComponent = InteractiveFieldInput,
  startPreLabel,
}: BInteractiveProps) => {
  const {
    isEditing,
    value,
    setValue,
    error,
    setError,
    toggleEditing,
    handleCancel,
    handleSave,
    handleDelete,
  } = useInteractiveField(
    initialValue,
    initialEditing,
    validationSchema,
    onSubmit
  )

  const mergedHandlers = {
    edit: toggleEditing,
    delete: handleDelete,
    ...handlers,
  }

  return (
    <>
      {isEditing ? (
        <InputWrap
          value={value}
          changeEditing={() => handleCancel(onCancel)}
          onSubmit={handleSave}
          sx={{ flex: 1 }}
        >
          {() => (
            <InputComponent
              name={name}
              type={type}
              onSave={handleSave}
              onCancel={() => handleCancel(onCancel)}
              placeholder={placeholder}
              startAdornment={startAdornment}
              initialValue={value}
              validationSchema={validationSchema}
              error={error}
              value={value}
              setValue={setValue}
              setError={setError}
            />
          )}
        </InputWrap>
      ) : (
        <InteractiveFieldLabel
          primary={primary}
          placeholder={placeholder}
          value={value}
          actions={actions}
          handlers={mergedHandlers}
          startPreLabel={startPreLabel}
        />
      )}
    </>
  )
}
