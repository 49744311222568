import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectService } from '../../service/project.service'
import { GetAllProjectDto } from '../../types/project/get-all-project.dto'
import { GridPaginationModel } from '@mui/x-data-grid'

export const getAllProject = createAsyncThunk<
  GetAllProjectDto,
  { paginationModel: GridPaginationModel }
>(
  'projects/getAllProject',
  async (params: { paginationModel: GridPaginationModel }) => {
    return await ProjectService.getAll(params)
  }
)
