import React from 'react'
import { FC } from 'react'
import { ActionCell, ActionCellProps } from './ActionCell/ActionCell'
import { ReviewCell, ReviewCellProps } from './ReviewCell'
import { BonusCell, BonusCellProps } from './BonusCell/BonusCell'
import {
  ProbationPeriodCell,
  ProbationPeriodCellProps,
} from './ProbationPeriodCell'
import { TableCell } from './Styled'

export type RenderCellProps = {
  type: 'actions' | 'review' | 'bonus' | 'probation_end' | 'empty'
  colSpan: number
  props:
    | ActionCellProps
    | ReviewCellProps
    | BonusCellProps
    | {
        probationPeriodEnd?: Date
      }
    | Record<string, unknown>
  periodIdentifier?: string
}

export const RenderCell: FC<RenderCellProps> = ({ type, colSpan, props }) => {
  const components = {
    actions: <ActionCell {...(props as ActionCellProps)} colSpan={colSpan} />,
    review: <ReviewCell {...(props as ReviewCellProps)} colSpan={colSpan} />,
    bonus: <BonusCell {...(props as BonusCellProps)} colSpan={colSpan} />,
    empty: <TableCell colSpan={colSpan} />,
    probation_end: (
      <ProbationPeriodCell
        {...(props as ProbationPeriodCellProps)}
        colSpan={colSpan}
      />
    ),
  }

  return components[type] || components['empty']
}
