import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payment } from '../../types/payment/payment'
import { getPaymentByUser } from '../action/payment'

export interface PaymentSlice {
  list: Payment[]
}

const initialState: PaymentSlice = {
  list: [],
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getPaymentByUser.fulfilled,
        (state, { payload }: PayloadAction<Payment[]>) => {
          state.list = payload
        }
      )
      .addCase(getPaymentByUser.rejected, (state) => {
        state.list = []
      })
  },
})

// export const {  } = compensationSlice.actions
export default paymentSlice.reducer
