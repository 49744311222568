import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const SidebarContainer = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(4)} ${theme.spacing(2)}`,
  maxHeight: 'calc(100vh)',
  height: 'calc(100vh - 66px)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  position: 'sticky',
}))

export const SidebarLogo = styled('img')({
  height: '40px',
  width: '150px',
})

export const SidebarBottomSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  borderRadius: '8px',
  borderTop: `1px solid ${theme.palette.border?.secondary}`,
}))
