import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useInteractiveModal } from '../providers/interactive.provider'

export const useInteractiveField = (
  initialValue = '',
  initialEditing = false,
  validationSchema?: Yup.Schema<unknown>,
  onSubmit?: (value: string | null) => Promise<void>
) => {
  const [isEditing, setIsEditing] = useState(initialEditing)
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState<string | null>(null)
  const { isExistEditing, setIsExistEditing } = useInteractiveModal()

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const toggleEditing = (flag?: boolean) => {
    if (isExistEditing && !flag && !isEditing) {
      return
    }
    setIsEditing((prev) => flag ?? !prev)
    setIsExistEditing(flag ?? !isEditing)
  }

  const handleCancel = (onCancel?: () => void) => {
    setValue(initialValue)
    toggleEditing()
    if (onCancel) onCancel()
  }

  const handleSave = async () => {
    if (validationSchema) {
      try {
        await validationSchema.validate(value)
        setValue(initialValue)
        if (onSubmit) await onSubmit(value)
        toggleEditing()
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setError(err.message)
        }
      }
    } else {
      setValue(initialValue)
      if (onSubmit) await onSubmit(value)
      toggleEditing()
    }
  }

  const handleDelete = () => {
    if (onSubmit) onSubmit(null)
    toggleEditing(false)
    setValue('')
  }

  return {
    isEditing,
    value,
    setValue,
    error,
    setError,
    toggleEditing,
    handleCancel,
    handleSave,
    handleDelete,
  }
}
