import { styled } from '@mui/system'
import { autocompleteClasses } from '@mui/material/Autocomplete'

export const TelPopperWrapper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.root}`]: {
    border: 'none',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.default,
    maxHeight: '150px',
    height: 'fit-content',
    overflowY: 'scroll',

    [`& .${autocompleteClasses.option}`]: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px',
      padding: '10px 8px',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.border?.secondary,
        },
    },
  },
}))
