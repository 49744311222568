import { createAsyncThunk } from '@reduxjs/toolkit'
import { CompensationService } from '../../service/compensation.service'
import { CompensationWithPayment } from '../../types/compensation/compensation-with-payment'
import { CreateCompensationPayload } from '../../types/compensation/create-compensation-payload'
import { UpdateReviewCompensationPayload } from '../../types/compensation/update-review-compensation-payload'
import { Compensation } from '../../types/compensation/compensation'

export const getWithPayment = createAsyncThunk<
  CompensationWithPayment[],
  string
>('compensation/withPayment', async (userId: string) => {
  return await CompensationService.getWithPayments(userId)
})

export const getCompensationByUserId = createAsyncThunk<
  Compensation[],
  { userId: string; types: string; sortField: string; sortOrder: string }
>(
  'compensation/getByUserId',
  async ({
    userId,
    types,
    sortField,
    sortOrder,
  }: {
    userId: string
    types: string
    sortField: string
    sortOrder: string
  }) => {
    return await CompensationService.getByUserId(
      userId,
      types,
      sortField,
      sortOrder
    )
  }
)

export const createCompensation = createAsyncThunk<
  void,
  CreateCompensationPayload
>(
  'compensation/createCompensation',
  async (payload: CreateCompensationPayload) => {
    return await CompensationService.create(payload)
  }
)

export const updateByReviewDate = createAsyncThunk<
  void,
  UpdateReviewCompensationPayload
>(
  'compensation/updateByReviewDate',
  async (payload: UpdateReviewCompensationPayload) => {
    return await CompensationService.updateByReviewDate(payload)
  }
)

export const bulkCompensationUpdate = createAsyncThunk<
  void,
  CreateCompensationPayload[]
>(
  'compensation/bulkCompensationUpdate',
  async (payload: CreateCompensationPayload[]) => {
    return await CompensationService.bulkUpsert(payload)
  }
)

export const deleteCompensationByIds = createAsyncThunk<void, number[]>(
  'compensation/deleteCompensationByIds',
  async (payload: number[]) => {
    return await CompensationService.deleteByIds(payload)
  }
)
