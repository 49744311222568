import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const CompensationContainer = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}))

export const CompensationForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(6),
  rowGap: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}))

export const CompensationFormItem = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: '400px',
  display: 'grid',
  gap: theme.spacing(4),
  minHeight: '100px',
}))

export const CompensationDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  height: 'fit-content',
}))
