import React from 'react'
import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { FilterTabs } from '../../components/ui/Tabs/FilterTabs/FilterTabs'
import { HeadingRow } from '../../components/common/HeadingRow/HeadingRow'
import { BTable } from '../../components/ui/Table/bTable/bTable'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { BDialog } from '../../components/ui/Dialog/bDialog'
import modalBackground from '../../assets/employee_modal_bg.png'
import { ReactComponent as AvatarIcon } from '../../assets/svg/avatar-icon.svg'
import { EmployeeModal } from '../../components/employee/Modal'
import { getAllUser, getCountByUserType } from '../../store/action/user'
import { Wrap } from '../../layout/root/Wrap'
import { TableSearch } from '../../components/common/TableSearch/TableSearch'
import { ReactComponent as SaveIcon } from '../../assets/svg/save-changes.svg'
import _ from 'lodash'
import { GridSortModel } from '@mui/x-data-grid'
import {
  userListSelector,
  usersActiveCountSelector,
  usersCurrentCountSelector,
  usersFormerCountSelector,
  usersUpcomingCountSelector,
} from '../../store/selector/user.selector'
import { SimpleDialog } from '../../components/ui/Dialog/SimpleDialog'
import { FlatUser } from '../../types/user/flat-user'
import { AddEmployeeInitialState } from '../../components/employee/Modal/initialStates'
import { AdminColumns } from '../../components/employee/columns/admin.columns'
import { BButton } from '../../components/ui/Button/BButton'
import { ReactComponent as CloudIcon } from '../../../assets/svg/cloud.svg'
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg'
import { InnerWrap } from '../../layout/root/InnerWrap'

export const AdminEmployee: FC = () => {
  const dispatch = useAppDispatch()
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  })

  const [filter, setFilter] = useState('current')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [open, setOpen] = useState(false)
  const [isOpenUnsaved, setIsOpenUnsaved] = useState(false)
  const [newUser, setNewUser] = useState<Partial<FlatUser>>(
    AddEmployeeInitialState
  )

  const list = useAppSelector(userListSelector)
  const currentCount = useAppSelector(usersCurrentCountSelector)
  const formerCount = useAppSelector(usersFormerCountSelector)
  const upcomingCount = useAppSelector(usersUpcomingCountSelector)
  const activeCount = useAppSelector(usersActiveCountSelector)

  const tabsData = useMemo(
    () => [
      { title: 'Current', value: 'current', badgeNumber: currentCount },
      { title: 'Upcoming', value: 'upcoming', badgeNumber: upcomingCount },
      { title: 'Former', value: 'former', badgeNumber: formerCount },
    ],
    [currentCount, upcomingCount, formerCount]
  )

  useEffect(() => {
    if (open) {
      return
    }
    dispatch(getCountByUserType())
  }, [dispatch, open])

  useEffect(() => {
    if (open) {
      return
    }
    if (searchQuery) {
      _.debounce((paginationModel, filter, searchQuery, sortModel) => {
        dispatch(
          getAllUser({
            paginationModel,
            filter,
            searchQuery,
            sortModel,
          })
        )
      }, 800)(paginationModel, filter, searchQuery, sortModel)
    } else {
      dispatch(getAllUser({ paginationModel, filter, sortModel }))
    }
  }, [paginationModel, dispatch, filter, searchQuery, sortModel, open])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }
  const handleChangeTab = (id: number) => {
    const { value } = tabsData[id]
    setFilter(value)
  }
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel)
  }

  const handleClose = (
    event?: object | React.SyntheticEvent,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return setIsOpenUnsaved(true)
    }
    setOpen(false)
    setNewUser(AddEmployeeInitialState)
  }

  const handleToggleUnsaved = () => setIsOpenUnsaved((prev) => !prev)

  return (
    <Wrap bg={'default'}>
      <InnerWrap>
        <Grid
          container
          sx={{
            paddingBottom: 2.3,
            gap: 2.3,
            flexDirection: 'column',
          }}
        >
          <HeadingRow
            title={'Employee'}
            subTitle={'View detailed information about your employee.'}
          >
            {/*<BButton*/}
            {/*  sx={{ height: 40 }}*/}
            {/*  color="secondary"*/}
            {/*  startIcon={<CloudIcon />}*/}
            {/*>*/}
            {/*  Download CSV*/}
            {/*</BButton>*/}
            <BButton
              sx={{ height: 40 }}
              onClick={() => setOpen(true)}
              size={'small'}
              color="primary"
              startIcon={<PlusIcon />}
            >
              Add employee
            </BButton>
          </HeadingRow>
          <FilterTabs
            width={'fit-content'}
            tabs={tabsData}
            onChange={handleChangeTab}
            tabKey={'employee-tab-key'}
          />
          <TableSearch
            placeholder={'Search by name, email, role, level, project'}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
        <BTable
          columns={AdminColumns}
          rows={list}
          rowCount={activeCount}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
        <BDialog
          title={'Add New Employee'}
          subTitle={'Add your employee profile in less than 5 minutes.'}
          open={open}
          icon={AvatarIcon}
          onClose={handleClose}
          bgImage={modalBackground}
        >
          <EmployeeModal
            onClose={handleClose}
            newUser={newUser}
            setNewUser={setNewUser}
          />
        </BDialog>
        <SimpleDialog
          open={isOpenUnsaved}
          width={'400px'}
          title={'Unsaved changes'}
          subTitle={'Do you want to continue or discard changes?'}
          icon={SaveIcon}
          submitLabel={'Continue'}
          cancelLabel={"Don't save"}
          onSubmit={handleToggleUnsaved}
          onClose={() => {
            handleToggleUnsaved()
            handleClose()
          }}
        />
      </InnerWrap>
    </Wrap>
  )
}
