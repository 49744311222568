import { Action } from '../components/ui/Form/InteractiveField/components/InteractiveFieldLabel/InteractiveFieldLabel'
import { UserRole } from '../types/user/user-role'
import { FlatUser } from '../types/user/flat-user'

export const getUserAccessResource = (
  actions: Action[],
  me: FlatUser | null,
  isAdminOnly: boolean,
  user?: FlatUser | null
) => {
  if (!me) return []

  const isMeAdmin = me.role === UserRole.Admin
  const isMeSuperAdmin = me.role === UserRole.Superadmin

  if (isMeSuperAdmin) return actions

  if (isAdminOnly && !isMeAdmin) return []

  if (isMeAdmin) {
    const isSameUser = user?.id === me.id
    const isTargetUserPrivileged =
      user?.role === UserRole.Admin || user?.role === UserRole.Superadmin

    if (!isTargetUserPrivileged || isSameUser) {
      return actions
    }
  }

  return []
}
