import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Project } from '../../types/project'
import { getAllProject } from '../action/project'
import { GetAllProjectDto } from '../../types/project/get-all-project.dto'

interface Slice {
  list: Project[]
  count: number
  isLoading: boolean
  errorMessage: string | null
}

const initialState: Slice = {
  list: [],
  count: 0,
  errorMessage: null,
  isLoading: false,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProject.pending, (state) => {
        state.isLoading = true
        state.errorMessage = null
      })
      .addCase(
        getAllProject.fulfilled,
        (state, { payload }: PayloadAction<GetAllProjectDto>) => {
          state.list = payload.rows
          state.count = payload.count
          state.isLoading = false
          state.errorMessage = null
        }
      )
      .addCase(getAllProject.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.error.message ?? 'Get all user error'
      })
  },
})

export default projectSlice.reducer
