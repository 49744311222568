import React, { forwardRef } from 'react'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { StyledTextField } from '../../../TextFiled/TextField.styled'
import { InputAdornment, SxProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { BStyledPopper } from './bStyledPopper'

interface BAutocompleteProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput'> {
  title?: string
  placeholder?: string
  sx?: SxProps
}
function BAutocompleteComponent<T>(
  props: BAutocompleteProps<T>,
  ref: React.Ref<HTMLDivElement>
) {
  const { placeholder } = props
  return (
    <Autocomplete
      PopperComponent={BStyledPopper}
      renderTags={() => null}
      noOptionsText={'No results'}
      renderInput={(params) => (
        <StyledTextField
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          size={'small'}
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      )}
      {...props}
      ref={ref}
    />
  )
}

export const BAutocomplete = forwardRef(BAutocompleteComponent) as <T>(
  props: BAutocompleteProps<T> & { ref?: React.Ref<HTMLDivElement> }
) => ReturnType<typeof BAutocompleteComponent>
