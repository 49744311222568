import React from 'react'
import { FC } from 'react'
import { TableCell } from '../Styled'
import { ReactComponent as CoinsIcon } from '../../../../../assets/svg/stacked_coins.svg'
import {
  BonusCellStyledBadge,
  BonusCellStyledTypography,
} from './BonusCell.styled'

export type BonusCellProps = {
  bonusAmount: number
  currencySymbol: string
  salaryPaymentDate: Date | null
  colSpan: number
}
export const BonusCell: FC<BonusCellProps> = ({
  bonusAmount = 0,
  salaryPaymentDate,
  currencySymbol = '$',
  colSpan = 2,
}) => {
  const badgeType = salaryPaymentDate ? 'success' : 'grey_blue'
  const label = currencySymbol + bonusAmount
  return (
    <TableCell
      paddingY={'12px'}
      colSpan={colSpan}
      direction={'column'}
      gap={'10px'}
    >
      <BonusCellStyledBadge
        salaryPaymentDate={salaryPaymentDate}
        icon={<CoinsIcon />}
        label={label}
        badgeType={badgeType}
      />
      <BonusCellStyledTypography>Bonuses</BonusCellStyledTypography>
    </TableCell>
  )
}
