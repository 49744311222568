import { SxProps, Theme } from '@mui/material'

export const ViewStyled: SxProps<Theme> = (theme) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  width: 'fit-content',
  display: 'block',
  borderRadius: '6px',
  fontWeight: 500,
  lineHeight: '20px',
  transition: 'color .1s ease-in',
  textTransform: 'initial',
  padding: 0,
  '&:hover': {
    color: theme.palette.icon?.utility_brand_500,
    background: 'transparent',
  },
})
