import { InputAdornment, Typography } from '@mui/material'
import React from 'react'

export const textLeftAdornment = (
  label: string,
  color: string = 'text.tertiary_600'
) => {
  return {
    startAdornment: (
      <InputAdornment position="start">
        <Typography color={color}>{label}</Typography>
      </InputAdornment>
    ),
  }
}
export const textLeftAdornmentStyles = {
  '.MuiInputBase-input': {
    borderLeft: '1px solid',
    borderColor: 'border.primary',
    paddingLeft: '15px',
  },
}
