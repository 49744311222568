import React, { FC, SVGProps } from 'react'
import { TableCell } from '../Styled'
import { Typography } from '@mui/material'
import { ReactComponent as EyeIcon } from '../../../../../assets/svg/eye.svg'
import { ReactComponent as CheckIcon } from '../../../../../assets/svg/check-circle.svg'
import { ReactComponent as StakedCoinsIcon } from '../../../../../assets/svg/stacked_coins.svg'
import {
  ActionCellStyledBox,
  ActionCellStyledSvgIcon,
} from './ActionCell.styled'

export type ActionCellProps = {
  onClickPaid: () => void
  onClickEdit: () => void
  onClickSalary: () => void
  colSpan: number
}

export const ActionCell: FC<ActionCellProps> = ({
  colSpan = 2,
  onClickPaid,
  onClickEdit,
  onClickSalary,
}) => {
  const Icon = (
    icon: FC<SVGProps<SVGSVGElement>>,
    clickHandler: () => void,
    label?: string
  ) => {
    return (
      <ActionCellStyledBox onClick={clickHandler}>
        <ActionCellStyledSvgIcon component={icon} inheritViewBox />
        {label && (
          <Typography fontSize={12} lineHeight={1.5} fontWeight={500}>
            {label}
          </Typography>
        )}
      </ActionCellStyledBox>
    )
  }

  return (
    <TableCell paddingY={'20px'} direction={'row'} gap={2} colSpan={colSpan}>
      {Icon(EyeIcon, onClickSalary)}
      {Icon(StakedCoinsIcon, onClickEdit)}
      {Icon(CheckIcon, onClickPaid)}
    </TableCell>
  )
}
