import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box } from '@mui/material'
import { TableWrapper } from '../../ui/Table/DynamicTable/Styled'
import { format, parseISO } from 'date-fns'
import { MonthPagination } from '../../ui/Table/DynamicTable/MonthPagination/MonthPagination'
import { mapToRows } from './table/mapToRows'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { getWithPayment } from '../../../store/action/compensation'
import { useTableResize } from './table/useTableResize'
import { getPaymentByUser } from '../../../store/action/payment'
import { compensationMappedListSelector } from '../../../store/selector/compenstation.selector'
import { paymentListSelector } from '../../../store/selector/payment.selector'
import { useRenderRows } from './table/useRenderRows'
import { CompensationTableContent } from './CompensationTableContent'
import { CompensationModals } from './CompensationModal'
import { useCompensationModals } from '../../../hooks/compensation/useCompensationModal'

type CompensationTableProps = {
  probationPeriodEnd?: string
  userId: string
  baseSalary?: number
}
export const CompensationTable: FC<CompensationTableProps> = ({
  probationPeriodEnd,
  userId,
  baseSalary,
}) => {
  const dispatch = useAppDispatch()
  const compensationList = useAppSelector(compensationMappedListSelector)
  const paymentList = useAppSelector(paymentListSelector)
  const initDate = parseISO(
    compensationList[0]?.periodIdentifier ?? new Date()?.toISOString()
  )
  const [startDate, setStartDate] = useState<Date>(initDate)

  const {
    isOpenEditModal,
    isOpenPaymentModal,
    isOpenSalaryReviewModal,
    editEntry,
    handleEditClick,
    handlePaidClick,
    handleSalaryReviewClick,
    closeModals,
  } = useCompensationModals(compensationList)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!isOpenEditModal && !isOpenPaymentModal && !isOpenSalaryReviewModal) {
      setIsLoading(true)
      dispatch(getWithPayment(userId))
      dispatch(getPaymentByUser(userId))
    }
  }, [
    dispatch,
    userId,
    isOpenPaymentModal,
    isOpenEditModal,
    baseSalary,
    isOpenSalaryReviewModal,
  ])

  useEffect(() => {
    const date = parseISO(
      compensationList[0]?.periodIdentifier || new Date()?.toISOString()
    )
    setStartDate(date)
  }, [compensationList])

  useTableResize(containerRef, setItemsPerPage, setCurrentPage)

  const { salaryByMonth, reviewRows, actionsRows, bonusRows, probationEndRow } =
    useMemo(
      () =>
        mapToRows(compensationList, paymentList, startDate, probationPeriodEnd),
      [compensationList, paymentList, startDate, probationPeriodEnd]
    )

  const lastPaidDate = paymentList.length ? paymentList[0]?.period : ''
  const parsedLastPaidDate = lastPaidDate ? parseISO(lastPaidDate) : null
  const lastPaidIndex = salaryByMonth.findIndex(
    ({ periodIdentifier }) =>
      parsedLastPaidDate &&
      format(parsedLastPaidDate, 'yyyy-MM-dd') === periodIdentifier
  )

  console.log(Math.floor((lastPaidIndex + 1) / itemsPerPage))
  const initialPage =
    lastPaidIndex !== -1
      ? Math.floor((lastPaidIndex + 1) / itemsPerPage) + 1
      : 1

  useEffect(() => {
    setCurrentPage(initialPage)
  }, [initialPage])

  const totalPages = Math.ceil(salaryByMonth.length / itemsPerPage)
  const paginatedMonths = useMemo(() => {
    return salaryByMonth.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    )
  }, [salaryByMonth, currentPage, itemsPerPage])

  const startDateOfExpectedReview = useMemo(() => {
    return salaryByMonth.find(
      (_, index) => salaryByMonth[index]?.isExpectedReview
    )?.periodIdentifier
  }, [salaryByMonth])

  const renderRows = useRenderRows(
    paginatedMonths,
    reviewRows,
    probationEndRow,
    bonusRows,
    actionsRows
  )

  useEffect(() => {
    setIsLoading(false)
  }, [compensationList, paymentList])

  const startDatePaginated = parseISO(
    paginatedMonths[0]?.periodIdentifier ?? ''
  )
  const endDatePaginated = parseISO(
    paginatedMonths[paginatedMonths.length - 1]?.periodIdentifier ?? ''
  )

  if (isLoading) return null

  return (
    <Box flex={1} minWidth={'100%'} ref={containerRef} py={3}>
      <MonthPagination
        startDate={startDatePaginated}
        endDate={endDatePaginated}
        page={currentPage}
        totalPages={totalPages}
        onNextPage={() =>
          setCurrentPage((prev) => Math.min(prev + 1, totalPages))
        }
        onPrevPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
      />
      <TableWrapper>
        <CompensationTableContent
          paginatedMonths={paginatedMonths}
          startDateOfExpectedReview={startDateOfExpectedReview}
          renderRows={renderRows}
          handleEditClick={handleEditClick}
          handlePaidClick={handlePaidClick}
          handleSalaryReviewClick={handleSalaryReviewClick}
        />
      </TableWrapper>
      <CompensationModals
        isOpenEditModal={isOpenEditModal}
        isOpenPaymentModal={isOpenPaymentModal}
        isOpenSalaryReviewModal={isOpenSalaryReviewModal}
        editEntry={editEntry}
        userId={userId}
        onCloseEditModal={closeModals.edit}
        onClosePaymentModal={closeModals.payment}
        onCloseSalaryReviewModal={closeModals.salaryReview}
      />
    </Box>
  )
}
