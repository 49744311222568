import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'
import {
  Action,
  ActionHandlers,
} from './InteractiveFieldLabel/InteractiveFieldLabel'
import { ReactComponent as CopyIcon } from '../../../../../assets/svg/copy.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/svg/edit.svg'
import { ReactComponent as DividerIcon } from '../../../../../assets/svg/divider.svg'
import { ReactComponent as AddIcon } from '../../../../../assets/svg/add.svg'
import { ReactComponent as LinkIcon } from '../../../../../assets/svg/link.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/svg/trash.svg'
import { toastGenerator } from '../../../../../providers/toast.provider'
import { ReactComponent as SaveIcon } from '../../../../../assets/svg/save-changes.svg'
import { SimpleDialog } from '../../../Dialog/SimpleDialog'
import { BSvg } from '../../../bSvg'

type LabelActionsProps = {
  actions: Action[]
  value?: string
  handlers?: ActionHandlers
  setIsHovered: (isHovered: boolean) => void
}
const actionIcons: Record<
  Action,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  copy: CopyIcon,
  edit: EditIcon,
  add: AddIcon,
  link: LinkIcon,
  delete: TrashIcon,
}

export const LabelActions: FC<LabelActionsProps> = ({
  value,
  actions = [],
  handlers = {},
  setIsHovered,
}) => {
  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const handleCopy = async () => {
    if (value) {
      await navigator.clipboard.writeText(value)
      toastGenerator.copy({ title: 'Copied to clipboard' })
    }
  }

  const handleAction = (action: Action) => {
    if (action === 'copy') {
      handleCopy()
    } else if (action === 'delete') {
      handleToggleModal()
    } else if (handlers[action]) {
      handlers[action]!()
    }
  }
  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const handleDelete = () => {
    handlers['delete']?.()
    handleToggleModal()
    setIsHovered(false)
  }
  return (
    <>
      {actions.map((action, index) => (
        <React.Fragment key={action}>
          <BSvg
            component={actionIcons[action]}
            onClick={() => handleAction(action)}
          />
          {index < actions.length - 1 && (
            <SvgIcon
              component={DividerIcon}
              inheritViewBox
              sx={{ fill: 'currentcolor', width: '16px' }}
            />
          )}
        </React.Fragment>
      ))}
      <SimpleDialog
        width={'400px'}
        title={'Confirm Deletion'}
        subTitle={
          'Are you sure you want to delete this data? This action cannot be undone.'
        }
        open={isOpenModal}
        icon={SaveIcon}
        onClose={handleToggleModal}
        cancelLabel={'Cancel'}
        submitLabel={'Delete'}
        onSubmit={handleDelete}
      />
    </>
  )
}
