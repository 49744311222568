import { styled } from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import { autocompleteClasses } from '@mui/material/Autocomplete'

export const BStyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0px 12px 16px -4px #10182814',
  borderRadius: 8,
  border: '1px solid',
  borderColor: theme.palette.border?.secondary,
  top: '16px !important',
  zIndex: theme.zIndex.modal,
  backgroundColor: theme.palette.background.default,

  [`& .${autocompleteClasses.paper}`]: {
    margin: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.background.default,
    padding: '6px',
    borderRadius: 8,
    height: 'fit-content',
    maxHeight: '220px',
    overflowY: 'scroll',
    // border: '1px solid',
    // borderColor: theme.palette.border?.secondary,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: '8px 10px',
      borderRadius: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.border?.secondary,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))
