import React, { FC } from 'react'
import { Box } from '@mui/material'
import { EmployeeDetailHeader } from '../../components/employee-details/EmployeeDetailsHeader/EmployeeDetailHeader'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { getById, updateUser } from '../../store/action/user'
import { Wrap } from '../../layout/root/Wrap'
import { InteractiveProvider } from '../../providers/interactive.provider'
import { FlatUser } from '../../types/user/flat-user'
import { meSelector } from '../../store/selector/auth.selector'
import { Action } from '../../components/ui/Form/InteractiveField/components/InteractiveFieldLabel/InteractiveFieldLabel'
import { getUserAccessResource } from '../../utils/get-user-access-resource'
import {
  UserDetailColumn,
  UserDetailContainer,
  UserDetailFieldGroup,
  UserDetailSection,
  UserDetailSectionTitle,
} from '../../components/employee-details/UserDetail/UserDetail.styled'
import { BInteractive } from '../../components/ui/Form/InteractiveField/BInteractiveField'
import { InteractiveMultiField } from '../../components/ui/Form/InteractiveField/InteractiveMultiField'
import { InteractiveSelectField } from '../../components/ui/Form/InteractiveField/InteractiveSelectField'
import { JOB_TITLE_OPTIONS } from '../../constants/job-title.option'
import * as Yup from 'yup'
import { useGetUserOptions } from '../../hooks/useGetUserOptions'
import { PersonalDetails } from '../../components/employee-details/PersonalDetails'

const emailSchema = Yup.string()
  .email('Email not valid')
  .required('Email is required')

type AdminDetailsProps = {
  showProject?: boolean
  user: FlatUser | null
}

export const AdminDetailsPage: FC<AdminDetailsProps> = ({
  showProject,
  user,
}) => {
  const dispatch = useAppDispatch()
  const { projectOptions } = useGetUserOptions()

  const onSubmit = async <T extends keyof FlatUser>(
    data: FlatUser[T] | null,
    name: T
  ) => {
    const id = user?.id
    if (!id) {
      return console.error('User ID is required')
    }
    await dispatch(updateUser({ id, name, data }))
    await dispatch(getById(id))
  }

  const me = useAppSelector(meSelector)

  const getActions = (actions: Action[], isAdminOnly: boolean = false) => {
    return getUserAccessResource(actions, me, isAdminOnly, user)
  }

  return (
    <Wrap bg={'default'}>
      {user && (
        <>
          <EmployeeDetailHeader user={user} />
          <Box
            sx={{
              paddingTop: '8px',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <InteractiveProvider>
              <UserDetailContainer key={user?.id}>
                <UserDetailColumn>
                  <UserDetailSection>
                    <PersonalDetails
                      user={user}
                      onSubmit={onSubmit}
                      getActions={getActions}
                    />
                    <UserDetailSectionTitle variant="body1">
                      Contact Details
                    </UserDetailSectionTitle>
                    <BInteractive
                      name="linkedinUrl"
                      placeholder="LinkedIn"
                      initialValue={user['linkedinUrl']}
                      startAdornment={'https://'}
                      onSubmit={(data) => onSubmit(data, 'linkedinUrl')}
                      actions={getActions(['edit', 'copy'])}
                    />
                    <InteractiveMultiField
                      placeholder="Email"
                      onSubmitPrimary={(data) => onSubmit(data, 'email')}
                      onSubmitAdditional={(data) =>
                        onSubmit(data, 'additionalContactEmails')
                      }
                      primary={user['email']}
                      additional={user['additionalContactEmails']}
                      FieldComponent={BInteractive}
                      InputFieldComponent={BInteractive}
                      validationSchema={emailSchema}
                      additionalActions={getActions(['copy', 'delete', 'edit'])}
                      primaryActions={getActions(['copy', 'edit', 'add'])}
                    />
                  </UserDetailSection>
                </UserDetailColumn>

                <UserDetailColumn>
                  <UserDetailSection>
                    <UserDetailSectionTitle variant="body1">
                      Professional Details
                    </UserDetailSectionTitle>
                    <InteractiveSelectField
                      name="jobTitle"
                      placeholder="Job title"
                      initialValue={user['jobTitle']}
                      onSubmit={(data) => onSubmit(data, 'jobTitle')}
                      options={JOB_TITLE_OPTIONS}
                      actions={getActions(['edit'])}
                    />

                    {showProject && (
                      <UserDetailFieldGroup>
                        <InteractiveSelectField
                          name="projectId"
                          placeholder="Project"
                          actions={getActions(['edit'])}
                          options={projectOptions}
                          initialValue={user['projectId']}
                          onSubmit={(data) => onSubmit(data, 'projectId')}
                        />
                        <InteractiveSelectField
                          name="projectRole"
                          placeholder="Role"
                          options={JOB_TITLE_OPTIONS}
                          actions={getActions(['edit'])}
                          initialValue={user['userProjectRole']}
                          onSubmit={(data) => onSubmit(data, 'userProjectRole')}
                        />
                      </UserDetailFieldGroup>
                    )}
                  </UserDetailSection>
                </UserDetailColumn>
              </UserDetailContainer>
            </InteractiveProvider>
          </Box>
        </>
      )}
    </Wrap>
  )
}
