import { FormInput } from '../FormInput'
import { InputAdornment } from '@mui/material'
import React, { FC } from 'react'
import { CountryAutocomplete } from '../AutoComplete/CountryAutocomplete'
import { useTelInput } from '../../../../hooks/useTelInput'
import { BBadge } from '../../Badge/bBadge/bBadge'

type TelInputProps = {
  name: string
  label?: string
  placeholder: string
}

export const TelInput: FC<TelInputProps> = ({ name, label, placeholder }) => {
  const {
    selectedCountry,
    phoneNumber,
    handleCountryChange,
    handleInputChange,
  } = useTelInput()

  return (
    <FormInput
      label={label}
      name={name}
      placeholder={placeholder}
      type={'number'}
      sx={{
        '& .MuiInputBase-root': {
          paddingRight: '4px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CountryAutocomplete
              onChange={(country) => handleCountryChange(country, name)}
              selected={selectedCountry}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <BBadge
              sx={{ height: '32px' }}
              badgeType={'primary'}
              label={'Primary'}
            />
          </InputAdornment>
        ),
      }}
      onChange={(event) => handleInputChange(event, name)}
      value={phoneNumber.replace(selectedCountry.phone, '') ?? ''}
    />
  )
}
