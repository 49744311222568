import { IconButton } from '@mui/material'
import React, { FC } from 'react'
import { Container, StyledTypography, UserInfoBox } from './UserProfile.styled'
import { BAvatar } from '../bAvatar'

export type UserProfileProps = {
  avatar?: string
  icon?: FC
  firstName: string
  lastName: string
  email?: string
  size?: 'xs' | 'small'
  fullNameColor: string
  endIconClick?: () => void
}
export const UserProfile: React.FC<UserProfileProps> = ({
  icon: Icon,
  email,
  firstName,
  lastName,
  avatar,
  size = 'small',
  fullNameColor,
  endIconClick,
}) => {
  const sizeConfig = {
    xs: {
      avatarSize: 32,
      nameVariant: 'body2' as const,
      emailVariant: 'body2' as const,
      ml: 1,
    },
    small: {
      avatarSize: 40,
      nameVariant: 'body2' as const,
      emailVariant: 'body2' as const,
      ml: 1,
    },
  }

  const { avatarSize, nameVariant, emailVariant, ml } = sizeConfig[size]

  return (
    <Container container>
      <BAvatar avatarSize={avatarSize} src={avatar}>
        {firstName?.[0]}
        {lastName?.[0]}
      </BAvatar>
      <UserInfoBox ml={ml}>
        <StyledTypography
          fontWeight={500}
          color={fullNameColor}
          variant={nameVariant}
        >
          {firstName} {lastName}
        </StyledTypography>
        <StyledTypography
          color="text.tertiary_600"
          sx={{
            display: email ? 'block' : 'none',
          }}
          variant={emailVariant}
          variantMapping={{ body2: 'span' }}
        >
          {email}
        </StyledTypography>
      </UserInfoBox>
      {Icon && (
        <IconButton size="small" onClick={endIconClick}>
          <Icon />
        </IconButton>
      )}
    </Container>
  )
}
