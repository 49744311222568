import { FC, useState } from 'react'
import React from 'react'
import { Box, Typography, SxProps, Theme } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { FieldInputProps, FormikProps } from 'formik/dist/types'
import {
  FileUploadContainer,
  FileUploadDragZone,
  FileUploadErrorText,
  FileUploadHiddenInput,
  FileUploadIconBox,
} from './FileUpload.styles'
import { AvatarPreview } from '../AvatarPreview'

export type FileUploadProps = {
  name: string
  onFileSelect: (file: File | null) => void
  preview?: string
  sx?: SxProps<Theme>
  acceptedFileTypes: string[]
  fileTypeDescription: string
  Icon?: FC
  hideAvatar?: boolean
}

export const FileUpload: FC<FileUploadProps> = ({
  onFileSelect,
  name,
  preview,
  acceptedFileTypes,
  fileTypeDescription,
  hideAvatar = false,
  Icon,
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const handleDrop = async (
    e: React.DragEvent,
    field: FieldInputProps<File>,
    form: FormikProps<unknown>
  ) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) {
      const file = files[0]

      if (!acceptedFileTypes.includes(file.type)) {
        form.setFieldError(name, `Invalid file type`)
        return setIsDragging(false)
      }
      onFileSelect(file)
      await form.setFieldValue(field.name, files[0])
    }
    setIsDragging(false)
  }

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FieldInputProps<File>,
    form: FormikProps<unknown>
  ) => {
    const files = e.target.files

    if (files && files.length > 0) {
      onFileSelect(files[0])
      await form.setFieldValue(field.name, files[0])
    }
  }

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    setIsDragging(false)
  }
  const handleClearFile = async (form: FormikProps<unknown>) => {
    onFileSelect(null)
    await form.setFieldValue(name, {})
  }

  const acceptedFileTypesString = acceptedFileTypes.join(',')

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            display="flex"
            flexDirection={hideAvatar ? 'column' : 'row'}
            gap={2.5}
            sx={{ width: '100%', height: '100%' }}
          >
            <FileUploadDragZone
              onDrop={(e) => handleDrop(e, field, form)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onClick={(e) => e.stopPropagation()}
            />
            {!hideAvatar && (
              <AvatarPreview
                src={preview}
                onClick={() => handleClearFile(form)}
              />
            )}
            {/*<Box>*/}
            <FileUploadContainer
              onDrop={(e) => handleDrop(e, field, form)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={handleDragEnter}
              htmlFor={`file-upload-${name}`}
              isDragging={isDragging}
              hideAvatar={hideAvatar}
            >
              <FileUploadHiddenInput
                accept={acceptedFileTypesString}
                id={`file-upload-${name}`}
                type="file"
                onChange={(e) => handleChange(e, field, form)}
              />
              {Icon && (
                <FileUploadIconBox>
                  <Icon />
                </FileUploadIconBox>
              )}
              <Typography variant="body2" color="text.tertiary_600">
                <Typography
                  variantMapping={{ body2: 'span' }}
                  fontWeight={600}
                  variant="body2"
                  color="text.secondary"
                >
                  Click to upload
                </Typography>
                {' or drag and drop'}
                <br />
                {fileTypeDescription}
              </Typography>
            </FileUploadContainer>
          </Box>
          <Box>
            {meta.error && (
              <FileUploadErrorText>{meta.error}</FileUploadErrorText>
            )}
          </Box>
        </Box>
      )}
    </Field>
  )
}
