import React, { FC } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Box } from '@mui/material'
import { BButton } from '../ui/Button/BButton'
import { ReactComponent as WarningIcon } from '../../assets/svg/warning.svg'
import { FormInput } from '../ui/Form/FormInput'

type ChangePasswordFormProps = {
  onSubmit: (payload: { password: string }) => void
}
const validationSchema = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^A-Za-z0-9]/,
      'Password must contain at least one special character'
    ),
})

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {() => (
        <Form>
          <Box mt={4} mb={3}>
            <FormInput
              name={'password'}
              label={'Password'}
              placeholder={'Enter new password'}
              type={'password'}
              endIcon={WarningIcon}
            />
          </Box>
          <BButton
            fullWidth={true}
            type="submit"
            variant="contained"
            color="primary"
          >
            Get Started
          </BButton>
        </Form>
      )}
    </Formik>
  )
}
