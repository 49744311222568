import React from 'react'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Box, Typography } from '@mui/material'
import { SalesCall } from '../../../types/sales/sales-call'
import { format } from 'date-fns'
import { LinkWrap } from '../../employee-details/documents/LinkWrap'
import { UserProfile } from '../../ui/UserProfile/UserProfile'
import { BSvg } from '../../ui/bSvg'
import { ReactComponent as EyeIcon } from '../../../assets/svg/eye.svg'
import { ReactComponent as TrashIcon } from '../../../assets/svg/delete.svg'
import { ReactComponent as PencilIcon } from '../../../assets/svg/pencil.svg'

export const AdminMedicalColumns: (
  openDeleteModal: (payload: SalesCall) => void
) => GridColDef[] = (openDeleteModal) => [
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 280,
    align: 'left',
    headerAlign: 'left',
    flex: 2,
    renderCell: ({ row }: GridRenderCellParams<SalesCall>) => {
      return (
        <Typography color={'text.primary'} fontSize={14} lineHeight="20px">
          {row?.title}
        </Typography>
      )
    },
  },
  {
    field: 'call_date',
    headerName: 'Call Date',
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<SalesCall>) => {
      const callDate = row?.call_date
      return (
        <Typography fontSize={14} lineHeight="20px">
          {format(callDate, 'dd/MM/yyyy')}
        </Typography>
      )
    },
  },
  {
    field: 'user_id',
    headerName: 'Uploaded by',
    minWidth: 300,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<SalesCall>) => {
      const link = `/employee/${row?.user_id}`
      const user = row?.user
      return (
        <LinkWrap to={link}>
          <UserProfile
            avatar={user?.profilePictureUrl}
            firstName={user?.firstName ?? ''}
            lastName={user?.lastName ?? ''}
            email={user?.email}
            fullNameColor={'text.primary'}
          />
        </LinkWrap>
      )
    },
  },
  {
    field: 'created_at',
    headerName: 'Upload Date',
    minWidth: 120,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<SalesCall>) => {
      const createdAt = row.created_at
      const formatted = createdAt ? format(createdAt, 'dd/MM/yyyy') : 'n/a'
      return (
        <Typography fontSize={14} lineHeight="20px">
          {formatted}
        </Typography>
      )
    },
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 160,
    sortable: false,
    resizable: false,
    // align: 'right',
    headerAlign: 'right',
    flex: 0,
    renderCell: ({ row }: GridRenderCellParams<SalesCall>) => {
      const link = `/medical/${row?.id}`
      return (
        <Box sx={{ display: 'flex', gap: 3 }}>
          <BSvg link={link} component={EyeIcon} size={'20px'} />{' '}
          <BSvg
            component={TrashIcon}
            size={'20px'}
            onClick={() => openDeleteModal(row)}
          />
          <BSvg component={PencilIcon} size={'20px'} />
        </Box>
      )
    },
  },
]
