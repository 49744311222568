import { RefObject, useEffect } from 'react'

const COLUMN_WIDTH = 200

export const useTableResize = (
  containerRef: RefObject<HTMLDivElement>,
  setItemsPerPage: (count: number) => void,
  setCurrentPage: (page: number) => void
) => {
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const newItemsPerPage = Math.floor(containerWidth / COLUMN_WIDTH)
        setItemsPerPage(Math.max(1, newItemsPerPage))
        setCurrentPage(1)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [containerRef, setItemsPerPage, setCurrentPage])
}
