import { Option } from '../types/common/option'

export const COMPENSATION_TYPE_OPTIONS: Option[] = [
  {
    value: 'bonus',
    label: 'Bonus',
  },
  {
    value: 'fee',
    label: 'Fee',
  },
  {
    value: 'other',
    label: 'Other',
  },
]
