import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { StyledTextField } from '../../ui/TextFiled/TextField.styled'

export const TableSearchContainer = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  padding: '12px 16px',
  backgroundColor: theme.palette.background.paper,
}))

export const TableSearchTextField = styled(StyledTextField)(({ theme }) => ({
  maxWidth: 400,
  height: 44,
  backgroundColor: theme.palette.background.default,
  borderRadius: '12px',
}))
