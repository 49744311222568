import React, { FC } from 'react'
import { Box, Slide, Typography } from '@mui/material'
import { LabelActions } from './LabelActions'
import { Action } from './InteractiveFieldLabel/InteractiveFieldLabel'
import { BAvatar } from '../../../bAvatar'
import { getInitials } from '../../../../../utils/get-initials'

interface LabelContentProps {
  startPreLabel?: string
  image?: string
  value?: string
  isHovered: boolean
  actions: Action[]
  handlers: Record<string, (() => void) | undefined>
  containerRef: React.RefObject<HTMLElement>
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>
}

export const LabelContent: FC<LabelContentProps> = ({
  startPreLabel,
  image,
  value,
  isHovered,
  actions,
  handlers,
  containerRef,
  setIsHovered,
}) => {
  return (
    <>
      <Box display="flex" alignItems="center" gap={1} top={0} zIndex={2}>
        {image && (
          <BAvatar src={image} avatarSize={20}>
            {getInitials(value)}
          </BAvatar>
        )}
        <Typography
          variant="body2"
          color={value ? 'text.primary' : 'text.tertiary_600'}
          fontWeight={600}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {value ? `${startPreLabel ?? ''}${value}` : 'n/a'}
        </Typography>
      </Box>
      <Slide
        style={{
          display: actions?.length > 0 ? 'flex' : 'none',
          alignItems: 'center',
        }}
        direction="left"
        in={isHovered}
        mountOnEnter
        unmountOnExit
        container={containerRef.current}
      >
        <Box>
          <LabelActions
            setIsHovered={setIsHovered}
            actions={actions}
            handlers={handlers}
            value={value}
          />
        </Box>
      </Slide>
    </>
  )
}
