import React, { FC } from 'react'
import { JOB_TITLE_OPTIONS } from '../../../constants/job-title.option'
import { InteractiveSelectField } from '../../ui/Form/InteractiveField/InteractiveSelectField'
import { RECRUIT_SOURCE_OPTIONS } from '../../../constants/recruit-source.options'
import { getById, updateUser } from '../../../store/action/user'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { useGetUserOptions } from '../../../hooks/useGetUserOptions'
import { FlatUser } from '../../../types/user/flat-user'
import { BInteractive } from '../../ui/Form/InteractiveField/BInteractiveField'
import { InteractiveMultiField } from '../../ui/Form/InteractiveField/InteractiveMultiField'
import * as Yup from 'yup'
import { LEVEL_OPTIONS } from '../../../constants/level.options'
import { InteractiveTelInputField } from '../../ui/Form/InteractiveField/components/TelInput'
import {
  UserDetailContainer,
  UserDetailColumn,
  UserDetailSection,
  UserDetailSectionTitle,
  UserDetailFieldGroup,
} from './UserDetail.styled'
import { Action } from '../../ui/Form/InteractiveField/components/InteractiveFieldLabel/InteractiveFieldLabel'
import { getUserAccessResource } from '../../../utils/get-user-access-resource'
import { meSelector } from '../../../store/selector/auth.selector'
import { PersonalDetails } from '../PersonalDetails'

type UserDetailProps = {
  user: FlatUser
}

const emailSchema = Yup.string()
  .email('Email not valid')
  .required('Email is required')
const phoneNumberSchema = Yup.string().required().min(5)

export const UserDetail: FC<UserDetailProps> = ({ user }) => {
  const dispatch = useAppDispatch()
  const { userOptions, projectOptions } = useGetUserOptions()

  const onSubmit = async <T extends keyof FlatUser>(
    data: FlatUser[T] | null,
    name: T
  ) => {
    const id = user?.id
    if (!id) {
      return console.error('User ID is required')
    }
    await dispatch(updateUser({ id, name, data }))
    await dispatch(getById(id))
  }

  const fieldProps = {
    onSubmit,
  }

  const currentUser = useAppSelector(meSelector)

  const getActions = (actions: Action[], isAdminOnly: boolean = false) => {
    return getUserAccessResource(actions, currentUser, isAdminOnly, user)
  }

  return (
    <UserDetailContainer key={user?.id}>
      <UserDetailColumn>
        <PersonalDetails
          user={user}
          onSubmit={onSubmit}
          getActions={getActions}
        />
        <UserDetailSection>
          <UserDetailSectionTitle variant="body1">
            Contact Details
          </UserDetailSectionTitle>
          <BInteractive
            name="linkedinUrl"
            placeholder="LinkedIn"
            initialValue={user['linkedinUrl']}
            startAdornment={'https://'}
            onSubmit={(data) => onSubmit(data, 'linkedinUrl')}
            actions={getActions(['edit', 'copy'])}
          />
          <InteractiveMultiField
            placeholder="Email"
            onSubmitPrimary={(data) => onSubmit(data, 'email')}
            onSubmitAdditional={(data) =>
              onSubmit(data, 'additionalContactEmails')
            }
            primary={user['email']}
            additional={user['additionalContactEmails']}
            FieldComponent={BInteractive}
            InputFieldComponent={BInteractive}
            validationSchema={emailSchema}
            additionalActions={getActions(['copy', 'delete', 'edit'])}
            primaryActions={getActions(['copy', 'edit', 'add'])}
          />
          <InteractiveMultiField
            placeholder="Phone number"
            primary={user['phoneNumber']}
            additional={user['additionalContactNumbers']}
            FieldComponent={InteractiveTelInputField}
            InputFieldComponent={InteractiveTelInputField}
            validationSchema={phoneNumberSchema}
            onSubmitPrimary={(data) => onSubmit(data, 'phoneNumber')}
            onSubmitAdditional={(data) =>
              onSubmit(data, 'additionalContactNumbers')
            }
            additionalActions={getActions(['copy', 'delete', 'edit'])}
            primaryActions={getActions(['copy', 'edit', 'add'])}
          />
          <BInteractive
            name="telegram"
            placeholder="Telegram nickname"
            initialValue={user['telegram']}
            onSubmit={(data) => onSubmit(data, 'telegram')}
            actions={getActions(['edit', 'copy'])}
            startAdornment={'@'}
          />
          <BInteractive
            name="address"
            placeholder="Address"
            initialValue={user['address']}
            onSubmit={(data) => onSubmit(data, 'address')}
            actions={getActions(['edit', 'copy'])}
          />
        </UserDetailSection>
      </UserDetailColumn>

      <UserDetailColumn>
        <UserDetailSection>
          <UserDetailSectionTitle variant="body1">
            Professional Details
          </UserDetailSectionTitle>
          <InteractiveSelectField
            name="jobTitle"
            placeholder="Job title"
            initialValue={user['jobTitle']}
            onSubmit={(data) => onSubmit(data, 'jobTitle')}
            options={JOB_TITLE_OPTIONS}
            actions={getActions(['edit'])}
          />
          <InteractiveSelectField
            name="positionLevel"
            placeholder="Level"
            actions={getActions(['edit'])}
            options={LEVEL_OPTIONS}
            initialValue={user['positionLevel']}
            onSubmit={(data) => onSubmit(data, 'positionLevel')}
          />
          <UserDetailFieldGroup>
            <InteractiveSelectField
              name="projectId"
              placeholder="Project"
              actions={getActions(['edit'])}
              options={projectOptions}
              initialValue={user['projectId']}
              onSubmit={(data) => onSubmit(data, 'projectId')}
            />
            <InteractiveSelectField
              name="projectRole"
              placeholder="Role"
              {...fieldProps}
              options={JOB_TITLE_OPTIONS}
              actions={getActions(['edit'])}
              initialValue={user['userProjectRole']}
              onSubmit={(data) => onSubmit(data, 'userProjectRole')}
            />
          </UserDetailFieldGroup>
          <InteractiveSelectField
            name="reportToId"
            placeholder="Reporting to"
            actions={getActions(['edit'])}
            initialValue={user['reportToId']}
            onSubmit={(data) => onSubmit(data, 'reportToId')}
            options={userOptions}
          />
          <InteractiveSelectField
            name="hrId"
            actions={getActions(['edit'])}
            placeholder="HR"
            options={userOptions}
            initialValue={user['hrId']}
            onSubmit={(data) => onSubmit(data, 'hrId')}
          />
          <InteractiveSelectField
            name="recruiterId"
            placeholder="Recruiter"
            initialValue={user['recruiterId']}
            onSubmit={(data) => onSubmit(data, 'recruiterId')}
            actions={getActions(['edit'])}
            options={userOptions}
          />
          <InteractiveSelectField
            name="recruitmentSource"
            placeholder="Source"
            actions={getActions(['edit'])}
            initialValue={user['recruitmentSource']}
            onSubmit={(data) => onSubmit(data, 'recruitmentSource')}
            options={RECRUIT_SOURCE_OPTIONS}
          />
        </UserDetailSection>
      </UserDetailColumn>
    </UserDetailContainer>
  )
}
