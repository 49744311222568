import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationResponse } from '../../types/common/pagination-response'
import { SalesCall } from '../../types/sales/sales-call'
import {
  getAllSalesCall,
  getDefaultSalesCallValues,
  getSalesAnalyzeCriteria,
  getSalesCallById,
  getSpeechToTextOptions,
} from '../action/sales-call'
import { Option } from '../../types/common/option'
import { GetDefaultCallValuesDto } from '../../types/sales/get-default-call-values-dto'
import { AnalyzeCriteria } from '../../types/sales/analyze-criteria'
import { Medical, MedicalWithUser } from '../../types/medical/medical'
import { getAllMedical, getMedicalById } from '../action/medical'

interface Slice {
  list: Medical[]
  count: number
  speechToTextOptions: Option[]
  defaultCallValues: GetDefaultCallValuesDto | null
  medicalItem: Medical | null
}

const initialState: Slice = {
  list: [],
  count: 0,
  speechToTextOptions: [],
  defaultCallValues: null,
  medicalItem: null,
}

export const medicalSlice = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    clearMedical: (state) => {
      state.medicalItem = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllMedical.fulfilled,
        (state, { payload }: PayloadAction<PaginationResponse<Medical>>) => {
          state.list = payload.rows
          state.count = payload.count
        }
      )
      .addCase(
        getSpeechToTextOptions.fulfilled,
        (state, { payload }: PayloadAction<Option[]>) => {
          state.speechToTextOptions = payload
        }
      )
      .addCase(
        getDefaultSalesCallValues.fulfilled,
        (state, { payload }: PayloadAction<GetDefaultCallValuesDto>) => {
          state.defaultCallValues = payload
        }
      )
      .addCase(
        getMedicalById.fulfilled,
        (state, { payload }: PayloadAction<Medical>) => {
          state.medicalItem = payload
        }
      )
      .addCase(
        getSalesAnalyzeCriteria.fulfilled,
        (state, { payload }: PayloadAction<AnalyzeCriteria[]>) => {
          state.analyzeCriteria = payload
        }
      )
  },
})
export const { clearMedical } = medicalSlice.actions
export default medicalSlice.reducer
