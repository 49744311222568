import React, { FC, useState } from 'react'
import { SxProps, Slide, Box, Typography } from '@mui/material'
import {
  TextAriaLabelContainer,
  TextAriaLabelHeader,
  TextAriaLabelText,
  TextAriaLabelList,
  TextAriaLabelListItem,
} from './TextAriaLabel.styled'
import { LabelActions } from '../LabelActions'
import {
  Action,
  ActionHandlers,
} from '../InteractiveFieldLabel/InteractiveFieldLabel'
import CircleIcon from '@mui/icons-material/Circle'
import { StyledInteractiveField } from '../StyledInteractiveField'

interface TextAriaLabelProps {
  placeholder: string
  value?: string
  primary?: boolean
  actions: Action[]
  handlers?: ActionHandlers
  multiline?: boolean
  sx?: SxProps
}

export const TextAriaLabel: FC<TextAriaLabelProps> = ({
  placeholder,
  value = 'n/a',
  actions = [],
  handlers = {},
  sx,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const containerRef = React.useRef<HTMLElement>(null)
  const lines = value?.split('\n') ?? []

  return (
    <TextAriaLabelContainer ref={containerRef}>
      <StyledInteractiveField
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={sx}
        multiline={true}
      >
        <TextAriaLabelHeader>
          <TextAriaLabelText variant="body2">{placeholder}</TextAriaLabelText>
          <Slide
            style={{ display: actions?.length > 0 ? 'flex' : 'none' }}
            direction="left"
            in={isHovered}
            mountOnEnter
            unmountOnExit
            container={containerRef.current}
          >
            <Box>
              <LabelActions
                setIsHovered={setIsHovered}
                actions={actions}
                handlers={handlers}
                value={value}
              />
            </Box>
          </Slide>
        </TextAriaLabelHeader>

        <Box
          display="flex"
          alignItems="value"
          gap={1}
          position="relative"
          zIndex={2}
          width="100%"
        >
          <TextAriaLabelList>
            {lines
              .filter((line) => line.trim() !== '')
              .map((line, index) => (
                <TextAriaLabelListItem key={index}>
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: 'text.primary', width: '7px', mr: '5px' }}
                  />
                  <Typography
                    variant="body2"
                    color={value ? 'text.primary' : 'text.tertiary_600'}
                    fontWeight={600}
                    sx={{
                      width: '100%',
                      height: '100%',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {line}
                  </Typography>
                </TextAriaLabelListItem>
              ))}
          </TextAriaLabelList>
        </Box>
      </StyledInteractiveField>
    </TextAriaLabelContainer>
  )
}
