import React, { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { DialogTree } from '../../ui/Dialog/DialogTree/DialogTree'
import { speechToTextOptionSelector } from '../../../store/selector/sales-call'
import {
  addTranscription,
  createSalesCall,
  getSpeechToTextOptions,
} from '../../../store/action/sales-call'
import {
  NewSalesCallType,
  salesCallInitialValues,
  salesCallModalSteps,
} from './sales-call.modal.config'
import { ModalFooter } from '../../employee/Modal/ModalFooter'
import { FormikProps } from 'formik/dist/types'

type AddSalesCallModalProps = {
  onClose: () => void
  newCall: NewSalesCallType
  setNewCall: (newCall: NewSalesCallType) => void
}

export const AddSalesCallModalContent: FC<AddSalesCallModalProps> = ({
  newCall,
  onClose,
  setNewCall,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [salesCallId, setSalesCallId] = useState<string | null>(null)
  const formRef = useRef<FormikProps<Partial<NewSalesCallType>>>(null)
  const dispatch = useAppDispatch()
  const speechToTextOptions = useAppSelector(speechToTextOptionSelector)

  useEffect(() => {
    if (speechToTextOptions.length > 1) return
    dispatch(getSpeechToTextOptions())
  }, [dispatch, speechToTextOptions])

  const handleCreateSalesCall = async (newData: Partial<NewSalesCallType>) => {
    const res = await dispatch(
      createSalesCall({ ...newCall, ...newData })
    ).unwrap()
    setSalesCallId(res.id?.toString())
    setActiveIndex((prev) => prev + 1)
    setNewCall({ ...newCall, ...newData })
  }

  const handleAddTranscription = async (newData: Partial<NewSalesCallType>) => {
    console.log(newData)
    if (!salesCallId || !newData.audio_link) {
      console.error('Sales call ID or link not found')
      return
    }

    const transcriptionData = {
      speakerNumber: newData.count_of_speakers as string,
      speechToTextSource: newData.text_source as string,
      audioLink: newData.audio_link as string,
    }

    dispatch(
      addTranscription({
        ...transcriptionData,
        id: salesCallId,
      })
    )

    onClose()
    setNewCall(salesCallInitialValues)
  }

  const handleNextStep = async (newData: Partial<NewSalesCallType>) => {
    if (activeIndex === 0) {
      await handleCreateSalesCall(newData)
    } else {
      await handleAddTranscription(newData)
    }
  }

  const triggerSubmit = async () => {
    if (formRef.current) {
      await formRef.current.submitForm()
    }
  }

  const { component: StepComponent } = salesCallModalSteps[activeIndex]
  return (
    <>
      <DialogTree
        steps={salesCallModalSteps.map((step) => step.title)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />

      <StepComponent
        formRef={formRef}
        onSubmit={handleNextStep}
        initialValues={newCall}
        onClose={onClose}
      />
      <ModalFooter
        onClose={onClose}
        onSubmit={triggerSubmit}
        submitLabel={activeIndex === 0 ? 'Next' : 'Add Call'}
      />
    </>
  )
}
