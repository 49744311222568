import React, { FC } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import loginBackgroundImage from '../../assets/auth_mock.png'
import changePasswordBackgroundImage from '../../assets/change_password.png'
import {
  AuthLayoutContainer,
  AuthLayoutContentBox,
  AuthLayoutImageBox,
} from './AuthLayout.styled'

export const AuthLayout: FC = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const location = useLocation()

  const backgroundImage =
    location?.pathname === '/auth/login'
      ? loginBackgroundImage
      : changePasswordBackgroundImage

  return (
    <AuthLayoutContainer>
      <AuthLayoutContentBox>
        <Outlet />
      </AuthLayoutContentBox>
      {isDesktop && backgroundImage && (
        <AuthLayoutImageBox>
          <img src={backgroundImage} alt={'Background'} />
        </AuthLayoutImageBox>
      )}
    </AuthLayoutContainer>
  )
}
