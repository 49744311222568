import { BDialog } from './bDialog'
import { DialogActions, DialogProps } from '@mui/material'
import { BButton } from '../Button/BButton'
import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { AnyObject, ObjectSchema } from 'yup'

type FormDialogProps<T> = DialogProps & {
  title: string
  subTitle?: string
  icon?: FC
  onClose?: () => void
  width?: string
  pb?: string
  validationSchema?: ObjectSchema<AnyObject>
  initialValue?: T
  handleSubmit: (values: T) => void
  submitLabel?: string
  cancelLabel?: string
}

export const FormDialog = <T extends Record<string, unknown>>({
  children,
  initialValue = {} as T,
  handleSubmit,
  validationSchema,
  submitLabel = 'Save',
  cancelLabel = 'Cancel',
  ...rest
}: FormDialogProps<T>) => {
  return (
    <BDialog {...rest}>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {() => (
          <Form>
            {children}
            <DialogActions>
              <BButton color={'secondary'} onClick={rest?.onClose}>
                {cancelLabel}
              </BButton>
              <BButton color={'primary'} type={'submit'}>
                {submitLabel}
              </BButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </BDialog>
  )
}
