import React, { FC, useEffect, useState, ReactNode } from 'react'
import { Box, SelectChangeEvent } from '@mui/material'
import { Option } from '../../../../types/common/option'
import { findOption } from '../../../../utils/find-option'
import { InputWrap } from './components/InputWrap'
import { SelectBase } from '../Select/SelectBase'
import {
  Action,
  ActionHandlers,
  InteractiveFieldLabel,
} from './components/InteractiveFieldLabel/InteractiveFieldLabel'
import { useInteractiveModal } from '../../../../providers/interactive.provider'
import { IconComponent } from './components/IconComponent'

type InteractiveSelectFieldProps = {
  name: string
  placeholder: string
  initialValue?: string | number
  onSubmit: (data: string) => Promise<void>
  options: Option[]
  actions: Action[]
  handlers?: ActionHandlers
  children?: (selected: Option['value']) => ReactNode
}

export const InteractiveSelectField: FC<InteractiveSelectFieldProps> = ({
  placeholder,
  name,
  initialValue = '',
  onSubmit,
  options,
  actions,
  handlers,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState<string>(initialValue?.toString() || '')
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  const { isExistEditing, setIsExistEditing } = useInteractiveModal()

  handlers = { edit: () => toggleEditing(), ...handlers }

  useEffect(() => {
    const option = findOption(options, initialValue)
    if (option) {
      setValue(option?.value as string)
      setSelectedOption(option)
    }
  }, [name, initialValue, options])

  const toggleEditing = () => {
    if (isExistEditing && !isEditing) {
      return
    }
    setIsEditing((prev) => !prev)
    setIsExistEditing((prev) => !prev)
  }

  const setInitialState = () => {
    const initialOption = findOption(options, initialValue)
    const label = initialOption?.label ?? initialOption?.value ?? ''
    setSelectedOption({
      value: initialValue?.toString() ?? '',
      label,
    })
    setValue(initialValue?.toString() ?? '')
  }

  const handleClose = () => {
    setInitialState()
    toggleEditing()
  }

  const handleSubmit = async (data: string) => {
    setInitialState()
    await onSubmit(data)
    toggleEditing()
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as string
    const option = options.find((opt) => opt.value === newValue)
    setValue(newValue)
    if (option) {
      setSelectedOption(option)
    }
  }

  const labelValue = (selectedOption?.label ??
    selectedOption?.value ??
    value) as string

  return (
    <Box
      flex={1}
      display={'flex'}
      alignItems={'center'}
      gap={2}
      overflow={'hidden'}
    >
      {isEditing ? (
        <InputWrap
          value={value}
          changeEditing={toggleEditing}
          onSubmit={handleSubmit}
          sx={{ flex: 1, overflow: 'hidden' }}
        >
          {(toggleModal) => (
            <SelectBase
              placeholder={placeholder}
              options={options}
              value={value}
              onChange={handleChange}
              onOpen={toggleModal}
              onClose={toggleModal}
              IconComponent={({ className }) => (
                <IconComponent
                  className={className}
                  onSave={() => handleSubmit(value)}
                  onCancel={handleClose}
                />
              )}
            />
          )}
        </InputWrap>
      ) : (
        <InteractiveFieldLabel
          placeholder={placeholder}
          value={labelValue}
          image={selectedOption?.image}
          actions={actions}
          handlers={handlers}
        />
      )}
      {children && children(value)}
    </Box>
  )
}
