import React from 'react'
import { Box, TypeBackground } from '@mui/material'
import { FC, ReactNode } from 'react'

type WrapProps = {
  bg: keyof TypeBackground
  children: ReactNode
}
export const Wrap: FC<WrapProps> = ({ bg, children }) => {
  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background[bg],
        overflow: 'hidden',
        height: '100vh',
      })}
    >
      {children}
    </Box>
  )
}
