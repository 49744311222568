import { RootState } from '../index'

export const medicalListSelector = (state: RootState) => state.medical.list

export const medicalCountSelector = (state: RootState) => state.medical.count

export const getDefaultSalesCallValueSelector = (state: RootState) =>
  state.sales.defaultCallValues

export const medicalItemSelector = (state: RootState) =>
  state.medical.medicalItem

export const analyzeCriteriaSelector = (state: RootState) =>
  state.sales.analyzeCriteria
