export class LocalStorage {
  static set(key: string, value: string | null): void {
    const store = localStorage.getItem('persist:root')
    if (!store) return
    const parsedStore = JSON.parse(store)
    parsedStore[key] = JSON.stringify(value)
    localStorage.setItem('persist:root', JSON.stringify(parsedStore))
  }
  static get(key: string): string | null {
    const store = localStorage.getItem('persist:root')
    if (!store) return null
    const parsedStore = JSON.parse(store)
    return parsedStore?.[key] ? JSON.parse(parsedStore[key]) : null
  }
}
