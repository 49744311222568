import { Theme } from '@mui/material'

export const StyledMenuProps = {
  sx: {
    '& .MuiPopover-paper': {
      marginTop: '8px',
      backgroundColor: 'background.default',
      borderRadius: '8px',
      border: '1px solid',
      borderColor: 'border.secondary',
    },
    '& .MuiList-root': {
      backgroundColor: 'background.default',
      borderRadius: '8px',
      maxHeight: '220px',
      overflowY: 'auto',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 10px',
      margin: '0.5px 6px',
      borderRadius: '8px',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: 'border.secondary',
      },
      '&.Mui-selected': {
        backgroundColor: (theme: Theme) =>
          `${theme.palette?.border?.secondary} !important`,
        '&:hover': {
          backgroundColor: (theme: Theme) =>
            `${theme.palette.border?.secondary} !important`,
        },
      },
    },
  },
}
