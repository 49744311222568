import * as React from 'react'
import {
  Action,
  ActionHandlers,
  InteractiveFieldLabel,
} from './components/InteractiveFieldLabel/InteractiveFieldLabel'
import * as Yup from 'yup'
import { InputWrap } from './components/InputWrap'
import { format, isValid } from 'date-fns'
import { DateInput } from '../DateInput/DateInput'
import { useInteractiveField } from '../../../../hooks/useInteractiveField'

type InteractiveDateFieldProps = {
  placeholder: string
  initialValue?: string
  validationSchema?: Yup.Schema<unknown>
  onSubmit: (data: string | null) => Promise<void>
  actions: Action[]
  handlers?: ActionHandlers
}

export const InteractiveDateField = ({
  placeholder,
  initialValue = '',
  validationSchema,
  onSubmit,
  actions,
  handlers = {},
}: InteractiveDateFieldProps) => {
  const {
    isEditing,
    value,
    setValue,
    toggleEditing,
    handleSave,
    handleDelete,
  } = useInteractiveField(initialValue, false, validationSchema, onSubmit)

  const mergedHandlers = {
    edit: toggleEditing,
    delete: handleDelete,
    ...handlers,
  }

  const labelValue =
    value && isValid(new Date(value))
      ? format(new Date(value), 'dd/MM/yyyy')
      : undefined

  return (
    <>
      {isEditing ? (
        <InputWrap
          onSubmit={handleSave}
          value={value}
          changeEditing={toggleEditing}
        >
          {() => (
            <DateInput
              onSave={handleSave}
              onCancel={() => toggleEditing()}
              initialValue={value}
              validationSchema={validationSchema}
              error={null}
              setValue={setValue}
            />
          )}
        </InputWrap>
      ) : (
        <InteractiveFieldLabel
          placeholder={placeholder}
          value={labelValue}
          actions={actions}
          handlers={mergedHandlers}
        />
      )}
    </>
  )
}
