import React from 'react'
import { IconButton, InputAdornment, SvgIcon } from '@mui/material'
import { ReactComponent as EyeIcon } from '../../../../assets/svg/eye.svg'
import { ReactComponent as EyeOffIcon } from '../../../../assets/svg/eye-off.svg'

interface PasswordAdornmentProps {
  showPassword: boolean
  handleClickShowPassword: () => void
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void
  isPassword: boolean
  endIcon?: React.ElementType
  hasError: boolean
  fieldValue: string
}

const PasswordAdornment: React.FC<PasswordAdornmentProps> = ({
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  isPassword,
  endIcon,
  hasError,
  fieldValue,
}) => {
  return (
    <InputAdornment position="end">
      {isPassword && (!hasError || fieldValue) ? (
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <EyeOffIcon /> : <EyeIcon width={20} height={20} />}
        </IconButton>
      ) : endIcon && hasError ? (
        <SvgIcon
          sx={(theme) => ({
            fill: 'inherit',
            width: 16,
            '& path': {
              stroke: theme.palette.border?.error,
            },
          })}
          inheritViewBox={true}
          component={endIcon}
          fontSize="small"
        />
      ) : null}
    </InputAdornment>
  )
}

export default PasswordAdornment
