import {
  analyzeSalesCall,
  getSalesCallById,
  updateSalesCall,
} from '../store/action/sales-call'
import React, { FC, useEffect, useState } from 'react'
import { useAnalyzeCriteria } from '../hooks/sales-details/useAnalyzeCriteria'
import { useParams } from 'react-router-dom'
import { saleCallSelector } from '../store/selector/sales-call'
import { useAppDispatch, useAppSelector } from '../hooks/store'
import { clearSalesCall } from '../store/slice/sales-call.slice'
import { useCallTranscription } from '../hooks/sales-details/useCallTranscription'
import { Wrap } from '../layout/root/Wrap'
import { InnerWrap } from '../layout/root/InnerWrap'
import { Box } from '@mui/material'
import { HeadingRow } from '../components/common/HeadingRow/HeadingRow'
import { BButton } from '../components/ui/Button/BButton'
import { StyledTextField } from '../components/ui/TextFiled/TextField.styled'
import { AnalyzeCriteriaDialog } from '../components/sales-details/AnalyzeCriteriaDialog'
import { AnalysisResults } from '../components/sales-details/AnalysisResults'
import { CategoryEnum } from '../types/sales/analyze-criteria'

export const SalesCallDetailsPage: FC = () => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const salesCall = useAppSelector(saleCallSelector)
  const { id } = useParams()

  const { selectedCriteria, toggleCriteria, resetCriteria, analyzeCriteria } =
    useAnalyzeCriteria(CategoryEnum.SALES)

  const { transcription, handleChange } = useCallTranscription(salesCall)

  useEffect(() => {
    if (!id || open) return
    dispatch(getSalesCallById(id))
    return () => {
      clearSalesCall()
    }
  }, [dispatch, id, open])

  const handleUpdateTranscription = async () => {
    if (!id) return
    await dispatch(
      updateSalesCall({ id, name: 'transcription', data: transcription })
    )
    await dispatch(getSalesCallById(id))
  }

  const handleAnalyzeSubmit = async () => {
    if (!id || !analyzeCriteria) return

    const criteria = analyzeCriteria
      .filter(({ key }) => selectedCriteria.includes(key))
      .map(({ key, ai_instructions }) => ({ key, ai_instructions }))

    dispatch(analyzeSalesCall({ id, criteria })).then(() => {
      dispatch(getSalesCallById(id))
    })
    setOpen(false)
    resetCriteria()
  }

  return (
    <Wrap bg="default">
      <InnerWrap>
        <Box
          display="flex"
          flexDirection="column"
          gap={2.3}
          pb={2.3}
          flex={1}
          overflow="hidden"
        >
          <HeadingRow
            title="Sales Analyzer"
            subTitle="Get a detailed analysis of your calls and improve them one by one!"
          >
            <BButton
              color="primary"
              variant="outlined"
              onClick={() => setOpen(true)}
            >
              Analyze
            </BButton>
            <BButton
              color="primary"
              variant="outlined"
              onClick={handleUpdateTranscription}
            >
              Update
            </BButton>
          </HeadingRow>

          <Box display="flex">
            {salesCall && (
              <StyledTextField
                name="transcription"
                size="small"
                type="text"
                rows={15}
                multiline
                value={transcription}
                onChange={handleChange}
              />
            )}
          </Box>

          <AnalyzeCriteriaDialog
            open={open}
            onClose={() => setOpen(false)}
            onSubmit={handleAnalyzeSubmit}
            analyzeCriteria={analyzeCriteria}
            selectedCriteria={selectedCriteria}
            onToggleCriteria={toggleCriteria}
          />

          <AnalysisResults
            salesCall={salesCall}
            analyzeCriteria={analyzeCriteria}
          />
        </Box>
      </InnerWrap>
    </Wrap>
  )
}
