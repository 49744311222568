import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { FormInput } from '../../ui/Form/FormInput'
import { FormDateInput } from '../../ui/Form/DateInput/FormDateInput'
import React, { FC, useEffect } from 'react'
import { MultiStepModalProps } from '../../../types/multi-step-modal'
import { salesCallInitialValues } from './sales-call.modal.config'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import {
  getDefaultSalesCallValues,
  getSpeechToTextOptions,
} from '../../../store/action/sales-call'
import {
  getDefaultSalesCallValueSelector,
  speechToTextOptionSelector,
} from '../../../store/selector/sales-call'

export const AddCallFirstStep: FC<
  MultiStepModalProps<typeof salesCallInitialValues>
> = ({ initialValues, onSubmit, onClose, formRef }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .max(100, 'Title must be at most 100 characters long'),
    call_date: Yup.date().required('Call date is required').nullable(),
    sales_company_details: Yup.string().required('Sales Company is required'),
    sales_person_details: Yup.string().required(
      'Sales Person Details are required'
    ),
    client_company_details: Yup.string().required('Client Company is required'),
    client_person_details: Yup.string().required(
      'Client Person Details are required'
    ),
    additional_information: Yup.string(),
  })
  const options = useAppSelector(speechToTextOptionSelector)
  const { salesCompanyDetails, salesPersonDetails } =
    useAppSelector(getDefaultSalesCallValueSelector) ?? {}
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getSpeechToTextOptions())
    dispatch(getDefaultSalesCallValues())
  }, [dispatch])
  return (
    <Formik
      initialValues={{
        ...initialValues,
        sales_company_details: salesCompanyDetails ?? '',
        sales_person_details: salesPersonDetails ?? '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {() => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <FormInput
              multiline
              name={'title'}
              label={'Title'}
              placeholder={'Enter title'}
            />
            <FormDateInput name="call_date" label="Call date" />
            <FormInput
              name={'sales_company_details'}
              label={'Sales Company'}
              placeholder={'Enter Sales Company'}
            />
            <FormInput
              name={'sales_person_details'}
              label={'Sales Person Details'}
              placeholder={'Enter Sales Person Details'}
            />
            <FormInput
              name={'client_company_details'}
              label={'Client Company'}
              placeholder={'Enter Client Company'}
            />
            <FormInput
              name={'client_person_details'}
              label={'Client Person Details'}
              placeholder={'Enter Client Details'}
            />
            <FormInput
              name={'additional_information'}
              label={'Additional Information'}
              placeholder={'Enter Additional Information'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
