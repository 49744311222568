import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'

export const FileUploadContainer = styled('label', {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'hideAvatar',
})<{ isDragging: boolean; hideAvatar: boolean }>(
  ({ theme, isDragging, hideAvatar }) => ({
    border: `1px solid ${theme.palette.border?.secondary}`,
    borderRadius: '12px',
    padding: '12px 0',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: hideAvatar ? 'column' : 'row',
    gap: '12px',
    // position: 'relative',
    borderColor: isDragging ? theme.palette.border?.brand : undefined,
    '&:hover': {
      borderColor: theme.palette.border?.brand,
    },
  })
)

export const FileUploadHiddenInput = styled('input')({
  display: 'none',
})

export const FileUploadDragZone = styled('div')(() => ({
  height: '100%',
  width: '100%',
  position: 'fixed',
  left: 0,
  top: 0,
}))

export const FileUploadIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: '8px',
  border: '1px solid',
  borderColor: theme.palette.border?.primary,
}))

export const FileUploadErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.border?.error,
  fontSize: 12,
  marginX: '14px',
  marginTop: '5px',
}))
