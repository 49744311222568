import React, { FC } from 'react'
import { FormInput } from '../FormInput'
import { IconButton, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TextFieldProps } from '@mui/material'
import { CountryAutocomplete } from '../AutoComplete/CountryAutocomplete'
import { useTelInput } from '../../../../hooks/useTelInput'

type AdditionalTelInput = {
  name: string
  placeholder: string
  index: number
  label?: string
  remove: (index: number) => void
} & TextFieldProps
export const AdditionalTelInput: FC<AdditionalTelInput> = ({
  name,
  remove,
  index,
  placeholder,
  label,
}) => {
  const {
    selectedCountry,
    phoneNumber,
    handleCountryChange,
    handleInputChange,
  } = useTelInput()

  return (
    <FormInput
      label={label}
      name={`${name}.${index}`}
      placeholder={placeholder}
      type="number"
      sx={{
        '& .MuiInputBase-root': {
          paddingRight: '4px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CountryAutocomplete
              onChange={(country) => handleCountryChange(country, name, index)}
              selected={selectedCountry}
              index={index}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => remove(index)}>
              <CloseIcon sx={{ width: 16 }} fontSize={'small'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(event) => handleInputChange(event, name, index)}
      value={phoneNumber.replace(selectedCountry.phone, '') ?? ''}
    />
  )
}
