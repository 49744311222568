import React, { FC } from 'react'
import { SalesCall } from '../../types/sales/sales-call'
import { AnalyzeCriteria } from '../../types/sales/analyze-criteria'
import { Box, Typography } from '@mui/material'
import { camelToTitleCase } from '../../utils/camel-to-title-case'

type AnalysisResultsProps = {
  salesCall: SalesCall | null
  analyzeCriteria: AnalyzeCriteria[] | null
}

export const AnalysisResults: FC<AnalysisResultsProps> = ({
  salesCall,
  analyzeCriteria,
}) => {
  const findLabel = (searchKey: string) =>
    analyzeCriteria?.find(({ key }) => key === searchKey)?.label

  if (!salesCall?.analyze) return null

  return (
    <Box overflow="auto" height="300px" flex={1}>
      {Object.entries(salesCall.analyze).map(([key, value]) => (
        <div key={key} style={{ marginBottom: '20px' }}>
          <Typography variant="h5" color="text.primary">
            {findLabel(key) ?? camelToTitleCase(key)}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {value}
          </Typography>
        </div>
      ))}
    </Box>
  )
}
