import { RootState } from '../index'

export const speechToTextOptionSelector = (state: RootState) =>
  state.sales.speechToTextOptions

export const saleCallListSelector = (state: RootState) => state.sales.list

export const salesCallCountSelector = (state: RootState) => state.sales.count

export const getDefaultSalesCallValueSelector = (state: RootState) =>
  state.sales.defaultCallValues

export const saleCallSelector = (state: RootState) => state.sales.salesCall

export const analyzeCriteriaSelector = (state: RootState) =>
  state.sales.analyzeCriteria
