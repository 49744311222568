import React from 'react'
import { PaginationItem, Box } from '@mui/material'
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { BButton } from '../../Button/BButton'
import { BPaginationStyled } from './bPagination.styled'
import { ReactComponent as ArrowNextIcon } from '../../../../assets/svg/arrow-next.svg'
import { ReactComponent as ArrowPreviousIcon } from '../../../../assets/svg/arrow-previous.svg'

export const BPagination = () => {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    apiRef.current.setPage(value - 1)
  }

  const handlePrevClick = () => {
    apiRef.current.setPage(page - 1)
  }

  const handleNextClick = () => {
    apiRef.current.setPage(page + 1)
  }

  return (
    <Box
      style={{
        width: '100%',
        padding: '12px 24px 16px 24px ',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <BButton
        color={'secondary'}
        onClick={handlePrevClick}
        disabled={page === 0}
        startIcon={<ArrowPreviousIcon />}
      >
        Previous
      </BButton>
      <BPaginationStyled
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={handlePageChange}
        siblingCount={0}
        boundaryCount={2}
        hidePrevButton
        hideNextButton
        renderItem={(item) => <PaginationItem {...item} />}
      />
      <BButton
        color={'secondary'}
        onClick={handleNextClick}
        disabled={page >= pageCount - 1}
        endIcon={<ArrowNextIcon />}
      >
        Next
      </BButton>
    </Box>
  )
}
