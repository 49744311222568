import React, { FC } from 'react'
import { FormInput } from './FormInput'
import { Grid, InputAdornment } from '@mui/material'
import { FieldArray, FormikValues } from 'formik'
import { AdditionalEmailInput } from './AdditionalEmailInput'
import { AddAnother } from './AddAnother'
import { BBadge } from '../Badge/bBadge/bBadge'

type MultipleEmailsFieldProps = {
  namePrimary: string
  nameArray: string
  values: FormikValues
}

export const MultipleEmailsField: FC<MultipleEmailsFieldProps> = ({
  nameArray,
  namePrimary,
  values,
}) => {
  return (
    <Grid container gap={1}>
      <FormInput
        label={'Email*'}
        name={namePrimary}
        placeholder={'john.doe@example.com'}
        sx={{
          '& .MuiInputBase-root': {
            paddingRight: '4px',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <BBadge
                sx={{ height: '32px' }}
                badgeType={'primary'}
                label={'Primary'}
              />
            </InputAdornment>
          ),
        }}
      />
      <FieldArray name={nameArray}>
        {({ push, remove }) => (
          <>
            {values[nameArray]?.map((item: string, index: number) => (
              <AdditionalEmailInput
                key={index}
                name={nameArray}
                placeholder={'john.doe@example.com'}
                index={index}
                remove={remove}
              />
            ))}
            <AddAnother onClick={() => push('')} />
          </>
        )}
      </FieldArray>
    </Grid>
  )
}
