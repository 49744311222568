import React, { FC } from 'react'
import { EditCompensationModal } from './table/EditCompesationModal'
import {
  ConfirmPaymentModal,
  ConfirmPaymentValues,
} from './table/ConfirmPaymentModal'
import {
  SalaryReviewModal,
  SalaryReviewValues,
} from './table/SalaryReviewModal'
import { MappedCompensation } from './table/common.types'
import { useAppDispatch } from '../../../hooks/store'
import { updateByReviewDate } from '../../../store/action/compensation'
import { createPayment } from '../../../store/action/payment'
import { parseISO } from 'date-fns'

type CompensationModalsProps = {
  isOpenEditModal: boolean
  isOpenPaymentModal: boolean
  isOpenSalaryReviewModal: boolean
  editEntry: MappedCompensation | undefined
  userId: string
  onCloseEditModal: () => void
  onClosePaymentModal: () => void
  onCloseSalaryReviewModal: () => void
}

export const CompensationModals: FC<CompensationModalsProps> = ({
  isOpenEditModal,
  isOpenPaymentModal,
  isOpenSalaryReviewModal,
  editEntry,
  userId,
  onCloseEditModal,
  onClosePaymentModal,
  onCloseSalaryReviewModal,
}) => {
  const dispatch = useAppDispatch()

  const handleSalaryReviewSubmit = async ({
    salaryAfterReview,
    reviewDate,
  }: SalaryReviewValues) => {
    const userIdNumber = Number(userId)
    await dispatch(
      updateByReviewDate({
        amount: salaryAfterReview,
        user_id: userIdNumber,
        end_date: parseISO(reviewDate ?? ''),
      })
    )
    onCloseSalaryReviewModal()
  }

  const handlePaidSubmit = async ({
    amount,
    periodIdentifier,
    paidDate,
  }: ConfirmPaymentValues & { periodIdentifier: string }): Promise<void> => {
    const parsedPaidDate = parseISO(paidDate ?? '')
    const parsedPeriodDate = new Date(periodIdentifier ?? '')

    await dispatch(
      createPayment({
        user_id: Number(userId),
        period: parsedPeriodDate,
        paid_date: parsedPaidDate,
        amount,
      })
    )

    onClosePaymentModal()
  }

  return (
    <>
      <EditCompensationModal
        onClose={onCloseEditModal}
        isOpen={isOpenEditModal}
        periodIdentifier={editEntry?.periodIdentifier ?? ''}
        baseSalary={editEntry?.baseSalary as number}
        reviewDate={editEntry?.reviewDate as string}
        userId={userId}
      />
      <ConfirmPaymentModal
        isOpen={isOpenPaymentModal}
        onSubmit={handlePaidSubmit}
        onClose={onClosePaymentModal}
        periodIdentifier={editEntry?.periodIdentifier ?? ''}
        salaryDue={editEntry?.salaryDue as number}
        bonusAmount={editEntry?.bonusAmount as number}
      />
      <SalaryReviewModal
        isOpen={isOpenSalaryReviewModal}
        periodIdentifier={editEntry?.periodIdentifier ?? ''}
        baseSalary={editEntry?.baseSalary as number}
        reviewDate={editEntry?.reviewDate as string}
        salaryAfterReview={editEntry?.salaryAfterReview as number}
        onClose={onCloseSalaryReviewModal}
        onSubmit={handleSalaryReviewSubmit}
      />
    </>
  )
}
