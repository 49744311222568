import { useAppSelector } from './store'
import { meSelector } from '../store/selector/auth.selector'
import { UserRole } from '../types/user/user-role'

export const useUserRole = () => {
  const me = useAppSelector(meSelector)
  const isAdminOrSuperAdmin =
    me?.role === UserRole.Admin || me?.role === UserRole.Superadmin
  const isSuperAdmin = me?.role === UserRole.Superadmin
  return { isAdminOrSuperAdmin, isSuperAdmin }
}
