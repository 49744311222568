import { Select, styled } from '@mui/material'

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  height: '44px',
  boxShadow: theme.appShadows?.primary_grey,
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border?.primary,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border?.brand,
    // boxShadow: theme.appShadows?.primary_grey_focus
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border?.brand,
    boxShadow: theme.appShadows?.primary_grey_focus,
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border?.error,
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    paddingRight: '15px !important',
  },

  '.MuiList-root': {
    top: 8,
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.border?.secondary,
  },
}))
