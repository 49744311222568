import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginDto } from '../../types/auth/login.dto'
import { AuthService } from '../../service/auth.service'
import { FlatUser } from '../../types/user/flat-user'

export const loginUser = createAsyncThunk<
  { token: string; refreshToken: string },
  LoginDto
>('auth/loginUser', async (credentials: LoginDto) => {
  return await AuthService.login(credentials)
})

export const getMe = createAsyncThunk<FlatUser>('auth/getMe', async () => {
  return await AuthService.getMe()
})
