import { styled } from '@mui/material/styles'
import { Box, Typography, List, ListItem } from '@mui/material'
import { StyledInteractiveField } from '../StyledInteractiveField'

export const TextAriaLabelContainer = styled(Box)(() => ({
  flex: 1,
  overflow: 'hidden',
}))

export const TextAriaLabelStyledField = styled(StyledInteractiveField)(
  () => ({})
)

export const TextAriaLabelHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  left: 16,
  zIndex: 10,
  width: '100%',
  minHeight: '24px',
}))

export const TextAriaLabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary_600,
  whiteSpace: 'nowrap',
}))

export const TextAriaLabelList = styled(List)(() => ({
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  padding: 0,
}))

export const TextAriaLabelListItem = styled(ListItem)(() => ({
  padding: 0,
}))
