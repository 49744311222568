import React, { FC, ReactNode, useState } from 'react'
import { Backdrop, Button } from '@mui/material'
import { ViewStyled } from './ViewStyled'
import apiClient from '../../../utils/api-client'

type OpenFileButtonProps = {
  link: string
  children?: ReactNode
}
export const OpenFileButton: FC<OpenFileButtonProps> = ({
  link,
  children = 'View',
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    try {
      setIsLoading(true)
      const response = (await apiClient.get(`files?key=${link}`, {
        responseType: 'blob',
      })) as Blob
      const blobUrl = URL.createObjectURL(response)
      window.open(blobUrl, '_blank')

      setTimeout(() => URL.revokeObjectURL(blobUrl), 100)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button disableRipple sx={ViewStyled} onClick={handleClick}>
        {children}
      </Button>
      <Backdrop sx={{ backgroundColor: 'inherit' }} open={isLoading} />
    </>
  )
}
