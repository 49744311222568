import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

type BSvgProps = SvgIconProps & {
  size?: string
  link?: string
}

export const BSvg: FC<BSvgProps> = ({ size = '16px', link, ...props }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    if (link) {
      navigate(link)
    }
  }
  return (
    <SvgIcon
      fontSize="small"
      inheritViewBox
      onClick={handleClick}
      {...props}
      sx={(theme) => ({
        width: size,
        height: size,
        cursor: 'pointer',
        fill: 'transparent',
        '&:hover path': {
          stroke: theme.palette.border?.brand,
        },
      })}
    />
  )
}
