import React, { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { FileWithFlatCreator } from '../../../types/file'
import { OpenFileButton } from '../../ui/Button/OpenFileButton'
import { toTitleCase } from '../../../utils/to-title-case'
import { ReactComponent as FileIcon } from '../../../assets/svg/file.svg'
import { findOption } from '../../../utils/find-option'
import { DOCUMENT_TYPE_OPTIONS } from '../../../constants/document-type.options'

interface FileCellProps {
  row: FileWithFlatCreator
}

export const FileCell: React.FC<FileCellProps> = ({ row }) => {
  const fileOption = useMemo(
    () => findOption(DOCUMENT_TYPE_OPTIONS, row?.name),
    [row?.name]
  )

  const fileName = useMemo(() => {
    return fileOption ? fileOption.label : toTitleCase(row?.name)
  }, [fileOption, row?.name])

  return (
    <OpenFileButton link={row?.upload_key}>
      <Stack
        sx={(theme) => ({
          '&:hover p': {
            color: theme.palette.icon?.utility_brand_500,
          },
        })}
        direction="row"
        spacing={1.5}
        alignItems="center"
      >
        <FileIcon width={24} height={24} />
        <Typography color="text.tertiary_600" fontSize={14} lineHeight="20px">
          {fileName}
        </Typography>
      </Stack>
    </OpenFileButton>
  )
}
