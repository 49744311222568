import React, { ElementType, useState } from 'react'
import { ReactComponent as SettingsIcon } from '../../../assets/svg/settings.svg'
import { useLocation } from 'react-router-dom'
import { NavItem } from '../NavItem/NavItem'
import { NavMenuBox, NavMenuContainer, NavMenuList } from './NavMenu.styled'
import { UserRole } from '../../../types/user/user-role'
import { useAppSelector } from '../../../hooks/store'
import { meSelector } from '../../../store/selector/auth.selector'

export type AppLink = {
  icon: ElementType
  path?: string
  label: string
  children?: AppLink[]
  roles: UserRole[]
}

type NavMenuProps = {
  list: AppLink[]
}

export const NavMenu: React.FC<NavMenuProps> = ({ list }) => {
  const [open, setOpen] = useState<string | null | undefined>(null)
  const me = useAppSelector(meSelector)
  const location = useLocation()

  const handleClick = (path?: string) => {
    setOpen((prevOpen) => (prevOpen === path ? null : path))
  }

  const hasAccess = (
    roles: string[] | undefined,
    userRole?: string
  ): boolean => {
    if (!roles || roles.length === 0 || !userRole) return true
    return roles.includes(userRole)
  }

  const isActive = (path = '_') => location.pathname.startsWith(path)

  return (
    <NavMenuContainer container>
      <NavMenuList component="nav" aria-labelledby="nav-menu-subheader">
        {list
          .filter((item) => hasAccess(item.roles, me?.role))
          .map((item, index) => (
            <NavItem
              key={`nav-menu-item-${index}`}
              item={item}
              isOpen={open === item.path}
              isActive={isActive(item.path)}
              onClick={() => handleClick(item.path)}
            />
          ))}
      </NavMenuList>

      {/*<NavMenuBox>*/}
      {/*  <NavItem*/}
      {/*    item={{ label: 'Settings', icon: SettingsIcon }}*/}
      {/*    isOpen={false}*/}
      {/*    isActive={isActive('-')}*/}
      {/*  />*/}
      {/*</NavMenuBox>*/}
    </NavMenuContainer>
  )
}
