import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { useLocation, useParams } from 'react-router-dom'
import { meSelector } from '../../store/selector/auth.selector'
import { UserRole } from '../../types/user/user-role'
import { EmployeeDetailsPage } from './EmployeeDetails'
import { getById } from '../../store/action/user'
import { userSelector } from '../../store/selector/user.selector'
import { AdminDetailsPage } from './AdminDetails'
import { PersonDetails } from './PersonDetails'
import { useInteractiveModal } from '../../providers/interactive.provider'
import { clearUser } from '../../store/slice/user.slice'

export const UserDetailsPage: FC = () => {
  const { id: userId } = useParams()
  const me = useAppSelector(meSelector)
  const dispatch = useAppDispatch()
  const user = useAppSelector(userSelector)

  const location = useLocation()
  const { setIsExistEditing } = useInteractiveModal()

  useEffect(() => {
    setIsExistEditing(false)
  }, [location.search, setIsExistEditing])

  useEffect(() => {
    const numberId = Number(userId)
    if (userId && !isNaN(numberId)) {
      dispatch(getById(numberId))
    }
    return () => {
      dispatch(clearUser())
    }
  }, [dispatch, userId])

  if (
    me?.role === UserRole.Superadmin &&
    (me.id === userId || user?.role === UserRole.Admin)
  ) {
    return <AdminDetailsPage user={user} />
  }
  if (me?.role === UserRole.Superadmin) {
    return <EmployeeDetailsPage user={user} />
  }

  if (
    me?.role === UserRole.Admin &&
    user?.role !== UserRole.Admin &&
    user?.role !== UserRole.Superadmin
  ) {
    return <EmployeeDetailsPage user={user} />
  }
  if (me?.role === UserRole.Admin && me?.id !== userId) {
    return <AdminDetailsPage user={user} />
  }
  if (me?.role === UserRole.Admin && me?.id === userId) {
    return <AdminDetailsPage user={user} />
  }

  if (user?.role === UserRole.Admin || user?.role === UserRole.Superadmin) {
    return <AdminDetailsPage user={user} />
  }

  if (me?.id !== userId) {
    return <AdminDetailsPage user={user} showProject={true} />
  }
  if (me?.id == userId) {
    return <PersonDetails user={user} />
  }

  return null
}
