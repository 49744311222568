import { Box, Typography } from '@mui/material'
import { BButton } from '../../ui/Button/BButton'
import React, { FC } from 'react'
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg'

type DocumentHeadingProps = {
  toggleModal: () => void
}
export const DocumentHeading: FC<DocumentHeadingProps> = ({ toggleModal }) => {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      mt={2}
      paddingX={4}
      boxSizing={'border-box'}
    >
      <Typography
        color={'text.primary'}
        fontWeight={600}
        fontSize={20}
        lineHeight={'30px'}
      >
        Private & Public Documents
      </Typography>
      <BButton
        size={'small'}
        color="primary"
        startIcon={<PlusIcon />}
        onClick={toggleModal}
      >
        Add document
      </BButton>
    </Box>
  )
}
