import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createUser,
  getAllUser,
  getById,
  getCountByUserType,
  getDropDownData,
  updateUser,
  uploadUserDocument,
} from '../action/user'
import { GetCountUserByTypeDto } from '../../types/user/get-count-user-by-type'
import { toastGenerator } from '../../providers/toast.provider'
import { UserDropDown } from '../../types/user/user-drop-down'
import { FlatUser } from '../../types/user/flat-user'
import { PaginationResponse } from '../../types/common/pagination-response'
import { AppError } from '../../types/common/app-error.type'

interface Slice {
  list: FlatUser[]
  user: FlatUser | null
  isLoading: boolean
  currentCount: number
  upcomingCount: number
  formerCount: number
  activeCount: number
  dropDownList: UserDropDown[]
}

const initialState: Slice = {
  list: [],
  user: null,
  isLoading: false,
  currentCount: 0,
  upcomingCount: 0,
  formerCount: 0,
  activeCount: 0,
  dropDownList: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        getAllUser.fulfilled,
        (state, { payload }: PayloadAction<PaginationResponse<FlatUser>>) => {
          state.list = payload.rows
          state.activeCount = payload.count
          state.isLoading = false
        }
      )
      .addCase(getAllUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getById.fulfilled, (state, action: PayloadAction<FlatUser>) => {
        state.user = action.payload
        state.isLoading = false
      })
      .addCase(getById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false
        toastGenerator.success({ title: 'User created success' })
      })
      .addCase(createUser.rejected, (state) => {
        state.isLoading = false
        toastGenerator.error({ title: 'Error user creating' })
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.isLoading = false
        toastGenerator.success({ title: 'Changes saved' })
      })
      .addCase(
        updateUser.rejected,
        (state, action: PayloadAction<AppError | undefined>) => {
          state.isLoading = false
          if (!action.payload?.preventToasts) {
            toastGenerator.error({ title: 'Error user updating' })
          }
        }
      )
      .addCase(
        getCountByUserType.fulfilled,
        (state, { payload }: PayloadAction<GetCountUserByTypeDto>) => {
          state.currentCount = payload.currentCount
          state.upcomingCount = payload.upcomingCount
          state.formerCount = payload.formerCount
        }
      )
      .addCase(
        getDropDownData.fulfilled,
        (state, { payload }: PayloadAction<UserDropDown[]>) => {
          state.dropDownList = payload
        }
      )
      .addCase(uploadUserDocument.fulfilled, () => {
        toastGenerator.success({ title: 'Upload successful' })
      })
  },
})
export const { clearUser } = userSlice.actions
export default userSlice.reducer
