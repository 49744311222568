import { Dialog, styled } from '@mui/material'

export const BDialogStyled = styled(Dialog)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255,0.1)',
  '& .MuiDialog-paper': {
    width: '600px',
    borderRadius: '12px',
    background: theme.palette.background.default,
    boxShadow: '0px 20px 24px -4px #10182814',
  },
  '& .MuiDialogTitle-root': {},
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    padding: '24px',
    paddingTop: '0',
    gap: '12px',
    '& :last-child': {
      margin: 0,
    },
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}))
