import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { UserProfile } from '../../ui/UserProfile/UserProfile'
import { Typography } from '@mui/material'
import React from 'react'
import { FileWithFlatCreator } from '../../../types/file'
import { format, isValid, parseISO } from 'date-fns'
import { OpenFileButton } from '../../ui/Button/OpenFileButton'
import { LinkWrap } from './LinkWrap'
import { FileCell } from './FileCell'

export const DocumentsTableConfig: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Document Name',
    minWidth: 250,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FileWithFlatCreator>) => {
      return <FileCell row={row} />
    },
  },
  {
    field: 'role',
    headerName: 'Uploaded by',
    minWidth: 300,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FileWithFlatCreator>) => {
      const link = `/employee/${row?.uploadedBy}`
      return (
        <LinkWrap to={link}>
          <UserProfile
            avatar={row?.uploadedByUser?.profilePictureUrl}
            firstName={row?.uploadedByUser?.firstName ?? ''}
            lastName={row?.uploadedByUser?.lastName ?? ''}
            fullNameColor={'text.primary'}
            size={'xs'}
          />
        </LinkWrap>
      )
    },
  },
  {
    field: 'level',
    headerName: 'Type',
    minWidth: 200,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FileWithFlatCreator>) => {
      return (
        <Typography color="text.tertiary_600" fontSize={14} lineHeight="20px">
          {row?.type ?? ''}
        </Typography>
      )
    },
  },
  {
    field: 'project',
    headerName: 'Date',
    minWidth: 200,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FileWithFlatCreator>) => {
      const date = parseISO(row?.created_at ?? '')
      return (
        <Typography color="text.tertiary_600" fontSize={14} lineHeight="20px">
          {isValid(date) ? format(date, 'dd/MM/yyyy') : '-'}
        </Typography>
      )
    },
  },
  {
    field: 'actions',
    headerName: '',
    minWidth: 40,
    sortable: false,
    resizable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 0,
    renderCell: ({ row }: GridRenderCellParams<FileWithFlatCreator>) => {
      return <OpenFileButton link={row?.upload_key} />
    },
  },
]
