export const JOB_TITLE_OPTIONS = [
  { value: 'Front-End Developer' },
  { value: 'Back-End Developer' },
  { value: 'Full-Stack Developer' },
  { value: 'Mobile Developer' },
  { value: 'DevOps Engineer' },
  { value: 'Product Designer' },
  { value: 'Product Manager' },
  { value: 'Lead Generation Manager' },
  { value: 'CTO' },
  { value: 'COO' },
  { value: 'CIO' },
  { value: 'Other' },
]
