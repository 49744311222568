import React from 'react'
import toast, { Toaster, ToastOptions, ToastPosition } from 'react-hot-toast'
import { CustomToast } from '../components/ui/toast/CustomToast'

interface ToastArgs {
  title?: string
  subtitle?: string
}
type ToastType = 'success' | 'error' | 'copy'

const TOAST_DURATION = 1500
const TOAST_POSITION: ToastPosition = 'top-right'

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <Toaster reverseOrder={false} />
    </>
  )
}

export const toastGenerator = {
  success(args: ToastArgs, options?: ToastOptions) {
    this.toast(args, 'success', options)
  },
  error(args: ToastArgs, options?: ToastOptions) {
    this.toast(args, 'error', options)
  },
  copy(args: ToastArgs, options?: ToastOptions) {
    this.toast(args, 'copy', options)
  },
  toast(args: ToastArgs, type: ToastType, options?: ToastOptions) {
    toast.custom(
      (params) => <CustomToast toastParams={params} type={type} {...args} />,
      {
        position: TOAST_POSITION,
        duration: TOAST_DURATION,
        ...options,
      }
    )
  },
}
