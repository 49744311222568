import { SalesCall } from '../../types/sales/sales-call'
import React, { useEffect, useState } from 'react'

export const useCallTranscription = (salesCall: SalesCall | null) => {
  const [transcription, setTranscription] = useState('')

  useEffect(() => {
    if (salesCall?.transcription) {
      setTranscription(salesCall.transcription)
    }
    return () => {
      setTranscription('')
    }
  }, [salesCall])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTranscription(e.target.value)
  }

  return { transcription, handleChange }
}
