import React, { FC, useRef, useState } from 'react'
import { DialogTree } from '../../ui/Dialog/DialogTree/DialogTree'
import { modalSteps } from './modal.config'
import { useAppDispatch } from '../../../hooks/store'
import { createUser } from '../../../store/action/user'
import { FlatUser } from '../../../types/user/flat-user'
import { FormikProps } from 'formik/dist/types'
import { ModalFooter } from './ModalFooter'

interface Props {
  onClose: () => void
  newUser: Partial<FlatUser>
  setNewUser: (newUser: Partial<FlatUser>) => void
}

export const EmployeeModal: FC<Props> = ({ onClose, newUser, setNewUser }) => {
  const formRef = useRef<FormikProps<Partial<FlatUser>>>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const dispatch = useAppDispatch()

  const handleNextStep = async (
    newData: Partial<FlatUser> & { avatar?: File }
  ) => {
    if (activeIndex === 3) {
      await dispatch(createUser({ ...newUser, ...newData }))
      setNewUser({})
      onClose()
      return
    }

    setActiveIndex((prev) => prev + 1)
    setNewUser({ ...newUser, ...newData })
  }
  const triggerSubmit = async () => {
    if (formRef.current) {
      await formRef.current.submitForm()
    }
  }
  const { component: StepComponent } = modalSteps[activeIndex]

  return (
    <>
      <DialogTree
        steps={modalSteps.map((step) => step.title)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <StepComponent
        formRef={formRef}
        onSubmit={handleNextStep}
        initialValues={newUser}
        onClose={onClose}
      />
      <ModalFooter
        onClose={onClose}
        onSubmit={triggerSubmit}
        submitLabel={activeIndex < 3 ? 'Next' : 'Add Employee'}
      />
    </>
  )
}
