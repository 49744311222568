import { styled } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

type LinkWrapProps = {
  to: string
  children: ReactNode
}
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  '&:hover p': {
    color: theme.palette.icon?.utility_brand_500,
  },
}))

export const LinkWrap: FC<LinkWrapProps> = ({ to, children }) => {
  return <StyledLink to={to}>{children}</StyledLink>
}
