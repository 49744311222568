import React, { MouseEventHandler, SyntheticEvent } from 'react'
import Box from '@mui/material/Box'
import { CountryItem } from '../CountryItem'
import { BAutocomplete } from './bAutoComplete/bAutocomplete'
import { AutocompleteCloseReason, ClickAwayListener } from '@mui/material'
import { StyledPopper } from '../Popper/StyledPopper'
import { TelPopper } from './TelPopper/TelPopper'
import {
  PHONE_COUNTRY,
  CountryType,
} from '../../../../constants/phone-countries'

type CountryAutocompleteProps = {
  index?: number
  selected: CountryType
  onChange: (value: CountryType) => void
}

export const CountryAutocomplete: React.FC<CountryAutocompleteProps> = ({
  index,
  selected,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget as HTMLElement)
  }
  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CountryType | null
  ) => {
    if (newValue) {
      onChange(newValue)
    }
    handleClose()
  }
  const open = Boolean(anchorEl)
  const id = open ? 'country-autocomplete' + index : undefined

  return (
    <Box position="relative">
      <CountryItem
        onClick={handleClick}
        sx={{ width: '125px' }}
        status={open ? 'open' : 'closed'}
        countryCode={selected?.code}
        phoneCode={selected?.phone}
      />
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <BAutocomplete
            open
            disableCloseOnSelect
            options={PHONE_COUNTRY}
            renderTags={() => null}
            getOptionLabel={(option) => option.code}
            PopperComponent={(popperProps) => <TelPopper {...popperProps} />}
            renderOption={({ key, ...optionProps }, option) => (
              <Box key={key} component="li" {...optionProps}>
                <CountryItem
                  status={
                    selected.label === option.label ? 'selected' : undefined
                  }
                  countryCode={option.code}
                  phoneCode={option.phone}
                />
              </Box>
            )}
            onClose={(
              event: SyntheticEvent<Element, Event>,
              reason: AutocompleteCloseReason
            ) => {
              if (reason === 'escape') {
                handleClose()
              }
            }}
            onChange={handleChange}
            fullWidth
          />
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  )
}
