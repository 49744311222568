import { Option } from '../types/common/option'

export const DOCUMENT_TYPE_OPTIONS: Option[] = [
  { value: 'cv', label: 'CV' },
  { value: 'offer', label: 'Offer' },
  { value: 'passport', label: 'Passport' },
  { value: 'other', label: 'Other' },
]

export const BASE_DOCUMENT_TYPE = ['cv', 'offer', 'passport']
