import { FC, HTMLInputTypeAttribute, useEffect, useState } from 'react'
import * as React from 'react'
import * as Yup from 'yup'
import { EditActions } from './EditActions'
import { StyledTextField } from '../../../TextFiled/TextField.styled'
import { InputAdornment } from '@mui/material'
import { CountryAutocomplete } from '../../AutoComplete/CountryAutocomplete'
import { PHONE_COUNTRY } from '../../../../../constants/phone-countries'
import { BaseInteractiveFieldProps } from '../../../../../types/common/interactive.types'
import { BInteractive } from '../BInteractiveField'

type TelInputProps = {
  name: string
  type?: HTMLInputTypeAttribute
  onSave: (data: string) => Promise<void>
  onCancel: () => void
  placeholder: string
  initialValue: string
  validationSchema?: Yup.Schema<unknown>
  error: string | null
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  setError: React.Dispatch<React.SetStateAction<string | null>>
  index?: number
}

export const TelInput: FC<TelInputProps> = ({
  placeholder,
  type = 'text',
  onSave,
  onCancel,
  initialValue,
  error,
  value,
  setValue,
  index,
}) => {
  const initialSelectedCounty = PHONE_COUNTRY.find(({ phone }) =>
    phone.startsWith(initialValue.slice(0, 3))
  )
  const [selectedCountry, setSelectedCountry] = useState(
    initialSelectedCounty ?? PHONE_COUNTRY[0]
  )

  useEffect(() => {
    setValue(initialValue ?? `${selectedCountry.phone}${value}`)
  }, [initialValue, selectedCountry.phone, setValue, value])

  const handleCountryChange = (country: (typeof PHONE_COUNTRY)[0]) => {
    setSelectedCountry(country)
    setValue(`${country.phone}${value.replace(selectedCountry?.phone, '')}`)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setValue(`${selectedCountry?.phone}${value}`)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSave(value)
    }
  }

  return (
    <>
      <StyledTextField
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CountryAutocomplete
                index={index}
                onChange={handleCountryChange}
                selected={selectedCountry}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <EditActions onSave={() => onSave(value)} onCancel={onCancel} />
          ),
        }}
        type={type}
        autoComplete={type}
        onKeyDown={handleKeyDown}
        error={Boolean(error)}
        helperText={error}
        value={value.replace(selectedCountry?.phone, '') ?? ''}
        onChange={handleInputChange}
      />
    </>
  )
}

export const InteractiveTelInputField = (props: BaseInteractiveFieldProps) => (
  <BInteractive {...props} type={'number'} InputComponent={TelInput} />
)
