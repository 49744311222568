import { FirstStep } from './FirstStep'
import { SecondStep } from './SecondStep'
import { ThirdStep } from './ThirdStep'
import { FourthStep } from './FourthStep'
import { ModalSteps } from '../../../types/multi-step-modal'
import { FlatUser } from '../../../types/user/flat-user'

export const modalSteps: ModalSteps<FlatUser> = [
  {
    title: 'Personal Details',
    component: FirstStep,
  },
  {
    title: 'Contact Details',
    component: SecondStep,
  },
  {
    title: 'Professional Details',
    component: ThirdStep,
  },
  {
    title: 'Work Conditions',
    component: FourthStep,
  },
]
