import React, { FC } from 'react'
import {
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '../../ui/Table/DynamicTable/Styled'
import { Typography } from '@mui/material'
import { SalaryCeil } from '../../ui/Table/DynamicTable/SalaryCeil'
import { RenderCell } from '../../ui/Table/DynamicTable/RenderCell'
import { MonthCell } from '../../ui/Table/DynamicTable/MonthCell'
import { MappedCompensation, TableRowType } from './table/common.types'

interface CompensationTableContentProps {
  paginatedMonths: MappedCompensation[]
  startDateOfExpectedReview: string | undefined
  renderRows: { rowType: string; cells: TableRowType[] }[]
  handlePaidClick: (periodIdentifier?: string) => void
  handleEditClick: (periodIdentifier?: string) => void
  handleSalaryReviewClick: (periodIdentifier?: string) => void
}

export const CompensationTableContent: FC<CompensationTableContentProps> = ({
  paginatedMonths,
  startDateOfExpectedReview,
  renderRows,
  handlePaidClick,
  handleEditClick,
  handleSalaryReviewClick,
}) => {
  return (
    <>
      <TableHeader>
        <TableRow>
          {paginatedMonths.map(({ periodIdentifier }) => (
            <MonthCell
              periodIdentifier={periodIdentifier}
              key={`header-${periodIdentifier}`}
            />
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          {paginatedMonths.flatMap(({ periodIdentifier }) => [
            <TableCell key={`${periodIdentifier}-actual`}>
              <Typography
                fontSize={'12px'}
                lineHeight={'18px'}
                color={'text.tertiary_600'}
              >
                Actual
              </Typography>
            </TableCell>,
            <TableCell key={`${periodIdentifier}-to-pay`}>
              <Typography
                fontSize={'12px'}
                lineHeight={'18px'}
                color={'text.tertiary_600'}
              >
                To pay
              </Typography>
            </TableCell>,
          ])}
        </TableRow>
        <TableRow>
          {paginatedMonths.map((props) => [
            <SalaryCeil
              key={`salary-${props.periodIdentifier}`}
              {...props}
              previousExpectedReview={
                startDateOfExpectedReview === props.periodIdentifier
              }
            />,
          ])}
        </TableRow>
        {renderRows.map(({ rowType, cells }) => {
          if (cells.length === 1 && cells[0].type === 'empty') {
            return null
          }
          return (
            <TableRow key={rowType}>
              {cells.map((cell, cellIndex) => (
                <RenderCell
                  key={`${rowType}-${cellIndex}-${cell.periodIdentifier}`}
                  type={cell.type}
                  colSpan={cell.colSpan}
                  props={{
                    ...cell.props,
                    onClickPaid: () => handlePaidClick(cell.periodIdentifier),
                    onClickEdit: () => handleEditClick(cell.periodIdentifier),
                    onClickSalary: () =>
                      handleSalaryReviewClick(cell.periodIdentifier),
                  }}
                  periodIdentifier={cell.periodIdentifier}
                />
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}
