import apiClient from '../utils/api-client'
import { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import { GetUserDocuments } from '../types/user/get-user-documents'
import { FileWithCreator, FileWithFlatCreator } from '../types/file'
import { PaginationResponse } from '../types/common/pagination-response'

export class FileService {
  static async upload(
    data: Blob | File | { data: string; name: string },
    uploadKey: string,
    config?: Partial<AxiosRequestConfig>
  ): Promise<string> {
    const formData = new FormData()

    if (data instanceof File) {
      formData.append('file', data)
    } else if (data instanceof Blob) {
      formData.append('file', data, 'user-avatar.png')
    } else if (
      typeof data === 'object' &&
      data !== null &&
      'data' in data &&
      'name' in data
    ) {
      const blob = await (await fetch(data.data)).blob()
      formData.append('file', blob, data.name)
    } else {
      console.error('Invalid data type or structure:', data)
      throw new Error('Invalid data type or structure for file upload')
    }

    try {
      const headers = {
        ...config?.headers,
        'Content-Type': 'multipart/form-data',
      }

      const response = await apiClient.post<{ data: string }>(
        `/files/upload/${uploadKey}`,
        formData,
        {
          ...config,
          headers: headers as AxiosRequestHeaders,
        }
      )
      return response.data
    } catch (error) {
      console.error('File upload failed:', error)
      throw error
    }
  }

  static async delete(key: string): Promise<string> {
    return await apiClient.delete(`/files`, { data: { key } })
  }

  static async findFileByUserAccess({
    userId,
    searchQuery,
    paginationModel,
    sortModel,
  }: GetUserDocuments): Promise<PaginationResponse<FileWithFlatCreator>> {
    const queryParams = {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortField: sortModel[0]?.field,
      sortOrder: sortModel[0]?.sort,
      searchQuery,
    }
    const { data } = await apiClient.get<AxiosResponse>(
      `/users/documents/${userId}`,
      {
        params: { ...queryParams },
      }
    )
    return {
      count: data?.count,
      rows: data?.rows?.map(
        ({ uploaded_by_user, uploaded_by, ...rest }: FileWithCreator) => ({
          ...rest,
          uploadedBy: uploaded_by,
          uploadedByUser: {
            email: uploaded_by_user?.email,
            profilePictureUrl: uploaded_by_user?.profile?.profile_picture_url,
            firstName: uploaded_by_user?.profile?.first_name,
            lastName: uploaded_by_user?.profile?.last_name,
          },
        })
      ),
    }
  }
}
