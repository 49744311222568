import apiClient from '../utils/api-client'
import { LoginDto } from '../types/auth/login.dto'
import { AxiosResponse } from 'axios'
import { mapUserToFlatUser } from '../types/user/user.mapper'

export class AuthService {
  static async login(credentials: LoginDto) {
    const { data } = await apiClient.post<AxiosResponse>(
      '/auth/login',
      credentials,
      {
        preventToasts: true,
      }
    )
    return data
  }

  static async getMe() {
    const { data } = await apiClient.get<AxiosResponse>('/users/me')
    return mapUserToFlatUser(data)
  }
}
