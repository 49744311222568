import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { BButton } from '../../../ui/Button/BButton'
import { ReactComponent as PlusIcon } from '../../../../assets/svg/plus.svg'
import {
  StyledEmptyDocumentContainer,
  StyledDocumentsList,
  StyledDocumentText,
} from './EmptyDocumentRows.styled'

type EmptyDocumentRowsProps = {
  firstName: string
  lastName: string
  onAdd: () => void
}

export const EmptyDocumentRows: FC<EmptyDocumentRowsProps> = ({
  firstName,
  lastName,
  onAdd,
}) => {
  return (
    <StyledEmptyDocumentContainer>
      <StyledDocumentsList />
      <Typography
        fontWeight={600}
        fontSize={16}
        lineHeight={1.5}
        color={'text.primary'}
      >
        Documents will appear here
      </Typography>
      <StyledDocumentText>
        You’ll find uploaded documents from {firstName} {lastName}
        <br />
        here, as well as documents that you add
      </StyledDocumentText>
      <BButton
        size={'small'}
        color="primary"
        startIcon={<PlusIcon />}
        onClick={onAdd}
      >
        Add document
      </BButton>
    </StyledEmptyDocumentContainer>
  )
}
