import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { format, isValid } from 'date-fns'
import { ReactComponent as LeftIcon } from '../../../../../assets/svg/chevron-left.svg'
import { ReactComponent as RightIcon } from '../../../../../assets/svg/chevron-right.svg'
import {
  MonthPaginationButton,
  MonthPaginationContainer,
} from './MonthPagination.styled'

type MonthPaginationProps = {
  startDate: Date
  endDate: Date
  onNextPage: () => void
  onPrevPage: () => void
  page: number
  totalPages: number
}
export const MonthPagination: FC<MonthPaginationProps> = ({
  startDate,
  endDate,
  onPrevPage,
  onNextPage,
  page,
  totalPages,
}) => {
  return (
    <MonthPaginationContainer>
      <MonthPaginationButton
        color={'secondary'}
        onClick={onPrevPage}
        disabled={page === 1}
        endIcon={<LeftIcon />}
      />
      <Typography
        px={1.5}
        fontWeight={600}
        variant={'body2'}
        color={'text.primary'}
      >
        {`${isValid(startDate) ? format(startDate, 'MMMM yyyy') : 'n/a'} – ${isValid(endDate) ? format(endDate, 'MMMM yyyy') : 'n/a'}`}
      </Typography>
      <MonthPaginationButton
        color={'secondary'}
        onClick={onNextPage}
        disabled={page >= totalPages}
        endIcon={<RightIcon />}
      />
    </MonthPaginationContainer>
  )
}
