import { Grid } from '@mui/material'
import React, { FC } from 'react'
import { ReactComponent as EyeIcon } from '../../../../assets/svg/eye_bg.svg'
import { format, isValid, parseISO, set } from 'date-fns'
import { FormInput } from '../../../ui/Form/FormInput'
import { textLeftAdornment } from '../../../ui/Form/FormInput/TextLeftAdornment'
import { textRightAdornment } from '../../../ui/Form/FormInput/TextRightAdornment'
import * as Yup from 'yup'
import { FormDialog } from '../../../ui/Dialog/FormDialog'
import { DayInput } from '../../../ui/Form/DateInput/DayInput'

export type SalaryReviewValues = {
  reviewDate?: string
  salaryAfterReview?: number
  periodIdentifier?: string
}

type SalaryReviewModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (updatedValues: Partial<SalaryReviewValues>) => void
  periodIdentifier: string
  baseSalary: number
  reviewDate: string
  salaryAfterReview: number
}

const validationSchema = Yup.object().shape({
  reviewDay: Yup.number()
    .required('Review day is required')
    .min(1, 'Must be at least 1')
    .max(31, 'Cannot exceed 31'),
  salaryAfterReview: Yup.number()
    .nullable()
    .min(0, 'Salary after review must be greater than 0'),
})

export const SalaryReviewModal: FC<SalaryReviewModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  periodIdentifier,
  baseSalary,
  reviewDate,
  salaryAfterReview,
}) => {
  const editDate = parseISO(periodIdentifier ?? '')
  const formattedEditDate = isValid(editDate)
    ? format(periodIdentifier, 'MMMM yyyy')
    : ''

  const initialValue = {
    reviewDay: reviewDate ? parseInt(format(reviewDate, 'dd')) : undefined,
    salaryAfterReview: salaryAfterReview,
  }
  const handleSubmit = ({
    reviewDay,
    salaryAfterReview,
  }: Omit<SalaryReviewValues, 'reviewDate'> & { reviewDay?: number }) => {
    onSubmit({
      reviewDate: set(editDate, { date: reviewDay })?.toISOString(),
      salaryAfterReview:
        salaryAfterReview === initialValue.salaryAfterReview
          ? undefined
          : salaryAfterReview,
      periodIdentifier,
    })
  }
  return (
    <FormDialog
      title={'Salary review'}
      subTitle={`${formattedEditDate} | Base salary: ${baseSalary}`}
      open={isOpen}
      icon={EyeIcon}
      onClose={onClose}
      pb={'0'}
      initialValue={initialValue}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
    >
      <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
        <DayInput
          endDate={periodIdentifier}
          label={'Review date*'}
          name={'reviewDay'}
        />
        <FormInput
          label={'Salary after review'}
          name={'salaryAfterReview'}
          placeholder={'XXXX'}
          type={'number'}
          InputProps={{
            ...textLeftAdornment('$'),
            ...textRightAdornment('/month'),
          }}
        />
      </Grid>
    </FormDialog>
  )
}
