import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { BaseInteractiveFieldProps } from '../../../../types/common/interactive.types'
import { Action } from './components/InteractiveFieldLabel/InteractiveFieldLabel'

export interface InteractiveFieldProps {
  primary?: string
  additional?: string[]
  primaryActions: Action[]
  additionalActions: Action[]
  onSubmitPrimary: (value: string) => Promise<void>
  onSubmitAdditional: (value: string[]) => Promise<void>
  validationSchema: Yup.Schema<unknown>
  FieldComponent: React.ComponentType<BaseInteractiveFieldProps>
  InputFieldComponent: React.ComponentType<BaseInteractiveFieldProps>
  placeholder: string
}

export const InteractiveMultiField = ({
  primary,
  additional = [],
  validationSchema,
  FieldComponent,
  InputFieldComponent,
  onSubmitPrimary,
  onSubmitAdditional,
  placeholder,
  primaryActions,
  additionalActions,
}: InteractiveFieldProps) => {
  const [primaryValue] = useState(primary)
  const [additionalValues, setAdditionalValues] = useState<string[]>([])

  useEffect(() => {
    setAdditionalValues(additional)
  }, [additional])

  const handleSaveAdditionalValue = async (data: string, index: number) => {
    const updatedValues = additionalValues.map((value, i) =>
      i === index ? data : value
    )
    await onSubmitAdditional(updatedValues)
    setAdditionalValues(updatedValues)
  }

  const handleAddValue = () => {
    setAdditionalValues([...additionalValues, ''])
  }

  const filterValues = (index: number) => {
    return additionalValues.filter((_, i) => i !== index)
  }

  const handleDeleteValue = async (index: number) => {
    const updatedValues = filterValues(index)
    await onSubmitAdditional(updatedValues)
  }

  const handleCancelAddValue = (index: number) => {
    if (additional.length === additionalValues.length) {
      return
    }
    const updatedValues = filterValues(index)
    setAdditionalValues(updatedValues)
  }

  return (
    <>
      <FieldComponent
        name={''}
        placeholder={placeholder}
        onSubmit={(data) => onSubmitPrimary(data as string)}
        initialValue={primaryValue}
        primary
        actions={primaryActions}
        handlers={{ add: handleAddValue }}
        validationSchema={validationSchema}
      />
      {additionalValues?.map((value, index) => (
        <InputFieldComponent
          key={index}
          name={index.toString()}
          placeholder={placeholder}
          initialValue={value}
          actions={additionalActions}
          handlers={{
            delete: () => handleDeleteValue(index),
          }}
          onSubmit={(data) => handleSaveAdditionalValue(data as string, index)}
          validationSchema={validationSchema}
          initialEditing={value === ''}
          onCancel={() => handleCancelAddValue(index)}
        />
      ))}
    </>
  )
}
