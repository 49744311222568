import { InputAdornment, Typography } from '@mui/material'
import React from 'react'

export const textRightAdornment = (
  label: string,
  color = 'text.tertiary_600'
) => {
  return {
    endAdornment: (
      <InputAdornment position="end">
        <Typography color={color}>{label}</Typography>
      </InputAdornment>
    ),
  }
}
