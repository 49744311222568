import { Collapse, List, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { AppLink } from '../NavMenu/NavMenu'
import { Link } from 'react-router-dom'
import { NavItemListItemButton } from './NavMenu.styled'

export const NavItem: React.FC<{
  item: AppLink
  isOpen: boolean
  isActive: boolean
  onClick?: () => void
  level?: number
}> = ({ item, isOpen, isActive, onClick, level = 0 }) => {
  const { icon: Icon, label, children } = item

  return (
    <>
      <Link to={item?.path || '#'} style={{ textDecoration: 'none' }}>
        <NavItemListItemButton
          onClick={onClick}
          className={isActive ? 'active' : ''}
          sx={{ pl: `${level > 0 && level * 1.5}rem` }}
        >
          <ListItemIcon sx={{ minWidth: '24px', mr: 1.5 }}>
            <Icon />
          </ListItemIcon>
          <ListItemText
            sx={{ m: 0 }}
            primaryTypographyProps={{
              lineHeight: '20px',
              fontSize: '14px',
              fontWeight: 500,
            }}
            primary={label}
          />
          {children && (isOpen ? <ExpandLess /> : <ExpandMore />)}
        </NavItemListItemButton>
      </Link>
      {children && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <NavItem
                key={child.path}
                item={child}
                isOpen={false}
                isActive={false}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}
