import React, { FC, ReactNode } from 'react'
import { Box, Typography, SelectChangeEvent, SxProps } from '@mui/material'
import { Field, FieldProps, useField } from 'formik'
import { Option } from '../../../../types/common/option'
import { SelectBase, SelectBaseProps } from './SelectBase'

interface SelectInputProps extends Omit<SelectBaseProps, 'value' | 'onChange'> {
  label?: string
  name: string
  children?: (selected: Option['value']) => ReactNode
  sx?: SxProps
}

export const SelectInput: FC<SelectInputProps> = ({
  label,
  name,
  children,
  sx,
  ...selectProps
}) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value
    await helpers.setValue(value)
  }

  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Typography mb="6px" color="text.secondary" variant="body2">
          {label}
        </Typography>
      )}
      <Box sx={{ width: '100%', display: 'flex', gap: 1 }}>
        <Field name={name}>
          {({ field }: FieldProps) => (
            <SelectBase
              {...selectProps}
              value={field.value ?? ''}
              onChange={handleChange}
              error={Boolean(meta.touched && meta.error)}
              sx={sx}
            />
          )}
        </Field>
        {children && children(field.value)}
      </Box>
      {meta.touched && meta.error && (
        <Typography
          color="border.error"
          fontSize={12}
          marginX={'14px'}
          mt="4px"
        >
          {meta.error}
        </Typography>
      )}
    </Box>
  )
}
