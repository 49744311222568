import React, { FC } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Box } from '@mui/material'
import { BButton } from '../ui/Button/BButton'
import { ReactComponent as WarningIcon } from '../../assets/svg/warning.svg'
import { FormInput } from '../ui/Form/FormInput'
import { LoginDto } from '../../types/auth/login.dto'
import { useAppSelector } from '../../hooks/store'
import { isLoadingAuthSelector } from '../../store/selector/auth.selector'

type LoginFormProps = {
  onSubmit: (payload: LoginDto) => void
  isValidCredential: boolean
  setIsValidCredential: () => void
}
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const LoginForm: FC<LoginFormProps> = ({
  onSubmit,
  setIsValidCredential,
  isValidCredential,
}) => {
  const isLoading = useAppSelector(isLoadingAuthSelector)
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {() => (
        <Form>
          <Box mt={4} mb={2.5}>
            <FormInput
              name={'email'}
              label={'Email'}
              type={'email'}
              placeholder={'Enter your email'}
              helperText={
                isValidCredential ? undefined : 'Wrong email or password'
              }
              endIcon={WarningIcon}
              error={!isValidCredential}
              onFocus={setIsValidCredential}
            />
          </Box>
          <Box mb={3}>
            <FormInput
              name={'password'}
              label={'Password'}
              placeholder={'Enter your password'}
              type={'password'}
              helperText={
                isValidCredential ? undefined : 'Wrong email or password'
              }
              error={!isValidCredential}
              endIcon={WarningIcon}
              onFocus={setIsValidCredential}
            />
          </Box>
          <BButton
            fullWidth={true}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Sign in
          </BButton>
        </Form>
      )}
    </Formik>
  )
}
