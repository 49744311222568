import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import {
  acceptedAudioFileTypes,
  FILE_SIZE_LIMIT,
  NewSalesCallType,
} from './sales-call.modal.config'
import { SelectInput } from '../../ui/Form/Select/SelectInput'

import React, { FC, useEffect, useState } from 'react'
import { MultiStepModalProps } from '../../../types/multi-step-modal'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { speechToTextOptionSelector } from '../../../store/selector/sales-call'
import { getSpeechToTextOptions } from '../../../store/action/sales-call'
import { FileService } from '../../../service/file.service'
import { FileUploadWithLoader } from '../../ui/Form/FileUpload/FileUploadWithLoader/FileUploadWithLoader'

export const AddCallSecondStep: FC<MultiStepModalProps<NewSalesCallType>> = ({
  initialValues,
  formRef,
  onSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    salesCall: Yup.mixed()
      .required('Audio file is required')
      .test('fileFormat', 'Unsupported file format', (value) => {
        return (
          value instanceof File && acceptedAudioFileTypes.includes(value.type)
        )
      })
      .test(
        'fileSize',
        'File size must be less than or equal to 100MB',
        (value) => {
          return value instanceof File && value.size <= FILE_SIZE_LIMIT
        }
      ),
    text_source: Yup.string().required('Source of speech to text is required'),
    count_of_speakers: Yup.string().required('Count of speakers is required'),
  })

  const [fileKey, setFileKey] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [uploadPercentage, setUploadPercentage] = useState(0)

  const handleUpload = async (
    file: File | null,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (!file) return

    if (file.size > FILE_SIZE_LIMIT) {
      setFieldError('salesCall', 'File size exceeds the 100MB limit')
      setFile(null)
      return
    }

    setFile(file)
    const formData = new FormData()
    formData.append('file', file)

    try {
      const key = await FileService.upload(file, `public`, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) /
              (typeof progressEvent.total === 'number'
                ? progressEvent.total
                : 1)
          )
          setUploadPercentage(percentCompleted)
        },
      })
      setFileKey(key)
    } catch (error) {
      setFile(null)
      setUploadPercentage(0)
      console.log(error)
    }
  }
  const dispatch = useAppDispatch()

  const handleSubmit = ({
    salesCall,
    ...payload
  }: Partial<NewSalesCallType>) => {
    onSubmit({ ...payload, audio_link: fileKey })
  }
  const speechToTextOptions = useAppSelector(speechToTextOptionSelector)

  const handleDelete = async () => {
    try {
      await FileService.delete(fileKey)
      setFile(null)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (getSpeechToTextOptions().length > 1) return
    dispatch(getSpeechToTextOptions())
  }, [dispatch])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ setFieldError }) => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <FileUploadWithLoader
              name={'salesCall'}
              file={file}
              uploadPercentage={uploadPercentage}
              onFileSelect={(file) => handleUpload(file, setFieldError)}
              acceptedFileTypes={acceptedAudioFileTypes}
              fileTypeDescription={file ? file.name : 'MP3, FLAC, AAA or WAV'}
              onDelete={handleDelete}
              hideAvatar
            />
            <SelectInput
              label={'Source of speech to text'}
              placeholder={'Select source of speech to text'}
              name={'text_source'}
              options={speechToTextOptions}
            />
            <SelectInput
              label={'Count of speakers'}
              placeholder={'Select count of speakers'}
              name={'count_of_speakers'}
              options={[{ value: '2' }, { value: '3' }]}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
