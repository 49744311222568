import React from 'react'
import { FC } from 'react'
import toast, { Toast } from 'react-hot-toast'
import { Typography, useTheme } from '@mui/material'
import { Close, Error } from '@mui/icons-material'
import { ReactComponent as SuccessIcon } from '../../../assets/svg/toast-sucsees.svg'
import { ReactComponent as CopyIcon } from '../../../assets/svg/toast-copy.svg'
import {
  CustomToastCloseButton,
  CustomToastIconWrapper,
  CustomToastContainer,
} from './CustomToast.styled'

type CustomToastProps = {
  title?: string
  type: 'success' | 'error' | 'copy'
  toastParams: Toast
}

const iconMap: { success: JSX.Element; error: JSX.Element; copy: JSX.Element } =
  {
    success: <SuccessIcon />,
    error: <Error color="error" />,
    copy: <CopyIcon />,
  }

export const CustomToast: FC<CustomToastProps> = ({
  title,
  type,
  toastParams,
}) => {
  const theme = useTheme()

  const handleClose = () => {
    toast.remove(toastParams.id)
  }

  return (
    <CustomToastContainer theme={theme}>
      <CustomToastIconWrapper>{iconMap[type]}</CustomToastIconWrapper>
      <Typography
        fontWeight={600}
        color="text.secondary"
        variant="body2"
        sx={{ flex: 1 }}
      >
        {title}
      </Typography>
      <CustomToastCloseButton onClick={handleClose} size="small">
        <Close />
      </CustomToastCloseButton>
    </CustomToastContainer>
  )
}
