import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { LocalStorage } from './local-storage'
import { jwtDecode } from 'jwt-decode'
import { toastGenerator } from '../providers/toast.provider'

declare module 'axios' {
  export interface InternalAxiosRequestConfig {
    preventToasts?: boolean
  }
}

interface Tokens {
  token: string | null
  refreshToken: string | null
}

class ApiClient {
  private static instance: ApiClient
  private axiosInstance: AxiosInstance

  private constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.axiosInstance.interceptors.request.use(this.handleRequest)

    this.axiosInstance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient()
    }
    return ApiClient.instance
  }

  private isTokenExpired(token: string) {
    try {
      const decodedToken = jwtDecode(token)
      const expirationTime = decodedToken.exp
      if (expirationTime) {
        return Date.now() >= expirationTime * 1000
      }
      return true
    } catch {
      return true
    }
  }

  private handleRequest = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const token = LocalStorage.get('token')
    if (token && !this.isTokenExpired(token)) {
      config.headers['Authorization'] = `Bearer ${token}`
      return config
    }
    if (token && this.isTokenExpired(token) && config.url !== '/auth/refresh') {
      return await this.retryRequest(config)
    }
    return config
  }

  private handleResponse = <T>(response: AxiosResponse<T>) => {
    const { data: axiosReposeData, config } = response

    if (config.url?.includes('auth/login')) {
      const response = axiosReposeData as AxiosResponse<Tokens>
      this.handleTokenRefresh(response?.data)
    }

    return axiosReposeData
  }

  private async retryRequest(
    originalRequest: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> {
    const refreshToken = LocalStorage.get('refreshToken')

    try {
      const { data } = await this.axiosInstance.post<Tokens>('/auth/refresh', {
        token: refreshToken,
      })

      this.handleTokenRefresh(data)
      originalRequest.headers['Authorization'] =
        `Bearer ${data.token as string}`
      return originalRequest
    } catch (refreshError) {
      return Promise.reject(refreshError)
    }
  }

  private handleError = (error: AxiosError<{ message?: string }>) => {
    let preventToasts = false
    if (error.response) {
      if (error.response.status === 401) {
        this.handleRefreshError()
        preventToasts = true
        toastGenerator.error({
          title: 'Your session has expired. Please log in again.',
        })
      }
      if (error.response.status === 403) {
        preventToasts = true
        toastGenerator.error({
          title: 'You do not have permission to view or modify this resource.',
        })
      }
    } else if (error.request) {
      preventToasts = true
      // The request was made but no response was received
      toastGenerator.error({
        title:
          'Unable to reach the server. Please check your internet connection.',
      })
    } else {
      preventToasts = true
      // Something happened in setting up the request that triggered an Error
      toastGenerator.error({ title: 'An unexpected error occurred.' })
    }

    if (!preventToasts && !error.config?.preventToasts) {
      toastGenerator.error({ title: error?.response?.data?.message })
    }

    return Promise.reject({
      message: error?.message,
      code: error?.code,
      status: error?.response?.status,
      data: error?.response?.data,
      preventToasts,
    })
  }

  private handleTokenRefresh(tokens: Tokens): void {
    LocalStorage.set('token', tokens.token)
    LocalStorage.set('refreshToken', tokens.refreshToken)
    this.updateAuthorizationHeader(tokens.token)
  }

  private updateAuthorizationHeader(token: string | null): void {
    if (token) {
      this.axiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${token}`
    } else {
      delete this.axiosInstance.defaults.headers.common['Authorization']
    }
  }

  private handleRefreshError(): void {
    LocalStorage.set('token', null)
    LocalStorage.set('refreshToken', null)
    window.location.href = '/auth/login'
  }

  public async get<T>(
    url: string,
    config?: Partial<InternalAxiosRequestConfig>
  ): Promise<T> {
    return (await this.axiosInstance.get<T>(url, config)) as T
  }

  public async post<T>(
    url: string,
    data?: unknown,
    config?: Partial<InternalAxiosRequestConfig>
  ): Promise<T> {
    return (await this.axiosInstance.post<T>(url, data, config)) as T
  }

  public async put<T>(
    url: string,
    data?: unknown,
    config?: InternalAxiosRequestConfig
  ): Promise<T> {
    return (await this.axiosInstance.put<T>(url, data, config)) as T
  }

  public async delete<T>(
    url: string,
    config?: Partial<InternalAxiosRequestConfig>
  ): Promise<T> {
    return (await this.axiosInstance.delete<T>(url, config)) as T
  }

  public async patch<T>(
    url: string,
    data?: unknown,
    config?: InternalAxiosRequestConfig
  ): Promise<T> {
    return (await this.axiosInstance.patch<T>(url, data, config)) as T
  }
}

export default ApiClient.getInstance()
