import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginResponseDto } from '../../types/auth/login.dto'
import { toastGenerator } from '../../providers/toast.provider'
import { getMe, loginUser } from '../action/auth'
import { FlatUser } from '../../types/user/flat-user'
export interface AuthSlice {
  token: string | null
  refreshToken: string | null
  me: FlatUser | null
  isLoading: boolean
}

const initialState: AuthSlice = {
  token: null,
  refreshToken: null,
  me: null,
  isLoading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null
      state.refreshToken = null
      state.me = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        loginUser.fulfilled,
        (state, action: PayloadAction<LoginResponseDto>) => {
          state.token = action.payload.token
          state.refreshToken = action.payload.refreshToken
          state.isLoading = false
          toastGenerator.success({ title: 'Successes login!' })
        }
      )
      .addCase(loginUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getMe.fulfilled, (state, action: PayloadAction<FlatUser>) => {
        state.me = action.payload
      })
  },
})

export const { logout } = authSlice.actions
export default authSlice.reducer
