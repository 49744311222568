import { Box, Typography } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'
import { TableCell } from './Styled'
import { ReactComponent as PaidIcon } from '../../../../assets/svg/paid.svg'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { BTooltip } from '../../Tooltip/BTooltip'

type SalaryCeilProps = {
  periodIdentifier: string
  baseSalary: number
  salaryDue: number
  bonusAmount: number
  isManualEntry: boolean
  salaryPaymentDate?: string | null
  salaryPaidAmount?: number
  isExpectedReview?: boolean
  previousExpectedReview?: boolean
  reviewDate: string | null
}

export const SalaryCeil = ({
  periodIdentifier,
  baseSalary,
  salaryDue,
  bonusAmount,
  isManualEntry,
  salaryPaidAmount,
  salaryPaymentDate,
  isExpectedReview,
  previousExpectedReview,
  reviewDate,
}: SalaryCeilProps) => {
  let cellContent

  if (salaryPaymentDate) {
    cellContent = (
      <TableCell key={`${periodIdentifier}-topay-value`} paddingY={'20px'}>
        <Box gap={0.5} display={'flex'} alignItems={'center'}>
          <Typography
            color={'text.success_600'}
            fontWeight={500}
            variant={'body2'}
          >
            ${salaryPaidAmount}
          </Typography>
          <PaidIcon />
        </Box>
        <Typography
          fontWeight={12}
          lineHeight={'16px'}
          color={'text.tertiary_600'}
        >
          {format(salaryPaymentDate, 'dd/MM/yyyy')}
        </Typography>
      </TableCell>
    )
  } else if (isExpectedReview) {
    cellContent = (
      <TableCell key={`${periodIdentifier}-topay-value`}>
        <Typography color={'text.primary'} fontWeight={500} variant={'body2'}>
          -
        </Typography>
      </TableCell>
    )
  } else if (reviewDate) {
    cellContent = (
      <TableCell key={`${periodIdentifier}-topay-value`}>
        <Typography
          color={'text.tertiary_600'}
          sx={{ textDecoration: 'line-through' }}
          fontWeight={500}
          variant={'body2'}
        >
          ${baseSalary}
        </Typography>
        <Typography color={'text.primary'} fontWeight={500} variant={'body2'}>
          ${salaryDue + bonusAmount}
        </Typography>
      </TableCell>
    )
  } else if (isManualEntry) {
    cellContent = (
      <TableCell direction={'row'} key={`${periodIdentifier}-topay-value`}>
        <Typography
          color={'text.warning_600'}
          fontWeight={500}
          variant={'body2'}
        >
          ${salaryDue}
        </Typography>
        <BTooltip placement={'top'} title="Actual salary was changed manually">
          <HelpOutlineOutlinedIcon sx={{ width: '16px', height: '16px' }} />
        </BTooltip>
      </TableCell>
    )
  } else if (bonusAmount) {
    cellContent = (
      <TableCell key={`${periodIdentifier}-topay-value`}>
        <Typography color={'text.primary'} fontWeight={500} variant={'body2'}>
          ${salaryDue + bonusAmount}
        </Typography>
      </TableCell>
    )
  } else {
    cellContent = (
      <TableCell key={`${periodIdentifier}-topay-value`}>
        <Typography color={'text.primary'} fontWeight={500} variant={'body2'}>
          {salaryDue !== undefined ? `$${salaryDue}` : 'N/A'}
        </Typography>
      </TableCell>
    )
  }

  return (
    <>
      <TableCell key={`${periodIdentifier}-active-value`}>
        <Typography color={'text.primary'} fontWeight={500} variant={'body2'}>
          {!previousExpectedReview && isExpectedReview
            ? '-'
            : baseSalary !== undefined
              ? '$' + baseSalary
              : 'N/A'}
        </Typography>
      </TableCell>
      {cellContent}
    </>
  )
}
