import { FC } from 'react'
import React from 'react'
import { Step, StepLabel, StepIconProps, Box, Typography } from '@mui/material'
import { ReactComponent as ActiveStep } from '../../../../assets/svg/actice-step.svg'
import { ReactComponent as NextStep } from '../../../../assets/svg/next-step.svg'
import { ReactComponent as CompleteStep } from '../../../../assets/svg/complete-step.svg'
import {
  DialogTreeCompleteStepWrapper,
  DialogTreeConnector,
  DialogTreeIconBox,
  DialogTreeStepper,
} from './DialogTree.styled'

type DialogTreeProps = {
  steps: string[]
  activeIndex: number
  setActiveIndex: (index: number) => void
}

export const DialogTree: FC<DialogTreeProps> = ({
  steps,
  activeIndex,
  setActiveIndex,
}) => {
  function StepIcon(props: StepIconProps, index: number) {
    const { active, completed } = props
    return (
      <DialogTreeIconBox>
        {active ? (
          <ActiveStep />
        ) : completed ? (
          <DialogTreeCompleteStepWrapper onClick={() => setActiveIndex(index)}>
            <CompleteStep />
          </DialogTreeCompleteStepWrapper>
        ) : (
          <NextStep />
        )}
      </DialogTreeIconBox>
    )
  }

  return (
    <DialogTreeStepper
      alternativeLabel
      activeStep={activeIndex}
      connector={<DialogTreeConnector />}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconComponent={(props) => StepIcon(props, index)}>
            <Typography
              fontSize={12}
              lineHeight={1.5}
              fontWeight={500}
              color={'text.tertiary_600'}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </DialogTreeStepper>
  )
}
