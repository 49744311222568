import { Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'

export type BadgeType =
  | 'success'
  | 'warning'
  | 'grey_blue'
  | 'blue'
  | 'project'
  | 'level'
  | 'primary'
export type BadgeRounded = 'full' | 'small' | 'medium' | 'large'

export interface BBadgeProps extends Omit<ChipProps, 'variant'> {
  badgeType: BadgeType
  rounded?: BadgeRounded
}

export const BBadgeStyles = (
  theme: Theme,
  badgeType: BadgeType,
  rounded: BadgeRounded
) => ({
  base: {
    padding: '1px 8px',
    border: '1px solid',
    fontSize: '12px',
    color: theme.palette.text?.secondary,
    lineHeight: '18px',
    height: 'fit-content',
    '& .MuiChip-label': {
      padding: 0,
    },
    '& .MuiChip-icon': {
      margin: 0,
      marginRight: '4px',
    },
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
    ...(rounded === 'full' && { borderRadius: '99999px' }),
    ...(rounded === 'small' && { borderRadius: '4px' }),
    ...(rounded === 'medium' && { borderRadius: '6px' }),
  },
  badgeType: {
    success: {
      backgroundColor: theme.palette.background?.success_50,
      color: theme.palette.text.success_700,
      borderColor: theme.palette.border?.success_200,
    },
    grey_blue: {
      color: theme.palette.text.grey_blue_700,
      borderColor: theme.palette.border?.grey_blue_600,
    },
    warning: {
      backgroundColor: theme.palette.background?.warning,
      borderColor: theme.palette.border?.warning,
      color: theme.palette.text?.warning,
    },
    blue: {
      padding: '0px 6px',
      backgroundColor: theme.palette.background?.blue_50,
      borderColor: theme.palette.border?.blue_200,
      color: theme.palette.text?.blue_600,
    },
    project: {
      borderRadius: '6px',
      boxShadow: '0px 1px 2px 0px #1018280D',
      backgroundColor: theme.palette.background?.default,
      color: theme.palette.button?.primary,
    },
    primary: {
      borderRadius: '6px',
      boxShadow: '0px 1px 2px 0px #1018280D',
      backgroundColor: theme.palette.background?.paper,
      borderColor: theme.palette.border?.primary,
      padding: '5px 12px',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    level: {
      borderRadius: '99999px',
      boxShadow: '0px 1px 2px 0px #1018280D',
      borderColor: theme.palette.border?.primary,
      padding: '4px 8px',
      backgroundColor: theme.palette.background?.paper,
    },
  },
  specialLabel: {
    needsAssessment: {
      backgroundColor: theme.palette.background?.warning,
      borderColor: theme.palette.border?.warning,
      color: theme.palette.text?.warning,
      '& .MuiChip-deleteIcon': {
        display: 'block',
        marginRight: 0,
        marginLeft: '4px',
      },
    },
  },
})

export const StyledBadge = styled(Chip, {
  shouldForwardProp: (prop) =>
    !['badgeType', 'rounded'].includes(prop as string),
})<BBadgeProps>(({ theme, badgeType, rounded = 'full', label }) => ({
  ...BBadgeStyles(theme, badgeType, rounded).base,
  ...BBadgeStyles(theme, badgeType, rounded).badgeType[badgeType],
  ...(label === 'Needs Assessment' &&
    BBadgeStyles(theme, badgeType, rounded).specialLabel.needsAssessment),
}))
