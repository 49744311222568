import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

export const BTableStyled = styled(DataGrid)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.tertiary_600,
  border: '1px solid',
  borderRadius: '12px',
  height: '100%',
  borderTop: '2px solid',
  borderColor: theme.palette.border?.secondary,
  '& .MuiDataGrid-cell': {
    borderBottom: '1px solid',
    borderColor: theme.palette.border?.secondary,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: ' 0 24px',
  },
  '& .MuiDataGrid-main': {
    // borderRadius: '12px',
    // overflow: 'unset',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    overflow: 'hidden',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    position: 'relative',
    zIndex: 30,
  },
  '& .MuiDataGrid-columnHeader': {
    padding: ' 0 24px',
  },
  '& .MuiDataGrid-cellCheckbox': {
    justifyContent: 'center',
  },
  '& .MuiDataGrid-topContainer': {
    // borderTopRightRadius: "12px",
    // borderTopLeftRadius: "12px",
    // border: '1px solid',
    // borderColor: theme.palette.border?.secondary,
    // top: '185px',
    // '::after': {
    //   content: '""',
    //   position: 'absolute',
    //   top: -10,
    //   left: 0,
    //   right: 0,
    //   height: '44px',
    //   backgroundColor: theme.palette.background.default,
    //   zIndex: 10,
    //   width: '101%',
    // },
  },
  '& .MuiDataGrid-footerContainer': {
    borderColor: theme.palette.border?.secondary,
    // backgroundColor: '#f5f5f5',  // Фоновий колір футера
  },
  '& .MuiDataGrid-row': {
    // Стилі для всіх рядів
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.background.paper, // Фоновий колір ряду при наведенні миші
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.border?.primary,
    },
  },

  // '& .MuiDataGrid-virtualScroller': {
  //   overflow: 'visible !important',
  //   marginTop: '0 !important',
  // },

  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-filler': {
    display: 'none',
  },
}))
