import React from 'react'
import { FC } from 'react'
import { Grid, styled, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

type AddAnotherProps = {
  label?: string
  onClick?: () => void
}
const StyledGrid = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  zIndex: 2,
  '&:hover': {
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      transition: 'all 0.3s',
    },
  },
}))

export const AddAnother: FC<AddAnotherProps> = ({
  label = 'Add another',
  onClick,
}) => {
  return (
    <StyledGrid container gap={'6px'} onClick={onClick}>
      <AddIcon fontSize={'small'} sx={{ color: 'button.primary_bg' }} />
      <Typography variant={'body2'} color={'text.tertiary_600'}>
        {label}
      </Typography>
    </StyledGrid>
  )
}
