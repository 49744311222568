import { useState } from 'react'
import { CompensationWithPayment } from '../../types/compensation/compensation-with-payment'
import { MappedCompensation } from '../../components/employee-details/compensation/table/common.types'

export const useCompensationModals = (
  compensationList: CompensationWithPayment[]
) => {
  const [isOpenEditModal, setOpenEditModal] = useState(false)
  const [isOpenPaymentModal, setOpenPaymentModal] = useState(false)
  const [isOpenSalaryReviewModal, setOpenSalaryReviewModal] = useState(false)
  const [editEntry, setEditEntry] = useState<MappedCompensation | undefined>()

  const findEntryByPeriod = (periodIdentifier?: string) =>
    compensationList.find((item) => item.periodIdentifier === periodIdentifier)

  const handleEditClick = (periodIdentifier?: string) => {
    setOpenEditModal(true)
    setEditEntry(findEntryByPeriod(periodIdentifier))
  }

  const handlePaidClick = (periodIdentifier?: string) => {
    setOpenPaymentModal(true)
    setEditEntry(findEntryByPeriod(periodIdentifier))
  }

  const handleSalaryReviewClick = (periodIdentifier?: string) => {
    setOpenSalaryReviewModal(true)
    setEditEntry(findEntryByPeriod(periodIdentifier))
  }

  const closeModals = {
    edit: () => setOpenEditModal(false),
    payment: () => setOpenPaymentModal(false),
    salaryReview: () => setOpenSalaryReviewModal(false),
  }

  return {
    isOpenEditModal,
    isOpenPaymentModal,
    isOpenSalaryReviewModal,
    editEntry,
    handleEditClick,
    handlePaidClick,
    handleSalaryReviewClick,
    closeModals,
  }
}
