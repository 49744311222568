import React, { FC } from 'react'
import { FormInput } from './FormInput'
import { IconButton, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TextFieldProps } from '@mui/material'

type AdditionalEmailInputProps = {
  name: string
  placeholder: string
  index: number
  label?: string
  remove: (index: number) => void
} & TextFieldProps
export const AdditionalEmailInput: FC<AdditionalEmailInputProps> = ({
  name,
  remove,
  index,
  placeholder,
  ...rest
}) => {
  return (
    <FormInput
      fullWidth
      name={`${name}.${index}`}
      placeholder={placeholder}
      {...rest}
      sx={{
        '& .MuiInputBase-root': {
          paddingRight: '4px',
        },
        ...rest?.sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => remove(index)}>
              <CloseIcon sx={{ width: 16 }} fontSize={'small'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
