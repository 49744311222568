import React, { FC } from 'react'
import * as Yup from 'yup'
import { Box, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { SelectInput } from '../../ui/Form/Select/SelectInput'
import { JOB_TITLE_OPTIONS } from '../../../constants/job-title.option'
import { LEVEL_OPTIONS } from '../../../constants/level.options'
import { RECRUIT_SOURCE_OPTIONS } from '../../../constants/recruit-source.options'
import { useGetUserOptions } from '../../../hooks/useGetUserOptions'
import { FlatUser } from '../../../types/user/flat-user'
import { getUserRoleOptions } from '../../../constants/user-role.options'
import { useUserRole } from '../../../hooks/useUserRole'
import { MultiStepModalProps } from '../../../types/multi-step-modal'

const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required('Job title is required'),
  role: Yup.string().required('Role is required'),
  positionLevel: Yup.string(),
  reportToId: Yup.number(),
  hrId: Yup.number(),
  recruiterId: Yup.number(),
  recruitmentSource: Yup.string(),
  projectId: Yup.number().test(
    'project_id-required',
    'Project is required when project role is provided',
    function (value) {
      const { userProjectRole } = this.parent
      return userProjectRole ? !!value : true
    }
  ),
  userProjectRole: Yup.string().test(
    'project_role-required',
    'Project role is required when project is provided',
    function (value) {
      const { projectId } = this.parent
      return projectId ? !!value : true
    }
  ),
})

export const ThirdStep: FC<MultiStepModalProps<FlatUser>> = ({
  onSubmit,
  initialValues,
  formRef,
}) => {
  const { userOptions, projectOptions } = useGetUserOptions()
  const { isSuperAdmin } = useUserRole()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {() => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <SelectInput
              label={'Role*'}
              name={'role'}
              options={getUserRoleOptions(isSuperAdmin)}
              placeholder={'Select role'}
            />
            <SelectInput
              label={'Job title*'}
              name={'jobTitle'}
              options={JOB_TITLE_OPTIONS}
              placeholder={'Select title'}
            />
            <SelectInput
              label={'Level'}
              name={'positionLevel'}
              options={LEVEL_OPTIONS}
              placeholder={'Select level'}
            />
            <Box display={'flex'} gap={2}>
              <SelectInput
                label={'Project'}
                name={'projectId'}
                options={projectOptions}
                placeholder={'Select project'}
              />
              <SelectInput
                label={'Project role'}
                name={'userProjectRole'}
                options={JOB_TITLE_OPTIONS}
                placeholder={'Select role'}
              />
            </Box>
            <SelectInput
              label={'Reporting to'}
              name={'reportToId'}
              options={userOptions}
              placeholder={'Select'}
            />
            <SelectInput
              label={'HR'}
              name={'hrId'}
              options={userOptions}
              placeholder={'Select HR'}
            />
            <Box display={'flex'} gap={2}>
              <SelectInput
                label={'Recruiter'}
                name={'recruiterId'}
                options={userOptions}
                placeholder={'Select recruiter'}
              />
              <SelectInput
                label={'Source'}
                name={'recruitmentSource'}
                options={RECRUIT_SOURCE_OPTIONS}
                placeholder={'Select source'}
              />
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
