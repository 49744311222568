import React, { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { FormDateInput } from '../../ui/Form/DateInput/FormDateInput'
import { FormInput } from '../../ui/Form/FormInput'
import { Cropper } from '../../ui/Form/FileUpload/Cropper/Cropper'
import { Grid } from '@mui/material'
import { FlatUser } from '../../../types/user/flat-user'
import { MultiStepModalProps } from '../../../types/multi-step-modal'

export const FirstStep: FC<MultiStepModalProps<FlatUser>> = ({
  initialValues,
  onSubmit,
  formRef,
}) => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    birthday: Yup.string().matches(
      /^\d{4}-\d{2}-\d{2}$/,
      'Please enter correct date'
    ),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {() => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <Cropper name={'avatar'} />
            <FormInput
              label={'First name*'}
              name={'firstName'}
              placeholder={'e.g. John'}
            />
            <FormInput
              label={'Last name*'}
              name={'lastName'}
              placeholder={'e.g. Doe'}
            />
            <FormDateInput name="birthday" label="Birthday" />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
