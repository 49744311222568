import React, { FC } from 'react'
import {
  SelectChangeEvent,
  Typography,
  MenuItem,
  MenuProps,
  SxProps,
} from '@mui/material'
import { Option } from '../../../../types/common/option'
import { BMenuItem } from '../Menu/bMenuItem'
import { StyledMenuProps } from '../Menu/StyledMenu'
import { StyledSelect } from './StyledSelect'
import { StyledArrowIcon } from './common'
export interface SelectBaseProps {
  options: Option[]
  placeholder?: string
  value: string
  onChange: (event: SelectChangeEvent<unknown>) => void
  error?: boolean
  onOpen?: () => void
  onClose?: () => void
  sx?: SxProps
  MenuProps?: Partial<MenuProps>
  IconComponent?: React.FC<{ className: string }>
}

const renderMenuItem = (option: Option, isSelected: boolean) => (
  <BMenuItem
    img={option.image}
    selected={isSelected}
    label={option.label ?? option.value}
  />
)

export const SelectBase: FC<SelectBaseProps> = ({
  options,
  placeholder,
  value,
  onChange,
  error,
  onOpen,
  onClose,
  sx,
  MenuProps,
  IconComponent,
}) => {
  return (
    <StyledSelect
      MenuProps={{
        ...StyledMenuProps,
        ...MenuProps,
      }}
      placeholder={placeholder}
      error={error}
      onChange={onChange}
      displayEmpty
      onOpen={onOpen}
      onClose={onClose}
      sx={sx}
      value={value}
      IconComponent={({ className }) => {
        if (IconComponent) {
          return <IconComponent className={className as string} />
        }
        return <StyledArrowIcon className={className} />
      }}
      renderValue={(selectedValue) => {
        if (!selectedValue && placeholder) {
          return (
            <Typography color="icon.fg_quinary_400">{placeholder}</Typography>
          )
        }
        const selectedOption = options.find(
          (opt) => opt.value === selectedValue
        )
        return selectedOption ? renderMenuItem(selectedOption, false) : null
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {renderMenuItem(option, value === option.value)}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}
