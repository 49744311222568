import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const RootLayoutContainer = styled(Box)({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

export const SidebarContainer = styled(Box)(({ theme }) => ({
  minWidth: 280,
  width: 280,
  background: theme.palette.background.default,
  maxHeight: '100%',
  border: '1px solid',
  borderColor: theme.palette.border?.secondary,
}))
