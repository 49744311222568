import { useState } from 'react'
import { useFormikContext } from 'formik'
import { PHONE_COUNTRY } from '../constants/phone-countries'

export const useTelInput = (initialCountry = PHONE_COUNTRY[0]) => {
  const [selectedCountry, setSelectedCountry] = useState(initialCountry)
  const [phoneNumber, setPhoneNumber] = useState('')
  const { setFieldValue } = useFormikContext()

  const handleCountryChange = (
    country: (typeof PHONE_COUNTRY)[0],
    name: string,
    index?: number
  ) => {
    setSelectedCountry(country)
    const formattedNumber = `${country.phone}${phoneNumber.replace(selectedCountry.phone, '')}`
    setPhoneNumber(formattedNumber)
    const fieldName = index !== undefined ? `${name}.${index}` : name
    setFieldValue(fieldName, formattedNumber)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string,
    index?: number
  ) => {
    const value = event.target.value
    const formattedNumber = `${selectedCountry.phone}${value}`
    setPhoneNumber(formattedNumber)
    const fieldName = index !== undefined ? `${name}.${index}` : name
    setFieldValue(fieldName, formattedNumber)
  }

  return {
    selectedCountry,
    phoneNumber,
    handleCountryChange,
    handleInputChange,
  }
}
