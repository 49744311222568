import { styled } from '@mui/material/styles'
import { Box, SvgIcon, SvgIconProps } from '@mui/material'

export const ActionCellStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3px 6px',
  borderRadius: '8px',
  transition: 'all .3s ease-in-out',
  '&:hover': {
    outline: '1px solid',
    outlineColor: theme.palette?.border?.primary,
    color: theme.palette.icon?.hover,
    cursor: 'pointer',
    '& path': {
      stroke: theme.palette.icon?.hover,
    },
  },
}))

export const ActionCellStyledSvgIcon = styled(SvgIcon)<SvgIconProps>(
  ({ theme }) => ({
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    fill: 'transparent',
    '& path': {
      stroke: theme.palette.text?.tertiary_600,
    },
  })
)
