import { styled } from '@mui/system'
import { Box, Grid, List, ListProps } from '@mui/material'

export const NavMenuContainer = styled(Grid)({
  height: '100%',
  alignContent: 'space-between',
})

export const NavMenuList = styled(List)<ListProps>(({ theme }) => ({
  width: '100%',
  maxWidth: 360,
  backgroundColor: theme.palette.background.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

export const NavMenuBox = styled(Box)({
  width: '100%',
})
