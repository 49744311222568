import { BDialog } from './bDialog'
import modalBackground from '../../../assets/employee_modal_bg.png'
import { DialogActions, DialogProps } from '@mui/material'
import { BButton } from '../Button/BButton'
import * as React from 'react'
import { FC, ReactNode } from 'react'

type Props = DialogProps & {
  title: string
  subTitle?: string
  inlineTitle?: boolean
  icon?: FC
  onClose?: () => void
  open: boolean
  onSubmit?: () => void
  width?: string
  submitLabel: string
  cancelLabel: string
  children?: ReactNode
  isFullButtons?: boolean
}

export const SimpleDialog: FC<Props> = ({
  onClose,
  onSubmit,
  submitLabel,
  cancelLabel,
  children,
  isFullButtons = true,
  inlineTitle,
  ...rest
}) => {
  return (
    <BDialog
      {...rest}
      onClose={onClose}
      bgImage={modalBackground}
      inlineTitle={inlineTitle}
    >
      {children ? children : null}
      <DialogActions>
        <BButton
          fullWidth={isFullButtons}
          color={'secondary'}
          onClick={onClose}
        >
          {cancelLabel}
        </BButton>
        <BButton fullWidth={isFullButtons} color={'primary'} onClick={onSubmit}>
          {submitLabel}
        </BButton>
      </DialogActions>
    </BDialog>
  )
}
