import { styled } from '@mui/material/styles'
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Typography,
} from '@mui/material'

interface BTitleDialogTitleProps extends DialogTitleProps {
  pb?: string
  inlineTitle?: boolean
}

export const BTitleDialogTitle = styled(DialogTitle, {
  shouldForwardProp: (prop) => prop !== 'pb' && prop !== 'inlineTitle',
})<BTitleDialogTitleProps>(({ theme, pb, inlineTitle }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  paddingBottom: pb,
  display: inlineTitle ? 'flex' : undefined,
}))

export const BTitleIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 16,
  top: 16,
}))

export const BTitleTypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  color: theme.palette.text.primary,
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: 600,
}))

export const BTitleSubtitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'inlineTitle',
})<{ inlineTitle?: boolean }>(({ theme, inlineTitle }) => ({
  position: 'relative',
  zIndex: 1,
  marginTop: inlineTitle ? theme.spacing(0.5) : theme.spacing(1),
  color: theme.palette.text.tertiary_600,
}))
