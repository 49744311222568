import { MappedCompensation } from './common.types'
import {
  addMonths,
  format,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  startOfMonth,
} from 'date-fns'
import { Payment } from '../../../../types/payment/payment'
import { CompensationWithPayment } from '../../../../types/compensation/compensation-with-payment'

export const mapSalary = (
  compensations: CompensationWithPayment[],
  payments: Payment[],
  startDate: Date
) => {
  const salaryByMonth: { [key: string]: MappedCompensation } = {}
  let currentDate = startDate
  const lastPaidDate = [...payments]?.[0]?.period
  const parsedLastPaidDate = lastPaidDate
    ? parseISO(lastPaidDate ?? '')
    : startDate
  const endDate = addMonths(parsedLastPaidDate, 12)

  let [lastKnownSalary, latestReviewStatus] = getInitials(
    compensations,
    startDate
  )

  while (isBefore(currentDate, endDate) || isEqual(currentDate, endDate)) {
    const currentMonthKey = format(startOfMonth(currentDate), 'yyyy-MM-dd')
    const apiEntry = compensations.find(
      (entry) => entry.periodIdentifier === currentMonthKey
    )
    if (apiEntry) {
      salaryByMonth[currentMonthKey] = { ...apiEntry }

      lastKnownSalary = apiEntry.baseSalary
      latestReviewStatus = !!apiEntry.isExpectedReview

      if (apiEntry.reviewDate) {
        const nextMonthKey = format(
          startOfMonth(addMonths(currentDate, 1)),
          'yyyy-MM-dd'
        )
        const nextMonthEntry = compensations.find(
          (entry) => entry.periodIdentifier === nextMonthKey
        )
        salaryByMonth[currentMonthKey].salaryAfterReview =
          nextMonthEntry?.baseSalary
        const lastItemIdentifier =
          compensations[compensations.length - 1]?.periodIdentifier
        if (lastItemIdentifier === currentMonthKey) {
          latestReviewStatus = true
        }
      }
    } else {
      salaryByMonth[currentMonthKey] = {
        reviewDate: null,
        isManualEntry: false,
        periodIdentifier: currentMonthKey,
        baseSalary: lastKnownSalary as number,
        salaryDue: lastKnownSalary as number,
        bonusAmount: 0,
        isExpectedReview: latestReviewStatus,
      }
    }

    currentDate = addMonths(currentDate, 1)
  }

  payments.forEach(({ period, paid_date, amount }) => {
    const monthYearString = format(startOfMonth(period), 'yyyy-MM-dd')

    if (salaryByMonth[monthYearString]) {
      salaryByMonth[monthYearString].salaryPaymentDate = paid_date
      salaryByMonth[monthYearString].salaryPaidAmount = amount
    }
  })

  return Object.values(salaryByMonth)
}

const getInitials = (
  compensations: CompensationWithPayment[],
  startDate: Date
): [number | undefined, boolean | undefined] => {
  const lastStartDateCompensation = [...compensations]
    ?.reverse()
    .find(({ periodIdentifier }) =>
      isAfter(startDate, parseISO(periodIdentifier))
    )
  const { baseSalary, isExpectedReview } = lastStartDateCompensation ?? {}
  return [baseSalary, isExpectedReview]
}
