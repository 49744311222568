import { styled } from '@mui/system'
import { ListItemButton } from '@mui/material'

export const NavItemListItemButton = styled(ListItemButton)(({ theme }) => ({
  textDecoration: 'none',
  borderRadius: '6px',
  padding: '8px 12px',
  fill: 'none',
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',
  '&:hover, &.active': {
    background: theme.palette.border?.secondary,
    '& .MuiListItemIcon-root path': {
      stroke: theme.palette.icon?.hover,
    },
    '& .MuiTypography-root': {
      color: theme.palette.icon?.hover,
    },
  },
}))
