import React, {
  useRef,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useCallback,
} from 'react'
import { useFormikContext } from 'formik'

const getDateParts = (value: string): [string, string, string] =>
  value ? (value.split('-') as [string, string, string]) : ['', '', '']

interface UseDateInputProps {
  name: string
  initialValue?: string
  onChange?: (date: string) => void
  useFormik?: boolean
}

export const useDateInput = ({
  name,
  initialValue = '',
  onChange,
}: UseDateInputProps) => {
  const [internalValue, setInternalValue] = useState<string>(initialValue)
  const [touched, setTouched] = useState<boolean>(false)

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const formik = useFormikContext<Record<string, unknown>>()

  const isUsingFormik = !!formik

  const getValue = useCallback(() => {
    if (isUsingFormik) {
      const value = formik.values[name]
      return typeof value === 'string' ? value : initialValue
    }
    return internalValue
  }, [isUsingFormik, internalValue, formik?.values, name, initialValue])

  const setValue = useCallback(
    (newValue: string) => {
      if (!newValue || newValue === '--') {
        newValue = ''
      }
      if (isUsingFormik) {
        formik.setFieldValue(name, newValue)
      } else {
        setInternalValue(newValue)
        onChange?.(newValue)
      }
      setTouched(true)
    },
    [isUsingFormik, formik, name, onChange]
  )

  const focusNextInput = (currentRef: React.RefObject<HTMLInputElement>) => {
    if (currentRef.current) {
      currentRef.current.focus()
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value: inputValue } = event.target
    const [year, month, day] = getDateParts(getValue())

    let newValue = getValue()
    if (
      inputName.endsWith('year') &&
      year?.length >= 4 &&
      inputValue.length > year.length
    ) {
      return
    }
    if (
      inputName.endsWith('month') &&
      month?.length >= 2 &&
      inputValue.length > month.length
    ) {
      return
    }
    if (
      inputName.endsWith('day') &&
      day?.length >= 2 &&
      inputValue.length > day.length
    ) {
      return
    }

    if (inputName.endsWith('year')) newValue = `${inputValue}-${month}-${day}`
    if (inputName.endsWith('month')) newValue = `${year}-${inputValue}-${day}`
    if (inputName.endsWith('day')) newValue = `${year}-${month}-${inputValue}`

    setValue(newValue)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const name = (event.target as HTMLInputElement).name
    if (event.key === '-' || event.key === '.') {
      event.preventDefault()
    }
    const [year, month, day] = getDateParts(getValue())
    const isBackspace = event.key === 'Backspace' || event.keyCode === 8
    const isTab = event.key === 'Tab'

    // move right
    if (name.endsWith('day') && day?.length >= 2 && !isBackspace) {
      if (isTab) {
        event.preventDefault()
      }
      focusNextInput(monthRef)
    } else if (name.endsWith('month') && month?.length >= 2 && !isBackspace) {
      if (isTab) {
        event.preventDefault()
      }
      focusNextInput(yearRef)
    }

    // move left
    else if (name.endsWith('month') && month?.length === 0 && isBackspace) {
      focusNextInput(dayRef)
    } else if (name.endsWith('year') && year?.length === 0 && isBackspace) {
      focusNextInput(monthRef)
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name: inputName, value: inputValue } = event.target
    const [year, month, day] = getDateParts(getValue())

    if (inputName.endsWith('month') && inputValue.length === 1) {
      const paddedMonth = inputValue.padStart(2, '0')
      setValue(`${year}-${paddedMonth}-${day}`)
    }
    if (inputName.endsWith('day') && inputValue.length === 1) {
      const paddedDay = inputValue.padStart(2, '0')
      setValue(`${year}-${month}-${paddedDay}`)
    }
  }

  return {
    dayRef,
    monthRef,
    yearRef,
    value: getValue(),
    touched,
    handleChange,
    handleKeyDown,
    getDateParts,
    handleBlur,
    meta: isUsingFormik ? formik.getFieldMeta(name) : undefined,
  }
}
