import { Box, Grid, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { ReactComponent as StakedCoins } from '../../../../assets/svg/staked_coins.svg'
import { endOfMonth, format, isValid, parseISO, startOfMonth } from 'date-fns'
import { FieldArray } from 'formik'
import * as Yup from 'yup'
import { AddAnother } from '../../../ui/Form/AddAnother'
import { FormInput } from '../../../ui/Form/FormInput'
import { textLeftAdornment } from '../../../ui/Form/FormInput/TextLeftAdornment'
import { COMPENSATION_TYPE_OPTIONS } from '../../../../constants/compensation-type.options'
import { CompensationType } from '../../../../types/compensation/compensation.enum'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import {
  bulkCompensationUpdate,
  deleteCompensationByIds,
  getCompensationByUserId,
} from '../../../../store/action/compensation'
import { compensationListByUserSelector } from '../../../../store/selector/compenstation.selector'
import { FormDialog } from '../../../ui/Dialog/FormDialog'
import { Compensation } from '../../../../types/compensation/compensation'
import { FormInputWithSelect } from '../../../ui/Form/FormInput/FormInputWithSelect'

type EditCompensationModalProps = {
  isOpen: boolean
  onClose: () => void
  periodIdentifier: string
  baseSalary: number
  reviewDate: string
  userId: string
}

const validationSchema = Yup.object().shape({
  compensations: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('compensation type is required'),
      amount: Yup.number().required('Amount is required'),
      description: Yup.string().nullable(),
    })
  ),
})

export const EditCompensationModal: FC<EditCompensationModalProps> = ({
  isOpen,
  onClose,
  periodIdentifier,
  baseSalary,
  userId,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getCompensationByUserId({
          userId,
          types: `${CompensationType.Bonus},${CompensationType.Fee},${CompensationType.Other}`,
          sortField: 'id',
          sortOrder: 'ASC',
        })
      )
    }
  }, [dispatch, isOpen, userId])

  const compensations = useAppSelector(compensationListByUserSelector)
  const [deletedCompensations, setDeletedCompensations] = useState<number[]>([])
  const [filteredCompensation, setFilteredCompensation] = useState<
    Compensation[]
  >([])

  useEffect(() => {
    const filtered = compensations.filter(({ start_date }) => {
      const competitionPeriod = format(
        startOfMonth(parseISO(start_date ?? '')),
        'yyyy-MM-dd'
      )
      return competitionPeriod === periodIdentifier
    })
    setFilteredCompensation(filtered)
  }, [compensations, periodIdentifier, isOpen])

  const handleDelete = (id?: number) => {
    if (id) {
      setDeletedCompensations((prev) => [...prev, id])
    }
  }

  const handleSubmit = async (values: Record<string, unknown>) => {
    const { compensations } = values as { compensations: Compensation[] }
    const data = compensations
      .filter(({ id }) => !deletedCompensations.includes(id))
      .map(({ start_date, end_date, ...rest }) => ({
        ...rest,
        user_id: Number(userId),
        start_date: endOfMonth(parseISO(start_date ?? periodIdentifier)),
        end_date: end_date ? parseISO(end_date) : undefined,
      }))

    await dispatch(bulkCompensationUpdate(data))
    if (deletedCompensations.length > 0) {
      await dispatch(deleteCompensationByIds(deletedCompensations))
    }
    onClose?.()
    setDeletedCompensations([])
  }

  const editDate = parseISO(periodIdentifier ?? '')
  const formattedEditDate = isValid(editDate)
    ? format(periodIdentifier, 'MMMM yyyy')
    : ''

  return (
    <FormDialog
      title={'Edit payment'}
      subTitle={`${formattedEditDate} | Base salary: ${baseSalary}`}
      open={isOpen}
      icon={StakedCoins}
      onClose={onClose}
      pb={'0'}
      initialValue={{ compensations: filteredCompensation }}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
    >
      <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
        <FieldArray name={'compensations'}>
          {({ push, remove, form }) => (
            <>
              {form.values.compensations.map(
                (compensation: Partial<Compensation>, index: number) => (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    key={`name-${index}`}
                  >
                    <FormInputWithSelect
                      key={`compensation-${index}`}
                      selectName={`compensations[${index}].type`}
                      primaryInputName={`compensations[${index}].amount`}
                      selectOptions={COMPENSATION_TYPE_OPTIONS}
                      onDelete={() => {
                        handleDelete(compensation?.id)
                        remove(index)
                      }}
                      showDelete={true}
                      selectPlaceholder="Select compensation type"
                      primaryInputPlaceholder="Enter amount"
                      primaryInputStartAdornment={
                        <Typography color="text.secondary">$</Typography>
                      }
                      primaryInputType="number"
                    />
                    <FormInput
                      name={`compensations[${index}].description`}
                      placeholder={'Type here...'}
                      type={'text'}
                      InputProps={{
                        ...textLeftAdornment('Description', 'text.secondary'),
                      }}
                    />
                  </Box>
                )
              )}
              <AddAnother
                onClick={() => {
                  push({
                    type: CompensationType.Bonus,
                    description: '',
                    amount: '',
                  })
                }}
                label={'Add'}
              />
            </>
          )}
        </FieldArray>
      </Grid>
    </FormDialog>
  )
}
