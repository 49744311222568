import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from '../../types/common/pagination-response'
import { GetAllSalesCallParams } from '../../types/sales/get-all-sales-call'
import { SalesCallService } from '../../service/sales-call.service'
import { CreateSalesCallDto } from '../../types/sales/create-sales-call.dto'
import { Option } from '../../types/common/option'
import { GetDefaultCallValuesDto } from '../../types/sales/get-default-call-values-dto'
import { AddTranscriptionsDto } from '../../types/sales/add-transcriptions.dto'
import { UpdateSalesDto } from '../../types/sales/update-sales.dto'
import {
  AnalyzeCriteria,
  CategoryEnum,
} from '../../types/sales/analyze-criteria'
import { AnalyzeSalesCallDto } from '../../types/sales/analyze-sales-call.dto'
import { Medical, MedicalWithUser } from '../../types/medical/medical'
import { MedicalService } from '../../service/medical.service'
import { CreateMedicalDto } from '../../types/medical/create-medical.dto'

export const getAllMedical = createAsyncThunk<
  PaginationResponse<Medical>,
  GetAllSalesCallParams
>('medical/getAllMedical', async (params: GetAllSalesCallParams) => {
  return await MedicalService.getAll(params)
})

export const getSpeechToTextOptions = createAsyncThunk<Option[]>(
  'medical/getSpeechToTextOptions',
  async () => {
    return await SalesCallService.getTranscriptionSpeechToText()
  }
)

export const getDefaultSalesCallValues =
  createAsyncThunk<GetDefaultCallValuesDto>(
    'medical/getDefaultSalesCallValues',
    async () => {
      return await SalesCallService.getDefaultSalesCallValues()
    }
  )
export const getMedicalCriteria = createAsyncThunk<AnalyzeCriteria[]>(
  'medical/getMedicalCriteria',
  async () => {
    return await SalesCallService.getSalesAnalyzeCriteria(CategoryEnum.MEDICAL)
  }
)

export const getMedicalById = createAsyncThunk(
  'medical/getMedicalById',
  async (id: number | string) => {
    return await MedicalService.getById(id)
  }
)

export const updateMedical = createAsyncThunk(
  'medical/updateMedical',
  async ({ id, name, data }: UpdateSalesDto) => {
    return await MedicalService.update(id, { [name]: data })
  }
)

export const createMedical = createAsyncThunk<{ id: number }, CreateMedicalDto>(
  'medical/createMedical',
  async (params: CreateMedicalDto) => {
    return await MedicalService.create(params)
  }
)

export const addMedicalTranscription = createAsyncThunk<
  void,
  AddTranscriptionsDto
>('medical/addMedicalTranscription', async (params: AddTranscriptionsDto) => {
  return await MedicalService.addTranscription(params)
})

export const analyzeMedical = createAsyncThunk<void, AnalyzeSalesCallDto>(
  'medical/analyzeMedical',
  async ({ id, criteria }: AnalyzeSalesCallDto) => {
    return await MedicalService.analyze(id, criteria)
  }
)

export const deleteMedical = createAsyncThunk(
  'medical/deleteMedical',
  async (id: number) => {
    return await MedicalService.delete(id)
  }
)
