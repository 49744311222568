import { styled } from '@mui/system'

export const StyledInteractiveField = styled('div')<{
  multiline?: boolean
  disableHover?: boolean
}>(({ theme, multiline, disableHover }) => ({
  height: multiline ? '100%' : '44px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '6px',
  padding: '12px 16px',
  border: '1px solid',
  borderColor: theme.palette.border?.secondary,
  display: 'flex',
  justifyContent: multiline ? 'flex-start' : 'flex-end',
  alignItems: multiline ? 'flex-start' : 'center',
  flexDirection: multiline ? 'column' : 'row',
  maxWidth: '100%',
  width: '100%',
  boxSizing: 'border-box',
  overflow: 'hidden',
  position: 'relative',
  '&:hover': {
    borderColor: disableHover ? undefined : theme.palette.border?.brand,
  },
  transition: 'display 0.3s ease-in-out',
  gap: '8px',
}))
