import { styled } from '@mui/system'
import { Box, IconButton } from '@mui/material'

export const CustomToastContainer = styled(Box)`
  max-width: 320px;
  display: flex;
  align-items: center;
  padding: 6px 8.5px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 1.5px;
  border: 1px solid ${({ theme }) => theme.palette.border?.primary};
  gap: 1px;
`

export const CustomToastIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 36px;
  margin-right: 8px;
`

export const CustomToastCloseButton = styled(IconButton)`
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.background.paper)};
`
