import React, { FC } from 'react'
import { Box } from '@mui/material'
import { EmployeeDetailHeader } from '../../components/employee-details/EmployeeDetailsHeader/EmployeeDetailHeader'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { getById, updateUser } from '../../store/action/user'
import { Wrap } from '../../layout/root/Wrap'
import { useGetUserOptions } from '../../hooks/useGetUserOptions'
import { FlatUser } from '../../types/user/flat-user'
import { meSelector } from '../../store/selector/auth.selector'
import { Action } from '../../components/ui/Form/InteractiveField/components/InteractiveFieldLabel/InteractiveFieldLabel'
import { getUserAccessResource } from '../../utils/get-user-access-resource'
import {
  UserDetailColumn,
  UserDetailContainer,
  UserDetailFieldGroup,
  UserDetailSection,
  UserDetailSectionTitle,
} from '../../components/employee-details/UserDetail/UserDetail.styled'
import { BInteractive } from '../../components/ui/Form/InteractiveField/BInteractiveField'
import { InteractiveDateField } from '../../components/ui/Form/InteractiveField/InteractiveDateField'
import { InteractiveMultiField } from '../../components/ui/Form/InteractiveField/InteractiveMultiField'
import { InteractiveSelectField } from '../../components/ui/Form/InteractiveField/InteractiveSelectField'
import { JOB_TITLE_OPTIONS } from '../../constants/job-title.option'
import * as Yup from 'yup'
import { PersonalDetails } from '../../components/employee-details/PersonalDetails'

const emailSchema = Yup.string()
  .email('Email not valid')
  .required('Email is required')

type PersonDetailsProps = {
  user: FlatUser | null
}
export const PersonDetails: FC<PersonDetailsProps> = ({ user }) => {
  const dispatch = useAppDispatch()
  const { userOptions, projectOptions } = useGetUserOptions()

  const onSubmit = async <T extends keyof FlatUser>(
    data: FlatUser[T] | null,
    name: T
  ) => {
    const id = user?.id
    if (!id) {
      return console.error('User ID is required')
    }
    await dispatch(updateUser({ id, name, data }))
    await dispatch(getById(id))
  }

  const fieldProps = {
    onSubmit,
  }

  const currentUser = useAppSelector(meSelector)

  const getActions = (actions: Action[], isAdminOnly: boolean = false) => {
    return getUserAccessResource(actions, currentUser, isAdminOnly, user)
  }

  return (
    <Wrap bg={'default'}>
      {user && (
        <>
          <EmployeeDetailHeader user={user} />
          <Box
            sx={{
              paddingTop: '8px',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <UserDetailContainer key={user?.id}>
              <UserDetailColumn>
                <PersonalDetails
                  user={user}
                  onSubmit={onSubmit}
                  getActions={getActions}
                />
                <UserDetailSection>
                  <UserDetailSectionTitle variant="body1">
                    Contact Details
                  </UserDetailSectionTitle>
                  <BInteractive
                    name="linkedinUrl"
                    placeholder="LinkedIn"
                    initialValue={user['linkedinUrl']}
                    startAdornment={'https://'}
                    onSubmit={(data) => onSubmit(data, 'linkedinUrl')}
                    actions={getActions(['edit', 'copy'])}
                  />
                  <InteractiveMultiField
                    placeholder="Email"
                    onSubmitPrimary={(data) => onSubmit(data, 'email')}
                    onSubmitAdditional={(data) =>
                      onSubmit(data, 'additionalContactEmails')
                    }
                    primary={user['email']}
                    additional={user['additionalContactEmails']}
                    FieldComponent={BInteractive}
                    InputFieldComponent={BInteractive}
                    validationSchema={emailSchema}
                    additionalActions={getActions(['copy', 'delete', 'edit'])}
                    primaryActions={getActions(['copy', 'edit', 'add'])}
                  />

                  <BInteractive
                    name="telegram"
                    placeholder="Telegram nickname"
                    initialValue={user['telegram']}
                    onSubmit={(data) => onSubmit(data, 'telegram')}
                    actions={getActions(['edit', 'copy'])}
                    startAdornment={'@'}
                  />
                  <BInteractive
                    name="address"
                    placeholder="Address"
                    initialValue={user['address']}
                    onSubmit={(data) => onSubmit(data, 'address')}
                    actions={getActions(['edit', 'copy'])}
                  />
                </UserDetailSection>
              </UserDetailColumn>

              <UserDetailColumn>
                <UserDetailSection>
                  <UserDetailSectionTitle variant="body1">
                    Professional Details
                  </UserDetailSectionTitle>
                  <InteractiveSelectField
                    name="jobTitle"
                    placeholder="Job title"
                    initialValue={user['jobTitle']}
                    onSubmit={(data) => onSubmit(data, 'jobTitle')}
                    options={JOB_TITLE_OPTIONS}
                    actions={getActions(['edit'])}
                  />
                  <UserDetailFieldGroup>
                    <InteractiveSelectField
                      name="projectId"
                      placeholder="Project"
                      actions={getActions(['edit'])}
                      options={projectOptions}
                      initialValue={user['projectId']}
                      onSubmit={(data) => onSubmit(data, 'projectId')}
                    />
                    <InteractiveSelectField
                      name="projectRole"
                      placeholder="Role"
                      {...fieldProps}
                      options={JOB_TITLE_OPTIONS}
                      actions={getActions(['edit'])}
                      initialValue={user['userProjectRole']}
                      onSubmit={(data) => onSubmit(data, 'userProjectRole')}
                    />
                  </UserDetailFieldGroup>
                  <InteractiveSelectField
                    name="reportToId"
                    placeholder="Reporting to"
                    actions={getActions(['edit'])}
                    initialValue={user['reportToId']}
                    onSubmit={(data) => onSubmit(data, 'reportToId')}
                    options={userOptions}
                  />
                  <InteractiveSelectField
                    name="hrId"
                    actions={getActions(['edit'])}
                    placeholder="HR"
                    options={userOptions}
                    initialValue={user['hrId']}
                    onSubmit={(data) => onSubmit(data, 'hrId')}
                  />
                  <InteractiveDateField
                    placeholder={'Start date'}
                    initialValue={user['startWorkDate']}
                    actions={getActions(['edit'], true)}
                    onSubmit={(data) => onSubmit(data, 'startWorkDate')}
                  />
                </UserDetailSection>
              </UserDetailColumn>
            </UserDetailContainer>
          </Box>
        </>
      )}
    </Wrap>
  )
}
