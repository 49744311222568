import React from 'react'
import { format } from 'date-fns'
import { FC } from 'react'
import { TableHeaderCell } from './Styled'
import { Box } from '@mui/material'
import { BBadge } from '../../Badge/bBadge/bBadge'

type MonthCellProps = {
  periodIdentifier: string
}
export const MonthCell: FC<MonthCellProps> = ({ periodIdentifier }) => {
  const currentPeriod = format(new Date(), 'MM/yyyy')
  const propsPeriod = format(new Date(periodIdentifier), 'MM/yyyy')
  return (
    <TableHeaderCell colSpan={2}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'6px'}
      >
        {format(periodIdentifier, 'MMMM')}
        {currentPeriod === propsPeriod && (
          <BBadge badgeType={'blue'} label={'Current'} rounded={'small'} />
        )}
      </Box>
    </TableHeaderCell>
  )
}
