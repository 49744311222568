import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from '../../types/common/pagination-response'
import { SalesCall } from '../../types/sales/sales-call'
import { GetAllSalesCallParams } from '../../types/sales/get-all-sales-call'
import { SalesCallService } from '../../service/sales-call.service'
import { CreateSalesCallDto } from '../../types/sales/create-sales-call.dto'
import { Option } from '../../types/common/option'
import { GetDefaultCallValuesDto } from '../../types/sales/get-default-call-values-dto'
import { AddTranscriptionsDto } from '../../types/sales/add-transcriptions.dto'
import { UpdateSalesDto } from '../../types/sales/update-sales.dto'
import {
  AnalyzeCriteria,
  CategoryEnum,
} from '../../types/sales/analyze-criteria'
import { AnalyzeSalesCallDto } from '../../types/sales/analyze-sales-call.dto'

export const getAllSalesCall = createAsyncThunk<
  PaginationResponse<SalesCall>,
  GetAllSalesCallParams
>('salesCall/getAllSalesCall', async (params: GetAllSalesCallParams) => {
  return await SalesCallService.getAll(params)
})

export const getSpeechToTextOptions = createAsyncThunk<Option[]>(
  'salesCall/getSpeechToTextOptions',
  async () => {
    return await SalesCallService.getTranscriptionSpeechToText()
  }
)

export const getDefaultSalesCallValues =
  createAsyncThunk<GetDefaultCallValuesDto>(
    'salesCall/getDefaultSalesCallValues',
    async () => {
      return await SalesCallService.getDefaultSalesCallValues()
    }
  )
export const getSalesAnalyzeCriteria = createAsyncThunk<
  AnalyzeCriteria[],
  CategoryEnum
>('salesCall/getSalesAnalyzeCriteria', async (category: CategoryEnum) => {
  return await SalesCallService.getSalesAnalyzeCriteria(category)
})

export const getSalesCallById = createAsyncThunk(
  'salesCall/deleteSalesCall',
  async (id: number | string) => {
    return await SalesCallService.getSalesCallById(id)
  }
)

export const updateSalesCall = createAsyncThunk(
  'salesCall/deleteSalesCall',
  async ({ id, name, data }: UpdateSalesDto) => {
    return await SalesCallService.update(id, { [name]: data })
  }
)

export const createSalesCall = createAsyncThunk<
  { id: number },
  CreateSalesCallDto
>('salesCall/createSalesCall', async (params: CreateSalesCallDto) => {
  return await SalesCallService.create(params)
})

export const addTranscription = createAsyncThunk<void, AddTranscriptionsDto>(
  'salesCall/addTranscription',
  async (params: AddTranscriptionsDto) => {
    return await SalesCallService.addTranscription(params)
  }
)

export const analyzeSalesCall = createAsyncThunk<void, AnalyzeSalesCallDto>(
  'salesCall/analyzeSalesCall',
  async ({ id, criteria }: AnalyzeSalesCallDto) => {
    return await SalesCallService.analyze(id, criteria)
  }
)

export const deleteSalesCall = createAsyncThunk(
  'salesCall/deleteSalesCall',
  async (id: number) => {
    return await SalesCallService.delete(id)
  }
)
