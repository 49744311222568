import { createBrowserRouter } from 'react-router-dom'
import React from 'react'
import { RootLayout } from '../layout/root/RootLayout/RootLayout'
import { AuthLayout } from '../layout/auth/AuthLayout'
import { LoginPage } from '../pages/Login'
import { privateRoutes } from './private.routes'

import { ChangePassword } from '../pages/ChangePassword'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: privateRoutes,
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'change-password',
        element: <ChangePassword />,
      },
    ],
  },
])
