import React, { FC, useState } from 'react'
import { SxProps } from '@mui/material'
import {
  InteractiveFieldLabelContainer,
  InteractiveFieldLabelPlaceholder,
  InteractiveFieldLabelText,
} from './InteractiveFieldLabel.styled'
import { LabelContent } from '../LabelContent'
import { BBadge } from '../../../../Badge/bBadge/bBadge'
import { StyledInteractiveField } from '../StyledInteractiveField'

export type Action = 'copy' | 'edit' | 'add' | 'link' | 'delete'

export type ActionHandlers = {
  [key in Action]?: () => void
}

interface InteractiveFieldLabelProps {
  startPreLabel?: string
  placeholder: string
  value?: string
  image?: string
  primary?: boolean
  actions: Action[]
  handlers?: ActionHandlers
  multiline?: boolean
  sx?: SxProps
}

export const InteractiveFieldLabel: FC<InteractiveFieldLabelProps> = ({
  placeholder,
  value,
  image,
  primary,
  actions = [],
  handlers = {},
  sx,
  multiline = false,
  startPreLabel,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const containerRef = React.useRef<HTMLElement>(null)

  return (
    <InteractiveFieldLabelContainer ref={containerRef}>
      <StyledInteractiveField
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={sx}
        multiline={multiline}
        disableHover={actions.length === 0}
      >
        <InteractiveFieldLabelPlaceholder multiline={multiline}>
          <InteractiveFieldLabelText variant="body2" color="text.tertiary_600">
            {placeholder}
          </InteractiveFieldLabelText>
          {primary && <BBadge badgeType="primary" label="Primary" />}
        </InteractiveFieldLabelPlaceholder>

        <LabelContent
          image={image}
          value={value}
          isHovered={isHovered}
          actions={actions}
          handlers={handlers}
          containerRef={containerRef}
          setIsHovered={setIsHovered}
          startPreLabel={startPreLabel}
        />
      </StyledInteractiveField>
    </InteractiveFieldLabelContainer>
  )
}
