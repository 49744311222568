import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from '../../types/common/pagination-response'
import { FileWithFlatCreator } from '../../types/file'
import { GetUserDocuments } from '../../types/user/get-user-documents'
import { FileService } from '../../service/file.service'

export const findFileByUserAccess = createAsyncThunk<
  PaginationResponse<FileWithFlatCreator>,
  GetUserDocuments
>('users/findFileByUserAccess', async (params: GetUserDocuments) => {
  return await FileService.findFileByUserAccess(params)
})
