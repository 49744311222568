import React from 'react'
import { FC, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { HeadingRow } from '../../components/common/HeadingRow/HeadingRow'
import { BTable } from '../../components/ui/Table/bTable/bTable'
import { UserColumns } from '../../components/employee/columns/user.columns'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { getAllUser, getCountByUserType } from '../../store/action/user'
import { Wrap } from '../../layout/root/Wrap'
import { TableSearch } from '../../components/common/TableSearch/TableSearch'
import _ from 'lodash'
import { GridSortModel } from '@mui/x-data-grid'
import {
  userListSelector,
  usersActiveCountSelector,
} from '../../store/selector/user.selector'
import { InnerWrap } from '../../layout/root/InnerWrap'

export const UserEmployee: FC = () => {
  const dispatch = useAppDispatch()

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  })

  const [searchQuery, setSearchQuery] = useState('')
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const list = useAppSelector(userListSelector)

  const activeCount = useAppSelector(usersActiveCountSelector)

  useEffect(() => {
    dispatch(getCountByUserType())
  }, [dispatch])

  useEffect(() => {
    if (searchQuery) {
      _.debounce((paginationModel, searchQuery, sortModel) => {
        dispatch(
          getAllUser({
            paginationModel,
            searchQuery,
            sortModel,
          })
        )
      }, 800)(paginationModel, searchQuery, sortModel)
    } else {
      dispatch(getAllUser({ paginationModel, searchQuery, sortModel }))
    }
  }, [paginationModel, dispatch, searchQuery, sortModel])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  return (
    <Wrap bg={'default'}>
      <InnerWrap>
        <Grid
          container
          sx={{
            paddingBottom: 2.3,
            gap: 2.3,
            flexDirection: 'column',
          }}
        >
          <HeadingRow
            title={'Employee'}
            subTitle={'View detail information about your coworkers.'}
          />
          <TableSearch
            placeholder={'Search by name, email, role, level, project'}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
        <BTable
          columns={UserColumns}
          rows={list}
          rowCount={activeCount}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </InnerWrap>
    </Wrap>
  )
}
