import React from 'react'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { UserProfile } from '../../ui/UserProfile/UserProfile'
import { Typography } from '@mui/material'
import { EmployeeLink } from './EmployeeLink'
import { FlatUser } from '../../../types/user/flat-user'
import { BBadge } from '../../ui/Badge/bBadge/bBadge'
import { LinkWrap } from '../../employee-details/documents/LinkWrap'

export const AdminColumns: GridColDef[] = [
  {
    field: 'profile.last_name',
    headerName: 'Full Name',
    minWidth: 310,
    align: 'left',
    headerAlign: 'left',
    flex: 2,
    renderCell: ({ row }: GridRenderCellParams<FlatUser>) => {
      const link = `/employee/${row?.id}`
      return (
        <LinkWrap to={link}>
          <UserProfile
            avatar={row?.profilePictureUrl}
            firstName={row?.firstName}
            lastName={row?.lastName}
            email={row?.email}
            fullNameColor={'text.primary'}
          />
        </LinkWrap>
      )
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 234,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FlatUser>) => {
      const role = row?.jobTitle ?? ''
      return (
        <Typography fontSize={14} lineHeight="20px">
          {role}
        </Typography>
      )
    },
  },
  {
    field: 'level',
    headerName: 'Level',
    minWidth: 200,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FlatUser>) => {
      return (
        <BBadge
          badgeType={'level'}
          label={row?.positionLevel || 'Needs Assessment'}
        />
      )
    },
  },
  {
    field: 'project',
    headerName: 'Project',
    minWidth: 230,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<FlatUser>) => {
      const project = row.projectName ?? ''
      const link = row?.projectId ? `/projects/${row?.projectId}` : undefined
      return (
        <Typography fontSize={14} lineHeight="20px">
          {project ? project : '-'}
        </Typography>
      )
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 90,
    sortable: false,
    resizable: false,
    flex: 0,
    renderCell: ({ row }: GridRenderCellParams<FlatUser>) => {
      const link = `/employee/${row?.id}`
      return <EmployeeLink to={link} />
    },
  },
]
