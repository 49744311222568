import { ThemeOptions } from '@mui/material/styles'
import { baseTheme } from './base-theme'

export const darkTheme: ThemeOptions = {
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#0C111D',
      paper: '#161B26',
      warning: '#4E1D09',
      utility_brand_50: '#1E0805',
      success_50: '#053321',
      blue_50: '#102A56',
    },
    text: {
      primary: '#F5F5F6',
      secondary: '#CECFD2',
      tertiary_600: '#94969C',
      warning: '#FEC84B',
      utility_brand_700: '#FC9385',
      success_600: '#47CD89',
      warning_600: '#FDB022',
      success_700: '#75E0A7',
      grey_blue_700: '#B3B8DB',
      blue_600: '#84CAFF',
    },
    border: {
      primary: '#333741',
      secondary: '#1F242F',
      brand: '#FB6F5D',
      warning: '#93370D',
      error: '#F97066',
      utility_brand_200: '#681F15',
      success_200: '#085D3A',
      grey_blue_600: '#717BBC',
      blue_200: '#1849A9',
    },
    icon: {
      hover: '#ECECED',
      fg_quinary_400: '#85888E',
      utility_brand_500: '#FA4B34',
      grey_blue_500: '#4E5BA6',
      success_500: '#17B26A',
      secondary_error: '#FECDCA',
    },
    button: {
      primary: '#fff',
      primary_bg: '#FA4B34',
      primary_hover: '#C93C2A',
    },
  },
  appShadows: {
    primary_grey: '0px 1px 2px 0px #1018280D',
    primary_grey_focus: '0px 0px 0px 4px #9E77ED3D',
    secondary_grey_focus: '0px 0px 0px 4px #98A2B324',
  },
}
