import React, { FC, useState } from 'react'
import { BAvatar } from '../../bAvatar'
import { Box, Fade } from '@mui/material'
import { ReactComponent as AvatarIcon } from '../../../../assets/svg/avatar.svg'
import { ReactComponent as TrashIcon } from '../../../../assets/svg/trash.svg'
import { styled } from '@mui/system'

type AvatarPreviewProps = {
  src?: string
  onClick?: () => void
}
const TrashIconContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default + 'CC',
  position: 'absolute',
  top: 0,
  zIndex: 10,
  outline: '4px solid #98A2B324',
  borderRadius: '50%',
  '& svg': {
    position: 'absolute',
    cursor: 'pointer',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '& path': {
    stroke: theme.palette.button.primary_bg,
  },
}))

const AvatarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}))

export const AvatarPreview: FC<AvatarPreviewProps> = ({ src, onClick }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <AvatarContainer
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <BAvatar
        sx={{
          outline: isHovered && src ? '0.75px solid #FFFFFF1F' : undefined,
          borderRadius: '50%',
        }}
        src={src}
        avatarSize={64}
      >
        <AvatarIcon />
      </BAvatar>

      <Fade in={isHovered && !!src}>
        <TrashIconContainer>
          {' '}
          <TrashIcon onClick={onClick} width={20} height={20} />
        </TrashIconContainer>
      </Fade>
    </AvatarContainer>
  )
}
