import React, { FC, useState, ElementType, HTMLInputTypeAttribute } from 'react'
import { Field, FieldProps, getIn } from 'formik'
import { StyledTextField } from '../../TextFiled/TextField.styled'
import { Box, Typography } from '@mui/material'
import FormInputAdornment from './FormInputAdornment'
import { TextFieldProps } from '@mui/material/TextField/TextField'

export type FormInputProps = {
  name: string
  label?: string
  placeholder?: string
  endIcon?: ElementType
  type?: HTMLInputTypeAttribute
} & TextFieldProps

export const FormInput: FC<FormInputProps> = ({
  name,
  label,
  placeholder,
  endIcon,
  type = 'text',
  ...textFieldProps
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const isPassword = type === 'password'

  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Typography mb="6px" color="text.secondary" variant="body2">
          {label}
        </Typography>
      )}
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => {
          const error = getIn(form.errors, name)
          const touched = getIn(form.touched, name)
          return (
            <StyledTextField
              fullWidth
              size="small"
              placeholder={placeholder}
              type={isPassword ? (showPassword ? 'text' : 'password') : type}
              autoComplete={isPassword ? 'current-password' : type}
              InputProps={{
                endAdornment: (
                  <FormInputAdornment
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownPassword={handleMouseDownPassword}
                    isPassword={isPassword}
                    endIcon={endIcon}
                    hasError={meta.touched && !!form.errors[name]}
                    fieldValue={field.value}
                  />
                ),
              }}
              {...field}
              {...textFieldProps}
              error={(touched && Boolean(error)) || textFieldProps?.error}
              helperText={(touched && error) || textFieldProps?.helperText}
            />
          )
        }}
      </Field>
    </Box>
  )
}
