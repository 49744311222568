import { styled } from '@mui/material/styles'
import { Stack, Typography } from '@mui/material'
import DocumentsGridBg from '../../../../assets/documents_grid_bg.png'
import DocumentsListBg from '../../../../assets/document_list.png'

export const StyledEmptyDocumentContainer = styled(Stack)(() => ({
  backgroundImage: `url(${DocumentsGridBg})`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  aspectRatio: '1/1',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledDocumentsList = styled(Stack)(({ theme }) => ({
  backgroundImage: `url(${DocumentsListBg})`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  aspectRatio: '1/1',
  width: '100%',
  height: '22%',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}))

export const StyledDocumentText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.tertiary_600,
  variant: 'body2',
}))
