import { styled } from '@mui/system'
import { Avatar } from '@mui/material'

export const BAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'avatarSize',
})<{ avatarSize: number }>(({ avatarSize, theme }) => ({
  width: avatarSize,
  height: avatarSize,
  fontSize: avatarSize * 0.4,
  lineHeight: 1.5,
  fontWeight: 500,
  color: theme.palette.text?.tertiary_600,
  border: '1px solid #FFFFFF1F',
  backgroundColor: theme.palette.border?.secondary,
}))
