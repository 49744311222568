import { createTheme } from '@mui/material/styles'
import { lightTheme } from './light-theme'
import { darkTheme } from './dark-theme'
import { Theme } from '@mui/material'

export type ThemeMode = 'light' | 'dark'

export const getTheme = (mode: ThemeMode): Theme => {
  const themeOptions = mode === 'dark' ? darkTheme : lightTheme
  return createTheme(themeOptions)
}
