import { RootState } from '../index'

export const compensationMappedListSelector = (state: RootState) =>
  state.compensations.mappedList

export const compensationListByUserSelector = (state: RootState) =>
  state.compensations.listByUser

export const latestMappedCompensationSelector = ({
  compensations,
}: RootState) => {
  return [...compensations.mappedList].reverse()?.[0]
}
