import apiClient from '../utils/api-client'
import { AxiosResponse } from 'axios'
import { PaginationResponse } from '../types/common/pagination-response'
import { GetAllSalesCallParams } from '../types/sales/get-all-sales-call'
import { SalesCall } from '../types/sales/sales-call'
import { CreateSalesCallDto } from '../types/sales/create-sales-call.dto'
import { Option } from '../types/common/option'
import { mapUserWithProfileToFlatUser } from '../types/user/user-with-profile'
import { GetDefaultCallValuesDto } from '../types/sales/get-default-call-values-dto'
import { AddTranscriptionsDto } from '../types/sales/add-transcriptions.dto'
import { AnalyzeCriteria } from '../types/sales/analyze-criteria'
import { Medical, MedicalWithUser } from '../types/medical/medical'
import { CreateMedicalDto } from '../types/medical/create-medical.dto'

export class MedicalService {
  static async getAll({
    searchQuery,
    paginationModel,
    sortModel,
  }: GetAllSalesCallParams) {
    const queryParams = {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortField: sortModel[0]?.field,
      sortOrder: sortModel[0]?.sort,
      searchQuery,
    }

    const { data } = await apiClient.get<
      AxiosResponse<PaginationResponse<MedicalWithUser>>
    >('/medical', {
      params: queryParams,
    })

    return {
      rows: data.rows?.map(({ user, ...rest }) => ({
        user: mapUserWithProfileToFlatUser(user),
        ...rest,
      })),
      count: data?.count,
    }
  }

  static async getDefaultSalesCallValues() {
    const { data } =
      await apiClient.get<AxiosResponse<GetDefaultCallValuesDto>>(
        '/medical/default'
      )

    return data
  }

  static async getSalesAnalyzeCriteria(category: string) {
    const { data } = await apiClient.get<AxiosResponse<AnalyzeCriteria[]>>(
      '/analyze/criteria',
      {
        params: { category },
      }
    )

    return data
  }

  static async getById(id: number | string) {
    const { data } = await apiClient.get<AxiosResponse<Medical>>(
      `/medical/${id}`
    )

    return data
  }

  static async getTranscriptionSpeechToText() {
    const { data } = await apiClient.get<AxiosResponse<{ sources: Option[] }>>(
      '/medical/transcription/speech-to-text'
    )

    return data?.sources
  }

  static async create(payload: CreateMedicalDto) {
    const { data } = await apiClient.post<AxiosResponse<SalesCall>>(
      '/medical',
      payload
    )
    return data
  }

  static async update(id: number | string, data: Partial<SalesCall>) {
    await apiClient.patch<AxiosResponse<SalesCall>>(`/medical/${id}`, data)
  }

  static async analyze(
    id: number | string,
    criteria: Pick<AnalyzeCriteria, 'key' | 'ai_instructions'>[]
  ) {
    await apiClient.patch(`/medical/analyze/${id}`, { criteria })
  }

  static async addTranscription({
    id,
    audioLink,
    speakerNumber,
    speechToTextSource,
  }: AddTranscriptionsDto) {
    await apiClient.patch<AxiosResponse>(`/medical/transcription/${id}`, {
      audioLink,
      speakerNumber: Number(speakerNumber),
      speechToTextSource,
    })
  }

  static async delete(id: number) {
    await apiClient.delete<AxiosResponse>(`/medical/${id}`)
  }
}
