import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../hooks/store'
import { isAuthSelector, meSelector } from '../store/selector/auth.selector'
import React, { FC, ReactElement } from 'react'
import { DashboardPage } from '../pages/Dashboard'
import { ProjectsPage } from '../pages/Projects'
import { NestedPage } from '../pages/Nested'
import { ProjectsDetailsPage } from '../pages/ProjectsDetails'
import { UserDetailsPage } from '../pages/UserDetails/UserDetailsPage'
import { EmployeePage } from '../pages/Employee/EmployeePage'
import { SalesAnalyzerPage } from '../pages/SalesAnalyzer'
import { SalesCallDetailsPage } from '../pages/SalesCallDetails'
import { UserRole } from '../types/user/user-role'
import { MedicalAnalyzerPage } from '../pages/MedicalAnalyzer'
import { MedicalDetailsPage } from '../pages/MedicalDetails'

const PrivateRoute: FC<{ element: ReactElement; roles?: UserRole[] }> = ({
  element,
  roles,
}) => {
  const isAuthenticated = useAppSelector(isAuthSelector)
  const me = useAppSelector(meSelector)
  const meRole = me?.role

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />
  }

  if (meRole && roles && !roles.includes(meRole)) {
    const to = meRole === UserRole.Doctor ? '/medical' : '/'
    return <Navigate to={to} />
  }

  return element
}

export const privateRoutes = [
  {
    path: '/',
    element: <Navigate to="/employee" />,
  },
  {
    path: 'employee',
    element: (
      <PrivateRoute
        element={<EmployeePage />}
        roles={[
          UserRole.Sales,
          UserRole.Admin,
          UserRole.Superadmin,
          UserRole.Manager,
          UserRole.Engineer,
          UserRole.Designer,
        ]}
      />
    ),
  },
  {
    path: 'sales',
    element: (
      <PrivateRoute
        element={<SalesAnalyzerPage />}
        roles={[UserRole.Sales, UserRole.Superadmin, UserRole.Admin]}
      />
    ),
  },
  {
    path: 'sales/:id',
    element: (
      <PrivateRoute
        element={<SalesCallDetailsPage />}
        roles={[UserRole.Sales, UserRole.Superadmin, UserRole.Admin]}
      />
    ),
  },
  {
    path: 'medical',
    element: (
      <PrivateRoute
        element={<MedicalAnalyzerPage />}
        roles={[UserRole.Doctor, UserRole.Superadmin, UserRole.Admin]}
      />
    ),
  },
  {
    path: 'medical/:id',
    element: (
      <PrivateRoute
        element={<MedicalDetailsPage />}
        roles={[UserRole.Doctor, UserRole.Superadmin, UserRole.Admin]}
      />
    ),
  },
  {
    path: 'employee/:id',
    element: <PrivateRoute element={<UserDetailsPage />} />,
  },
  {
    path: 'dashboard',
    element: <PrivateRoute element={<DashboardPage />} />,
  },
  {
    path: 'projects',
    element: <PrivateRoute element={<ProjectsPage />} />,
  },
  {
    path: 'projects/:id',
    element: <PrivateRoute element={<ProjectsDetailsPage />} />,
  },
  {
    path: 'nested',
    element: <PrivateRoute element={<NestedPage />} />,
  },
]
