import { MappedCompensation, ProcessedData, TableRowType } from './common.types'
import { format, startOfMonth } from 'date-fns'
import { Payment } from '../../../../types/payment/payment'
import { mapSalary } from './mapSalary'
import { CompensationWithPayment } from '../../../../types/compensation/compensation-with-payment'

export const mapToRows = (
  compensations: CompensationWithPayment[],
  payments: Payment[],
  startDate: Date,
  probationPeriodEnd?: string
): ProcessedData => {
  const salaryByMonth: MappedCompensation[] = mapSalary(
    compensations,
    payments,
    startDate
  )
  const reviewRows: Record<string, TableRowType> = {}
  const actionsRows: Record<string, TableRowType> = {}
  const bonusRows: Record<string, TableRowType> = {}
  const probationEndRow: Record<string, TableRowType> = {}
  const probationPeriodKey = probationPeriodEnd
    ? format(startOfMonth(probationPeriodEnd), 'yyyy-MM-dd')
    : null
  salaryByMonth.forEach(
    ({
      bonusAmount,
      salaryPaymentDate,
      reviewDate,
      isExpectedReview,
      periodIdentifier,
    }) => {
      if (reviewDate) {
        reviewRows[periodIdentifier] = {
          type: 'review',
          periodIdentifier: periodIdentifier,
          props: {
            reviewDate: reviewDate,
            isExpectedReview: isExpectedReview,
          },
          colSpan: 2,
        }
      }

      if (bonusAmount) {
        bonusRows[periodIdentifier] = {
          type: 'bonus',
          periodIdentifier: periodIdentifier,
          props: {
            bonusAmount: bonusAmount,
            salaryPaymentDate: salaryPaymentDate,
          },
          colSpan: 2,
        }
      }

      actionsRows[periodIdentifier] = {
        periodIdentifier: periodIdentifier,
        colSpan: 2,
        type: 'actions',
        props: {
          hide: !!salaryPaymentDate,
        },
      }

      if (probationPeriodKey === periodIdentifier) {
        probationEndRow[periodIdentifier] = {
          periodIdentifier: periodIdentifier,
          colSpan: 2,
          type: 'probation_end',
          props: {
            probationPeriodEnd,
          },
        }
      }
    }
  )

  return {
    salaryByMonth,
    reviewRows,
    actionsRows,
    bonusRows,
    probationEndRow,
  }
}
