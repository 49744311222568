import React, { createContext, useContext, useState } from 'react'

interface InteractiveContextType {
  isExistEditing: boolean
  setIsExistEditing: (value: React.SetStateAction<boolean>) => void
}

const InteractiveContext = createContext<InteractiveContextType | undefined>(
  undefined
)

export const InteractiveProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isExistEditing, setIsExistEditing] = useState(false)

  return (
    <InteractiveContext.Provider value={{ isExistEditing, setIsExistEditing }}>
      {children}
    </InteractiveContext.Provider>
  )
}

export const useInteractiveModal = () => {
  const context = useContext(InteractiveContext)
  if (context === undefined) {
    throw new Error(
      'useInteractiveModal must be used within a InteractiveProvider'
    )
  }
  return context
}
