import React, { FC } from 'react'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { SelectInput } from '../../ui/Form/Select/SelectInput'
import { FormDateInput } from '../../ui/Form/DateInput/FormDateInput'
import { FormInput } from '../../ui/Form/FormInput'
import { textLeftAdornment } from '../../ui/Form/FormInput/TextLeftAdornment'
import { textRightAdornment } from '../../ui/Form/FormInput/TextRightAdornment'
import { EMPLOYMENT_TYPE_OPTIONS } from '../../../constants/employment-type.options'
import { CompensationType } from '../../../types/compensation/compensation.enum'
import { FlatUser } from '../../../types/user/flat-user'
import { ProbationEndDate } from '../../ui/Form/Select/ProbationEndDate'
import { PROBATION_PERIOD_OPTIONS } from '../../../constants/probation-period.options'
import { getProbationPeriod } from '../../../utils/get-probation-period'
import { MultiStepModalProps } from '../../../types/multi-step-modal'

const validationSchema = Yup.object().shape({
  employmentType: Yup.string(),
  probationEndDate: Yup.date().required('Field is required'),
  startWorkDate: Yup.string()
    .required()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Please enter correct date'),
  compensationEndDate: Yup.string().matches(
    /^\d{4}-\d{2}-\d{2}$/,
    'Please enter correct date'
  ),
  compensationAmount: Yup.number()
    .required('Salary required')
    .min(0, 'Amount must be greater than 0')
    .positive('Amount must be greater than 0')
    .nullable(),
})

export const FourthStep: FC<MultiStepModalProps<FlatUser>> = ({
  onSubmit,
  initialValues,
  formRef,
}) => {
  const handleSubmit = async (values: Partial<FlatUser>) => {
    const probationEndDate = getProbationPeriod(
      Number(values?.probationEndDate),
      values?.startWorkDate
    )
    const updatedValues = {
      ...values,
      compensationStartDate: values.startWorkDate,
      compensationType: CompensationType.Salary,
      compensationEndDate: probationEndDate,
      probationEndDate,
    }
    await onSubmit(updatedValues)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {(form) => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <SelectInput
              label={'Employment type'}
              name={'employmentType'}
              options={EMPLOYMENT_TYPE_OPTIONS}
              placeholder={'Full-time'}
            />
            <FormDateInput name={'startWorkDate'} label={'Start date*'} />
            <FormInput
              label={'Salary*'}
              name={'compensationAmount'}
              placeholder={'XXXX'}
              type={'number'}
              InputProps={{
                ...textLeftAdornment('$'),
                ...textRightAdornment('/month'),
              }}
            />
            <SelectInput
              label={'Probation period*'}
              name={'probationEndDate'}
              options={PROBATION_PERIOD_OPTIONS}
              placeholder={'Select period'}
            >
              {(selected) => (
                <ProbationEndDate
                  selected={selected}
                  startWith={form?.values?.startWorkDate}
                />
              )}
            </SelectInput>
            <FormDateInput
              name={'compensationEndDate'}
              label={'Salary review'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
