import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserService } from '../../service/user.service'
import { GetUserParams } from '../../types/user/get-user-params'
import { GetCountUserByTypeDto } from '../../types/user/get-count-user-by-type'
import { UserDropDown } from '../../types/user/user-drop-down'
import { FlatUser } from '../../types/user/flat-user'
import { mapFlatUserToUser } from '../../types/user/user.mapper'
import { PaginationResponse } from '../../types/common/pagination-response'
import { deepFilter } from '../../utils/deep-filter'
import { UploadUserFile } from '../../types/user/upload-user-file'
import { AppError } from '../../types/common/app-error.type'
import { UpdateUserDto } from '../../types/user/update-user'

export const getAllUser = createAsyncThunk<
  PaginationResponse<FlatUser>,
  GetUserParams
>('users/getAllUser', async (params: GetUserParams) => {
  return await UserService.getAll(params)
})

export const getCountByUserType = createAsyncThunk<GetCountUserByTypeDto>(
  'users/getCountByUserType',
  async () => {
    return await UserService.getCountByType()
  }
)

export const getDropDownData = createAsyncThunk<UserDropDown[]>(
  'users/getDropDownData',
  async () => {
    return await UserService.getDropDown()
  }
)

export const getById = createAsyncThunk(
  'users/getById',
  async (id: number | string) => {
    return await UserService.getById(id)
  }
)

export const createUser = createAsyncThunk(
  'users/createUser',
  async (params: Partial<FlatUser> & { avatar?: File }) => {
    return await UserService.create(params)
  }
)
export const uploadUserDocument = createAsyncThunk(
  'users/uploadUserDocument',
  async ({ data, userId }: { data: UploadUserFile; userId: string }) => {
    return await UserService.uploadFile(data, userId)
  }
)

export const updateUser = createAsyncThunk<
  void,
  UpdateUserDto,
  {
    rejectValue: AppError
  }
>(
  'users/updateUser',
  async ({ id, name, data }: UpdateUserDto, { rejectWithValue }) => {
    if (!id) {
      return
    }
    try {
      const updateData: Partial<FlatUser> = { [name]: data }
      const deepObj = deepFilter(mapFlatUserToUser(updateData))
      await UserService.update(id, deepObj)
    } catch (e) {
      return rejectWithValue(e as AppError)
    }
  }
)
