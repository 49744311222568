import React, { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { BTable } from '../../ui/Table/bTable/bTable'
import { DocumentsTableConfig } from './documents-table.config'
import { GridSortModel } from '@mui/x-data-grid'
import { EmptyDocumentRows } from './EmptyDocumentRows/EmptyDocumentRows'
import { FlatUser } from '../../../types/user/flat-user'
import { AddDocumentModal } from './AddDocumentModal'
import { DocumentHeading } from './DocumentHeading'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import {
  fileCountSelector,
  fileListSelector,
} from '../../../store/selector/file.selector'
import { findFileByUserAccess } from '../../../store/action/file'

type EmployeeDocumentProps = {
  user: FlatUser
}
export const EmployeeDocuments: FC<EmployeeDocumentProps> = ({ user }) => {
  const dispatch = useAppDispatch()
  const list = useAppSelector(fileListSelector)
  const filesCount = useAppSelector(fileCountSelector)

  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [open, setOpen] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  })
  useEffect(() => {
    if (!open) {
      dispatch(
        findFileByUserAccess({
          userId: user.id,
          sortModel,
          paginationModel,
        })
      )
    }
  }, [dispatch, open, paginationModel, sortModel, user.id])

  const toggleModal = () => {
    setOpen(!open)
  }
  return (
    <>
      <DocumentHeading toggleModal={toggleModal} />
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        columnGap={6}
        rowGap={4}
        p={2}
        flex={1}
        overflow={'hidden'}
        px={4}
      >
        <BTable
          rowCount={filesCount}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          columns={DocumentsTableConfig}
          rows={list}
          slots={{
            noRowsOverlay: () => (
              <EmptyDocumentRows
                firstName={user?.firstName}
                lastName={user?.lastName}
                onAdd={toggleModal}
              />
            ),
          }}
        />
        <AddDocumentModal
          userId={user?.id}
          open={open}
          toggleModal={toggleModal}
        />
      </Box>
    </>
  )
}
