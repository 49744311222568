import React from 'react'
import { IconButton, InputAdornment, SxProps, Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FormInput } from './index'
import { SelectInput } from '../Select/SelectInput'
import { Option } from '../../../../types/common/option'

interface SelectWithInputsProps {
  selectName: string
  primaryInputName: string
  selectOptions: Option[]
  onDelete?: () => void
  showDelete?: boolean
  selectPlaceholder?: string
  primaryInputPlaceholder?: string
  primaryInputStartAdornment?: React.ReactNode
  primaryInputType?: string
  sx?: SxProps<Theme>
}

export const FormInputWithSelect: React.FC<SelectWithInputsProps> = ({
  selectName,
  primaryInputName,
  selectOptions,
  onDelete,
  showDelete = true,
  selectPlaceholder = 'Select option',
  primaryInputPlaceholder = 'Enter value',
  primaryInputStartAdornment,
  primaryInputType = 'text',
}) => {
  return (
    <FormInput
      name={primaryInputName}
      placeholder={primaryInputPlaceholder}
      type={primaryInputType}
      sx={{
        '& .MuiInputBase-root': {
          padding: 0,
        },
        '& .MuiFormHelperText-root': {
          m: 0,
          fontSize: '8px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SelectInput
              name={selectName}
              options={selectOptions}
              placeholder={selectPlaceholder}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  sx: {
                    minWidth: 200,
                    width: '200px',
                  },
                },
              }}
              sx={{
                boxShadow: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  boxShadow: 'none !important',
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                  boxShadow: 'none !important',
                },
              }}
            />
            {primaryInputStartAdornment}
          </InputAdornment>
        ),
        endAdornment: showDelete && onDelete && (
          <InputAdornment position="end">
            <IconButton onClick={onDelete}>
              <CloseIcon sx={{ width: 16 }} fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
