import { DialogActions } from '@mui/material'
import { BButton } from '../../ui/Button/BButton'
import React, { FC } from 'react'

interface Props {
  onClose: () => void
  onSubmit: () => void
  disable?: boolean
  cancelLabel?: string
  submitLabel?: string
}

export const ModalFooter: FC<Props> = ({
  onClose,
  disable,
  onSubmit,
  submitLabel = 'Next',
  cancelLabel = 'Cancel',
}) => {
  return (
    <DialogActions>
      <BButton
        disabled={disable}
        color={'secondary'}
        variant="outlined"
        onClick={onClose}
      >
        {cancelLabel}
      </BButton>
      <BButton
        disabled={disable}
        color={'primary'}
        variant="outlined"
        type={'submit'}
        onClick={onSubmit}
      >
        {submitLabel}
      </BButton>
    </DialogActions>
  )
}
