import React, { FC } from 'react'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  TableSearchContainer,
  TableSearchTextField,
} from './TableSearch.styled'

type EmployeeSearchProps = {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
}

export const TableSearch: FC<EmployeeSearchProps> = (props) => {
  return (
    <TableSearchContainer>
      <TableSearchTextField
        size={'small'}
        autoComplete={'off'}
        {...props}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </TableSearchContainer>
  )
}
