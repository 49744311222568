import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { BTabBadge } from '../Badge/bTabBadge/bTabBadge.styled'

interface LabelProps {
  title: string
  badgeNumber?: number
}

export const BTabsLabel: FC<LabelProps> = ({ title, badgeNumber }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography fontWeight={600} variant={'body2'}>
        {title}
      </Typography>
      {typeof badgeNumber !== 'undefined' && (
        <BTabBadge>{badgeNumber}</BTabBadge>
      )}
    </Box>
  )
}
