import { ModalSteps } from '../../../types/multi-step-modal'
import { AddMedicalFirstStep } from './AddMedicalFirstStep'
import { AddMedicalSecondStep } from './AddMedicalSecondStep'

export const acceptedAudioFileTypes = [
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'audio/aac',
  'audio/flac',
]
export const FILE_SIZE_LIMIT = 100 * 1024 * 1024

export const medicalInitialValues = {
  title: '',
  call_date: '',
  doctor_details: '',
  client_details: '',
  additional_information: '',
  audio: null,
  text_source: '',
  count_of_speakers: '',
  audio_link: '',
}
export type NewMedicalType = typeof medicalInitialValues

export const medicalModalSteps: ModalSteps<NewMedicalType> = [
  {
    title: 'Dialog details',
    component: AddMedicalFirstStep,
  },
  {
    title: 'Upload audio',
    component: AddMedicalSecondStep,
  },
]
