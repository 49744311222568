import React from 'react'
import logo from '../../../assets/svg/logo.svg'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { StyledTextField } from '../../../components/ui/TextFiled/TextField.styled'
import { NavMenu } from '../NavMenu/NavMenu'
import { navList } from '../navList'
import { UserProfile } from '../../../components/ui/UserProfile/UserProfile'
import { ReactComponent as LogOut } from '../../../assets/svg/log-out.svg'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { useEffect } from 'react'
import { getMe } from '../../../store/action/auth'
import {
  tokenSelector,
  meSelector,
} from '../../../store/selector/auth.selector'
import {
  SidebarBottomSection,
  SidebarContainer,
  SidebarLogo,
} from './Sidebar.styled'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../../store/slice/auth.slice'

export const Sidebar = () => {
  const dispatch = useAppDispatch()
  const me = useAppSelector(meSelector)
  const token = useAppSelector(tokenSelector)
  const navigate = useNavigate()

  const handleClick = () => {
    dispatch(logout())
    navigate('auth/login')
  }

  useEffect(() => {
    if (token) {
      dispatch(getMe())
    }
  }, [dispatch, token])
  return (
    <SidebarContainer>
      <SidebarLogo src={logo} alt="company logo" />
      {/*<StyledTextField*/}
      {/*  size="small"*/}
      {/*  placeholder="Search"*/}
      {/*  InputProps={{*/}
      {/*    startAdornment: (*/}
      {/*      <InputAdornment position="start">*/}
      {/*        <SearchIcon fontSize="small" />*/}
      {/*      </InputAdornment>*/}
      {/*    ),*/}
      {/*  }}*/}
      {/*/>*/}
      <NavMenu list={navList} />
      <SidebarBottomSection>
        {me && (
          <UserProfile
            fullNameColor="text.secondary"
            avatar={me?.profilePictureUrl}
            firstName={me?.firstName}
            lastName={me?.lastName}
            email={me?.email}
            icon={LogOut}
            endIconClick={handleClick}
          />
        )}
      </SidebarBottomSection>
    </SidebarContainer>
  )
}
