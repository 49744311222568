import React, { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg'
import { useAppDispatch } from '../hooks/store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ChangePasswordForm } from '../components/change-password/ChangePasswordForm'
import { updateUser } from '../store/action/user'

export const ChangePassword: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const userId = searchParams.get('userId')
  const navigate = useNavigate()

  const handleSubmit = async ({ password }: { password: string }) => {
    if (!userId) {
      return console.log('userId Not Found')
    }
    await dispatch(updateUser({ name: 'password', data: password, id: userId }))
    navigate('/employee')
  }

  return (
    <Box
      sx={{
        pt: '120px',
        height: 500,
        margin: '0 auto',
        width: '100%',
        maxWidth: '360px',
        [theme.breakpoints.down('sm')]: {
          pt: '60px',
        },
      }}
    >
      <LogoIcon />
      <Box>
        <Typography
          mt={10}
          mb={1.5}
          color="text.primary"
          fontWeight={600}
          variant={'h4'}
          sx={{
            [theme.breakpoints.down('sm')]: {
              mt: '60px',
            },
          }}
        >
          Welcome!
        </Typography>
        <Typography color="text.tertiary_600" variant={'subtitle1'}>
          Please create a new strong password to proceed.
        </Typography>
        <ChangePasswordForm onSubmit={handleSubmit} />
      </Box>
    </Box>
  )
}
