import React, { FC } from 'react'
import { Box, CircularProgress } from '@mui/material'

interface BLoaderProps {
  size?: number
  thickness?: number
  bgColor?: string
  progressColor?: string
}

export const BLoader: FC<BLoaderProps> = ({
  size = 28,
  thickness = 4,
  bgColor = '#1F242F',
  progressColor = '#FA4B34',
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: bgColor,
          position: 'absolute',
        }}
        size={size}
        thickness={thickness}
      />
      <CircularProgress
        sx={{
          color: progressColor,
        }}
        size={size}
        thickness={thickness}
      />
    </Box>
  )
}
