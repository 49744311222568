import React, { FC } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import {
  HeaderContainer,
  BackgroundImage,
  StyledAvatar,
  DetailsContainer,
} from './EmployeeDetailHeader.styled'
import { ReactComponent as DividerIcon } from '../../../assets/svg/divider.svg'
import { FlatUser } from '../../../types/user/flat-user'

type EmployeeDetailHeaderProps = {
  user: FlatUser
}

export const EmployeeDetailHeader: FC<EmployeeDetailHeaderProps> = ({
  user,
}) => {
  const latestProjectName = user?.projectName
  const latestProjectRole = user?.userProjectRole

  return (
    <HeaderContainer>
      <BackgroundImage />
      <DetailsContainer>
        <Grid container alignItems={'center'} flexWrap={'nowrap'}>
          <StyledAvatar src={user?.profilePictureUrl}>
            {user?.firstName?.[0]}
            {user?.lastName?.[0]}
          </StyledAvatar>
          <Box alignSelf={'flex-end'} ml={3} mr={'auto'}>
            <Typography fontWeight={600} color="text.secondary" variant={'h4'}>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <Typography color="text.tertiary_600" variant={'body1'}>
                {user?.jobTitle}
              </Typography>
              {latestProjectRole && (
                <>
                  <DividerIcon />
                  <Typography color="text.tertiary_600" variant={'body1'}>
                    {latestProjectRole}
                  </Typography>
                </>
              )}
              {latestProjectName && (
                <>
                  <Typography color="text.tertiary_600" variant={'body1'}>
                    -
                  </Typography>
                  <Typography color="button.primary_bg" variant={'body1'}>
                    {latestProjectName}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </DetailsContainer>
    </HeaderContainer>
  )
}
