import React, { FC } from 'react'
import { BHeading } from '../../ui/BHeading'
import {
  HeadingRowButtonContainer,
  HeadingRowContainer,
} from './HeadingRow.styled'

type UserControlsProps = {
  title: string
  subTitle: string
  children?: React.ReactNode
}

export const HeadingRow: FC<UserControlsProps> = ({
  subTitle,
  title,
  children,
}) => {
  return (
    <HeadingRowContainer>
      <BHeading title={title} subTitle={subTitle} />
      <HeadingRowButtonContainer>{children}</HeadingRowButtonContainer>
    </HeadingRowContainer>
  )
}
