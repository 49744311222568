import React from 'react'
import { TelPopperWrapper } from './TelPopper.styled'

type AutocompletePopperProps = {
  open: boolean
}

export const TelPopper = (props: AutocompletePopperProps) => {
  const { open, ...other } = props

  if (!open) {
    return null
  }
  return <TelPopperWrapper {...other} />
}
