import React from 'react'
import { useUserRole } from '../../hooks/useUserRole'
import { AdminEmployee } from './AdminEmployee'
import { UserEmployee } from './UserEmployee'

export const EmployeePage = () => {
  const { isAdminOrSuperAdmin } = useUserRole()
  if (isAdminOrSuperAdmin) {
    return <AdminEmployee />
  } else {
    return <UserEmployee />
  }
}
