import { styled } from '@mui/material/styles'
import { Box, Avatar } from '@mui/material'

export const HeaderContainer = styled(Box)({
  position: 'relative',
  width: '100%',
})

export const BackgroundImage = styled(Box)(() => ({
  height: '160px',
  background: 'linear-gradient(91.1deg, #EE7600 7.94%, #DA2B20 90.29%)',
}))

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 96,
  height: 96,
  outline: `4px solid ${theme.palette.background.default}`,
  fontSize: '38.5px',
  lineHeight: 1.5,
  fontWeight: 500,
  color: theme.palette.text.tertiary_600,
  border: '1px solid #FFFFFF1F',
  backgroundColor: theme.palette.border?.secondary,
}))

export const DetailsContainer = styled(Box)({
  position: 'relative',
  top: '-14px',
  left: '32px',
})
