import React from 'react'
import { FC, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { FileUpload } from '../FileUpload/FileUpload'
import { ReactComponent as CropIcon } from '../../../../../assets/svg/crop.svg'
import { useFormikContext } from 'formik'
import {
  CropperSimpleDialog,
  CropperSlider,
  CropperWrap,
} from './Cropper.styled'

type CropperProps = {
  name: string
}

export const Cropper: FC<CropperProps> = ({ name }) => {
  const [src, setSrc] = useState('')
  const [preview, setPreview] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [scale, setScale] = useState(1)

  const cropRef: React.LegacyRef<AvatarEditor> = useRef(null)

  const { setFieldValue } = useFormikContext()
  const handleImgChange = (file: File | null) => {
    if (!file) {
      return setPreview('')
    }
    setSrc(URL.createObjectURL(file))
    setModalOpen(true)
  }

  const handleSave = async () => {
    if (cropRef.current) {
      const canvas = cropRef.current.getImageScaledToCanvas()

      const base64Image = canvas.toDataURL('image/png')
      setPreview(base64Image)

      await setFieldValue(name, {
        data: base64Image,
        name: 'avatar.png',
      })

      setModalOpen(false)
    }
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setScale(newValue as number)
  }

  return (
    <>
      <FileUpload
        preview={preview}
        name={name}
        onFileSelect={handleImgChange}
        acceptedFileTypes={[
          'image/svg+xml',
          'image/png',
          'image/jpeg',
          'image/gif',
        ]}
        fileTypeDescription={'SVG, PNG, JPG or GIF (max. 800×400px)'}
      />
      <CropperSimpleDialog
        onSubmit={handleSave}
        icon={CropIcon}
        title={'Crop profile image'}
        subTitle={'Upload a 480 x 480px image for best results.'}
        open={modalOpen}
        onClose={handleClose}
        width={'720px'}
        isFullButtons={false}
        inlineTitle={true}
        cancelLabel={'Cancel'}
        submitLabel={'Confirm'}
      >
        <CropperWrap>
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            border={50}
            borderRadius={179}
            color={[255, 255, 255, 0.35]}
            scale={scale}
            rotate={0}
            backgroundColor={'rgba(0, 0, 0, 0.4)'}
          />
          <CropperSlider
            aria-label="Small steps"
            defaultValue={1}
            step={0.1}
            min={1}
            max={3}
            value={scale}
            color={'info'}
            onChange={handleSliderChange}
          />
        </CropperWrap>
      </CropperSimpleDialog>
    </>
  )
}
