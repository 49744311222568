import React, { FC } from 'react'
import { InputBase } from '@mui/material'

export const BDateInput: FC<{
  inputRef: React.RefObject<HTMLInputElement>
  name: string
  value: string
  placeholder: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  sx?: React.CSSProperties
}> = (props) => <InputBase {...props} />
