import { Option } from '../types/common/option'

export const USER_ROLE_OPTIONS: Option[] = [
  { value: 'engineer', label: 'Engineer' },
  // { value: 'manager', label: 'Manager' },
  { value: 'designer', label: 'Designer' },
  { value: 'sales', label: 'Sales' },
  { value: 'doctor', label: 'Doctor' },
]

export const getUserRoleOptions = (isSuperAdmin: boolean): Option[] => {
  return isSuperAdmin
    ? [...USER_ROLE_OPTIONS, { value: 'admin', label: 'Admin' }]
    : USER_ROLE_OPTIONS
}
