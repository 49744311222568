import apiClient from '../utils/api-client'
import { AxiosResponse } from 'axios'
import { CompensationWithPayment } from '../types/compensation/compensation-with-payment'
import { CreateCompensationPayload } from '../types/compensation/create-compensation-payload'
import { UpdateReviewCompensationPayload } from '../types/compensation/update-review-compensation-payload'
import { Compensation } from '../types/compensation/compensation'

export class CompensationService {
  static async getWithPayments(userId: string) {
    const { data } = await apiClient.get<
      AxiosResponse<CompensationWithPayment[]>
    >(`/compensations/with-payment/${userId}`)
    return data
  }

  static async getByUserId(
    userId: string,
    types: string,
    sortField: string,
    sortOrder: string
  ) {
    const { data } = await apiClient.get<AxiosResponse<Compensation[]>>(
      `/compensations/users/${userId}`,
      { params: { types, sortField, sortOrder } }
    )
    return data
  }

  static async create(payload: CreateCompensationPayload) {
    await apiClient.post<AxiosResponse<Record<string, never>>>(
      `/compensations`,
      payload
    )
  }

  static async updateByReviewDate(payload: UpdateReviewCompensationPayload) {
    await apiClient.patch<AxiosResponse<Record<string, never>>>(
      `/compensations`,
      payload
    )
  }

  static async bulkUpsert(payload: CreateCompensationPayload[]) {
    await apiClient.patch<AxiosResponse<Record<string, never>>>(
      `/compensations/upsert-bulk`,
      payload
    )
  }

  static async deleteByIds(payload: number[]) {
    await apiClient.delete<AxiosResponse<Record<string, never>>>(
      `/compensations/by-ids`,
      {
        data: {
          ids: payload,
        },
      }
    )
  }
}
