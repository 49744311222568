import React from 'react'
import { Box } from '@mui/material'
import { StyledArrowIcon } from '../../Select/common'
import { EditActions } from './EditActions'
import { ReactComponent as DividerIcon } from '../../../../../assets/svg/divider.svg'

type IconComponentProps = {
  onSave: () => void
  onCancel: () => void
  className?: string
}

export const IconComponent: React.FC<IconComponentProps> = ({
  onCancel,
  onSave,
  className,
}) => {
  console.log(className)
  return (
    <Box display={'flex'} gap={1} alignItems={'center'} pr={2}>
      <StyledArrowIcon right={'100px'} className={className} />
      <DividerIcon />
      <EditActions onSave={() => onSave()} onCancel={onCancel} />
    </Box>
  )
}
