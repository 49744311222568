import { styled, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'

export const StyledTextField = styled(TextField)<TextFieldProps>(
  ({ theme, multiline }) => ({
    width: '100%',
    boxShadow: '0px 1px 2px 0px #1018280D',
    margin: 0,
    height: multiline ? '100%' : undefined,
    '& .MuiOutlinedInput-root': {
      height: multiline ? '100%' : '44px',
      borderRadius: '8px',
      alignItems: multiline ? 'flex-start' : undefined,
      '& fieldset': {
        borderColor: theme.palette.border?.primary,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.border?.brand,
        // boxShadow: theme.appShadows?.primary_grey_focus
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.border?.brand,
        boxShadow: theme.appShadows?.primary_grey_focus,
      },
      '&.Mui-error fieldset': {
        borderColor: theme.palette.border?.error,
      },
      '& .MuiInputAdornment-root': {
        height: multiline ? 'auto' : undefined,
      },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text?.primary,
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.text?.tertiary_600,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.border?.error,
      marginLeft: 0,
    },

    //disable blue browser autocomplete
    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 30px transparent inset !important',
      WebkitTextFillColor: theme.palette.text?.primary,
      transition: 'background-color 5000s ease-in-out 0s',
    },

    //hide controls type number
    '& input[type="number"]': {
      MozAppearance: 'textfield',
      appearance: 'textfield',
    },
    '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
  })
)
