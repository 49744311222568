export interface AnalyzeCriteria {
  key: string
  label: string
  ai_instructions: string
  default_criteria: boolean
}

export enum CategoryEnum {
  SALES = 'sales',
  MEDICAL = 'medical',
}
