import { styled } from '@mui/system'
import cropBg from '../../../../../assets/crop_bg.jpeg'
import { Box, Slider } from '@mui/material'
import { SimpleDialog } from '../../../Dialog/SimpleDialog'

export const CropperWrap = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${cropBg})`,
  height: '450px',
  marginBottom: theme.spacing(6),
  position: 'relative',
  zIndex: 3,
}))

export const CropperSlider = styled(Slider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& .MuiSlider-thumb': {
    color: theme.palette.button?.primary_bg,
  },
  '& .MuiSlider-track': {
    color: theme.palette.button?.primary_bg,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.border?.primary,
  },
}))

export const CropperSimpleDialog = styled(SimpleDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflow: 'hidden',
  },
}))
