import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

export const BButton = styled(Button)(({ theme, color }) => ({
  boxShadow: theme.appShadows?.primary_grey,
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '14px',
  padding: '10px 14px',
  height: '36px',
  textTransform: 'none',
  ...(color === 'primary' && {
    border: '1px solid',
    borderColor: theme.palette.button?.primary_bg,
    backgroundColor: theme.palette.button?.primary_bg,
    color: theme.palette.button?.primary,
    minWidth: '84px',
    '&:hover': {
      borderColor: theme.palette.button?.primary_hover,
      backgroundColor: theme.palette.button?.primary_hover,
    },
    '&:focus': {
      boxShadow: theme.appShadows?.primary_grey_focus,
    },
  }),

  ...(color === 'secondary' && {
    border: '1px solid ',
    borderColor: theme.palette.border?.primary,
    backgroundColor: theme.palette.background?.paper,
    color: theme.palette.text?.secondary,
    minWidth: '72px',
    '&:hover': {
      backgroundColor: theme.palette.border?.secondary,
    },
    '&:focus': {
      boxShadow: theme.appShadows?.secondary_grey_focus,
      backgroundColor: theme.palette.background?.paper,
    },
    '&:disabled': {
      backgroundColor: theme.palette.background?.default,
      boxShadow: theme.appShadows?.primary_grey,
      color: theme.palette.icon?.fg_quinary_400,
      '& path': {
        stroke: theme.palette.icon?.fg_quinary_400,
      },
    },
  }),
  ...(color === 'info' && {
    border: '1px solid ',
    borderColor: theme.palette.border?.primary,
    backgroundColor: theme.palette.background?.paper,
    color: theme.palette.text?.secondary,
  }),
}))
