import React, { FC } from 'react'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  BTitleDialogTitle,
  BTitleIconButton,
  BTitleTypography,
  BTitleSubtitle,
} from './BTitle.styled'

export type BTitleProps = {
  inlineTitle?: boolean
  title: string
  subTitle?: string
  bgImage?: string
  icon?: FC
  pb?: string
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const BTitle: FC<BTitleProps> = ({
  title,
  subTitle,
  onClose,
  icon: Icon,
  bgImage,
  pb,
  inlineTitle,
}) => {
  return (
    <BTitleDialogTitle pb={pb} inlineTitle={inlineTitle}>
      {Icon && <Icon />}
      {bgImage && (
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
          <img src={bgImage} alt={title} />
        </Box>
      )}
      <BTitleIconButton onClick={onClose}>
        <CloseIcon
          sx={(theme) => ({
            color: theme.palette.icon?.fg_quinary_400,
            zIndex: 1,
          })}
        />
      </BTitleIconButton>
      <Box ml={inlineTitle ? 2 : undefined}>
        {title && (
          <BTitleTypography mt={inlineTitle ? undefined : 2}>
            {title}
          </BTitleTypography>
        )}
        {subTitle && (
          <BTitleSubtitle inlineTitle={inlineTitle} variant={'body2'}>
            {subTitle}
          </BTitleSubtitle>
        )}
      </Box>
    </BTitleDialogTitle>
  )
}
