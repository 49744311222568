import React, { FC } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { FormInput } from '../../ui/Form/FormInput'
import { MultipleEmailsField } from '../../ui/Form/MultipleEmailsField'
import { MultipleTelField } from '../../ui/Form/TelInput/MultipleTelField'
import {
  textLeftAdornment,
  textLeftAdornmentStyles,
} from '../../ui/Form/FormInput/TextLeftAdornment'
import { FlatUser } from '../../../types/user/flat-user'
import { MultiStepModalProps } from '../../../types/multi-step-modal'

export const SecondStep: FC<MultiStepModalProps<FlatUser>> = ({
  onSubmit,
  initialValues,
  formRef,
}) => {
  const validationSchema = Yup.object({
    email: Yup.string().email().required('Email is required'),
    phoneNumber: Yup.string(),
    linkedinUrl: Yup.string(),
    telegram: Yup.string(),
    address: Yup.string(),
    additionalContactEmails: Yup.array().of(
      Yup.string().email('Email not valid').required('Email is required')
    ),
    additionalContactNumbers: Yup.array().of(
      Yup.string().required('Number is required')
    ),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({ values }) => (
        <Form>
          <Grid container flexDirection={'column'} gap={2} p={3} pt={4}>
            <FormInput
              label={'LinkedIn'}
              name={'linkedinUrl'}
              placeholder={'www.linkedin.com/in/johndoe'}
              sx={textLeftAdornmentStyles}
              InputProps={textLeftAdornment(' https://')}
            />
            <MultipleEmailsField
              nameArray={'additionalContactEmails'}
              values={values}
              namePrimary={'email'}
            />
            <MultipleTelField
              nameArray={'additionalContactNumbers'}
              namePrimary={'phoneNumber'}
              values={values}
            />
            <FormInput
              label={'Telegram nickname'}
              name={'telegram'}
              placeholder={'john_doe'}
              sx={textLeftAdornmentStyles}
              InputProps={textLeftAdornment('@')}
            />
            <FormInput
              label={'Address'}
              name={'address'}
              placeholder={'e.g. 123 Street, Apt 4B'}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
