import React, { FC } from 'react'
import { FieldArray, FormikValues } from 'formik'
import { Grid } from '@mui/material'
import { AddAnother } from '../AddAnother'
import { TelInput } from './TelInput'
import { AdditionalTelInput } from './AdditionalTelInput'

type MultipleTelFieldProps = {
  namePrimary: string
  nameArray: string
  values: FormikValues
}

export const MultipleTelField: FC<MultipleTelFieldProps> = ({
  nameArray,
  namePrimary,
  values,
}) => {
  return (
    <Grid container gap={1}>
      <TelInput
        label={'Phone number'}
        name={namePrimary}
        placeholder={'12345678'}
      />
      <FieldArray name={nameArray}>
        {({ push, remove }) => (
          <>
            {values[nameArray]?.map((_: string, index: number) => (
              <AdditionalTelInput
                key={index}
                name={nameArray}
                placeholder={'123456789'}
                index={index}
                remove={remove}
              />
            ))}
            <AddAnother onClick={() => push('')} />
          </>
        )}
      </FieldArray>
    </Grid>
  )
}
