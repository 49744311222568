import { styled } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'

export const Container = styled(Grid)({
  alignItems: 'center',
  flexWrap: 'nowrap',
})

export const UserInfoBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'ml',
})<{ ml: number }>(({ ml, theme }) => ({
  marginLeft: theme.spacing(ml),
  marginRight: 'auto',
  overflow: 'hidden',
}))

export const StyledTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
