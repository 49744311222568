import { Box, Typography } from '@mui/material'
import { addMonths, format, formatDate, isValid, parseISO } from 'date-fns'
import React, { FC } from 'react'

type ProbationEndDateProps = {
  selected?: string
  startWith?: string
  width?: string
  value?: string
}

export const ProbationEndDate: FC<ProbationEndDateProps> = ({
  selected,
  width,
  startWith,
  value,
}) => {
  if (!selected && !value) return null
  const date = parseISO(startWith ?? value ?? '')
  let formatted
  if (value) {
    formatted = format(isValid(date) ? date : new Date(), 'dd/MM/yyyy')
  } else {
    formatted = format(
      isValid(date) ? addMonths(date, Number(selected)) : new Date(),
      'dd/MM/yyyy'
    )
  }

  return (
    <Box
      fontWeight={500}
      sx={{ width: width ?? '185px', flexShrink: 0, flexGrow: 0 }}
    >
      <Typography color={'text.primary'} variant={'body2'}>
        Probation end date
      </Typography>
      <Typography color={'text.primary'} variant={'body1'}>
        {formatted}
      </Typography>
    </Box>
  )
}
