import React from 'react'
import { FC } from 'react'
import { BBadge } from '../../Badge/bBadge/bBadge'
import { TableCell } from './Styled'
import { Typography } from '@mui/material'
import { format } from 'date-fns'

export type ReviewCellProps = {
  reviewDate: Date | null
  isExpectedReview?: boolean
  colSpan?: number
}
export const ReviewCell: FC<ReviewCellProps> = ({
  colSpan,
  isExpectedReview,
  reviewDate,
}) => {
  const padding = isExpectedReview ? '24px' : '12px'
  const badgeType = isExpectedReview ? 'warning' : 'success'
  const badgeLabel = isExpectedReview ? 'Need Review' : 'Reviewed'

  return (
    <TableCell
      colSpan={colSpan}
      gap={1}
      direction={isExpectedReview ? 'column' : undefined}
      paddingY={padding}
    >
      <BBadge badgeType={badgeType} label={badgeLabel} />
      {reviewDate && (
        <Typography fontSize={12} lineHeight={'16px'}>
          {format(reviewDate, 'dd/MM/yyyy')}
        </Typography>
      )}
    </TableCell>
  )
}
