import { useMemo } from 'react'
import { addSpacingToCells } from './addSpacingToCell'
import { MappedCompensation, RowConfig, TableRowType } from './common.types'

const rowConfigs: Record<string, RowConfig> = {
  second: {
    priority: ['review', 'probation_end', 'bonus', 'actions'],
    fallback: 'empty',
  },
  third: { priority: ['probation_end', 'bonus', 'actions'], fallback: 'empty' },
  fourth: { priority: ['bonus', 'actions'], fallback: 'empty' },
  fifth: { priority: ['actions'], fallback: 'empty' },
}

const getCellForType = (
  cellType: string,
  currentKey: string,
  reviewRows: Record<string, TableRowType>,
  probationEndRow: Record<string, TableRowType>,
  bonusRows: Record<string, TableRowType>,
  actionsRows: Record<string, TableRowType>
): TableRowType | null => {
  switch (cellType) {
    case 'review':
      return reviewRows[currentKey] || null
    case 'probation_end':
      return probationEndRow[currentKey] || null
    case 'bonus':
      return bonusRows[currentKey] || null
    case 'actions':
      return actionsRows[currentKey] && !actionsRows[currentKey]?.props?.hide
        ? actionsRows[currentKey]
        : null
    default:
      return null
  }
}

export const useRenderRows = (
  paginatedMonths: MappedCompensation[],
  reviewRows: Record<string, TableRowType>,
  probationEndRow: Record<string, TableRowType>,
  bonusRows: Record<string, TableRowType>,
  actionsRows: Record<string, TableRowType>
) => {
  return useMemo(() => {
    const usedCells: Record<string, Set<string>> = {}

    return Object.entries(rowConfigs).map(([rowType, config]) => {
      const rowCells: TableRowType[] = []

      paginatedMonths.forEach((month) => {
        const currentKey = month.periodIdentifier

        if (!usedCells[currentKey]) {
          usedCells[currentKey] = new Set()
        }

        let cellAdded = false

        for (const cellType of config.priority) {
          if (!usedCells[currentKey].has(cellType)) {
            const cell = getCellForType(
              cellType,
              currentKey,
              reviewRows,
              probationEndRow,
              bonusRows,
              actionsRows
            )

            if (cell) {
              usedCells[currentKey].add(cellType)
              rowCells.push(cell)
              cellAdded = true
              break
            }
          }
        }

        if (
          !cellAdded &&
          config.fallback === 'actions' &&
          !usedCells[currentKey].has('actions')
        ) {
          usedCells[currentKey].add('actions')
          rowCells.push(actionsRows[currentKey])
        }
      })

      const cellsWithSpacing = addSpacingToCells(rowCells, paginatedMonths)

      return { rowType, cells: cellsWithSpacing }
    })
  }, [paginatedMonths, reviewRows, probationEndRow, bonusRows, actionsRows])
}
