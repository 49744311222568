import React, { FC } from 'react'
import { Box } from '@mui/material'
import { EmployeeDetailHeader } from '../../components/employee-details/EmployeeDetailsHeader/EmployeeDetailHeader'
import { FilterTabs } from '../../components/ui/Tabs/FilterTabs/FilterTabs'
import { UserDetail } from '../../components/employee-details/UserDetail/UserDetail'
import { Wrap } from '../../layout/root/Wrap'
import { Compensation } from '../../components/employee-details/compensation/Compensation/Compensation'
import { EmployeeDocuments } from '../../components/employee-details/documents/EmployeeDocuments'
import { InteractiveProvider } from '../../providers/interactive.provider'
import { FlatUser } from '../../types/user/flat-user'

type EmployeeDetailsProps = {
  user: FlatUser | null
}

export const EmployeeDetailsPage: FC<EmployeeDetailsProps> = ({ user }) => {
  const tabsData = [
    { title: 'Personal & Professional Details' },
    { title: 'Compensation' },
    { title: 'Documents' },
  ]

  return (
    <Wrap bg={'default'}>
      {user && (
        <>
          <EmployeeDetailHeader user={user} />
          <Box
            sx={{
              paddingTop: '8px',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <InteractiveProvider>
              <FilterTabs tabs={tabsData} wrapperMarginX={4} tabKey={'tab-key'}>
                <UserDetail user={user} />
                <Compensation user={user} />
                <EmployeeDocuments user={user} />
              </FilterTabs>
            </InteractiveProvider>
          </Box>
        </>
      )}
    </Wrap>
  )
}
