import React from 'react'
import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Wrap } from '../layout/root/Wrap'

export const DashboardPage: FC = () => {
  return (
    <Wrap bg={'default'}>
      <Box sx={{ padding: '24px', pt: 4 }}>
        <Typography
          fontWeight={600}
          fontSize={30}
          lineHeight={'38px'}
          color={'text.primary'}
        >
          Dashboard
        </Typography>
      </Box>
    </Wrap>
  )
}
