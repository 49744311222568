import { FC } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import * as React from 'react'
import { ReactComponent as DividerIcon } from '../../../../../assets/svg/divider.svg'
import { ReactComponent as CancelIcon } from '../../../../../assets/svg/cancel.svg'
import { ReactComponent as CheckIcon } from '../../../../../assets/svg/check-circle.svg'

type EditActionsProps = {
  onSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const EditActions: FC<EditActionsProps> = ({ onCancel, onSave }) => {
  return (
    <InputAdornment
      sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
      position="end"
    >
      <IconButton onMouseDown={onSave} sx={{ p: 0.3 }}>
        <CheckIcon />
      </IconButton>
      <DividerIcon />
      <IconButton onMouseDown={onCancel} sx={{ p: 0.3 }}>
        <CancelIcon />
      </IconButton>
    </InputAdornment>
  )
}
