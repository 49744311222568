import { FC, ReactNode, useState } from 'react'
import { Box, ClickAwayListener, SxProps } from '@mui/material'
import * as React from 'react'
import { ReactComponent as SaveIcon } from '../../../../../assets/svg/save-changes.svg'
import { SimpleDialog } from '../../../Dialog/SimpleDialog'

type InputWrapProps = {
  changeEditing: () => void
  onSubmit: (data: string) => Promise<void>
  value: string
  children: (setSelectOpen?: () => void) => ReactNode
  sx?: SxProps
}

export const InputWrap: FC<InputWrapProps> = ({
  value,
  onSubmit,
  changeEditing,
  children,
  sx,
}) => {
  const [open, setOpen] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    changeEditing()
  }

  const handleClickAway = () => {
    if (!selectOpen) {
      setOpen(true)
    }
  }
  const handleToggleSelect = () => setSelectOpen((prev) => !prev)

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={sx}>{children(handleToggleSelect)}</Box>
      </ClickAwayListener>
      <SimpleDialog
        width={'400px'}
        title={'Unsaved changes'}
        subTitle={'Do you want to save or discard changes?'}
        open={open}
        icon={SaveIcon}
        onClose={handleClose}
        onSubmit={() => onSubmit(value)}
        cancelLabel={'Discard'}
        submitLabel={'Save changes'}
      />
    </>
  )
}
