import React, { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { DialogTree } from '../../ui/Dialog/DialogTree/DialogTree'
import { speechToTextOptionSelector } from '../../../store/selector/sales-call'
import {
  addTranscription,
  createSalesCall,
  getSpeechToTextOptions,
} from '../../../store/action/sales-call'
import { ModalFooter } from '../../employee/Modal/ModalFooter'
import { FormikProps } from 'formik/dist/types'
import {
  medicalInitialValues,
  medicalModalSteps,
  NewMedicalType,
} from './medical.modal.config'
import {
  addMedicalTranscription,
  createMedical,
} from '../../../store/action/medical'

type AddSalesCallModalProps = {
  onClose: () => void
  newItem: NewMedicalType
  setNewItem: (newCall: NewMedicalType) => void
}

export const AddMedicalModalContent: FC<AddSalesCallModalProps> = ({
  newItem,
  onClose,
  setNewItem,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [medicalId, setMedicalId] = useState<string | null>(null)
  const formRef = useRef<FormikProps<Partial<NewMedicalType>>>(null)
  const dispatch = useAppDispatch()
  const speechToTextOptions = useAppSelector(speechToTextOptionSelector)

  useEffect(() => {
    if (speechToTextOptions.length > 1) return
    dispatch(getSpeechToTextOptions())
  }, [dispatch, speechToTextOptions])

  const handleCreateMedical = async (newData: Partial<NewMedicalType>) => {
    try {
      const res = await dispatch(
        createMedical({ ...newItem, ...newData })
      ).unwrap()
      setMedicalId(res.id?.toString())
      setActiveIndex((prev) => prev + 1)
      setNewItem({ ...newItem, ...newData })
    } catch (e) {
      console.error(e)
    }
  }

  const handleAddTranscription = async (newData: Partial<NewMedicalType>) => {
    if (!medicalId || !newData.audio_link) {
      console.error('Sales call ID or link not found')
      return
    }

    const transcriptionData = {
      speakerNumber: newData.count_of_speakers as string,
      speechToTextSource: newData.text_source as string,
      audioLink: newData.audio_link as string,
    }

    dispatch(
      addMedicalTranscription({
        ...transcriptionData,
        id: medicalId,
      })
    )

    onClose()
    setNewItem(medicalInitialValues)
  }

  const handleNextStep = async (newData: Partial<NewMedicalType>) => {
    if (activeIndex === 0) {
      await handleCreateMedical(newData)
    } else {
      await handleAddTranscription(newData)
    }
  }

  const triggerSubmit = async () => {
    if (formRef.current) {
      await formRef.current.submitForm()
    }
  }

  const { component: StepComponent } = medicalModalSteps[activeIndex]
  return (
    <>
      <DialogTree
        steps={medicalModalSteps.map((step) => step.title)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />

      <StepComponent
        formRef={formRef}
        onSubmit={handleNextStep}
        initialValues={newItem}
        onClose={onClose}
      />
      <ModalFooter
        onClose={onClose}
        onSubmit={triggerSubmit}
        submitLabel={activeIndex === 0 ? 'Next' : 'Add Call'}
      />
    </>
  )
}
