export const LEVEL_OPTIONS = [
  { value: 'Intern' },
  { value: 'Junior' },
  { value: 'Strong Junior' },
  { value: 'Middle' },
  { value: 'Strong Middle' },
  { value: 'Senior' },
  { value: 'Strong Senior' },
  { value: 'Lead' },
]
