import { ModalSteps } from '../../../types/multi-step-modal'
import { AddCallFirstStep } from './AddCallFirstStep'
import { AddCallSecondStep } from './AddCallSecondStep'

export const acceptedAudioFileTypes = [
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'audio/aac',
  'audio/flac',
]
export const FILE_SIZE_LIMIT = 100 * 1024 * 1024

export const salesCallInitialValues = {
  title: '',
  call_date: '',
  sales_company_details: '',
  sales_person_details: '',
  client_company_details: '',
  client_person_details: '',
  additional_information: '',
  salesCall: null,
  text_source: '',
  count_of_speakers: '',
  audio_link: '',
}
export type NewSalesCallType = typeof salesCallInitialValues

export const salesCallModalSteps: ModalSteps<NewSalesCallType> = [
  {
    title: 'Sales call details',
    component: AddCallFirstStep,
  },
  {
    title: 'Upload audio',
    component: AddCallSecondStep,
  },
]
