import { UserRole } from '../../../types/user/user-role'

export const AddEmployeeInitialState = {
  firstName: '',
  lastName: '',
  role: '' as UserRole,
  birthday: '',
  avatar: '' as unknown as File,

  email: '',
  phoneNumber: '',
  linkedinUrl: '',
  telegram: '',
  address: '',
  additionalContactEmails: [] as string[],
  additionalContactNumbers: [] as string[],

  positionLevel: '',
  jobTitle: '',
  recruitmentSource: '',
  reportToId: '',
  hrId: '',
  recruiterId: '',
  projectId: '',
  userProjectRole: '',

  employmentType: '',
  probationEndDate: '',
  startWorkDate: '',
  compensationEndDate: '',
  compensationAmount: '' as unknown as number,
}
