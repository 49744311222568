import { TableCell } from './Styled'
import { BBadge } from '../../Badge/bBadge/bBadge'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { FC } from 'react'

export type ProbationPeriodCellProps = {
  probationPeriodEnd?: Date
  colSpan?: number
}
export const ProbationPeriodCell: FC<ProbationPeriodCellProps> = ({
  probationPeriodEnd,
  colSpan,
}) => {
  return (
    <TableCell paddingY={'12px'} colSpan={colSpan}>
      <BBadge label={'Probation End'} badgeType={'warning'} />
      <Typography fontSize={12} lineHeight={2}>
        {probationPeriodEnd ? format(probationPeriodEnd, 'dd/MM/yyyy') : 'N/A'}
      </Typography>
    </TableCell>
  )
}
