import React, { FC } from 'react'
import { Box } from '@mui/material'
import { BTableStyled } from './bTable.styled'
import { BPagination } from '../bPagination/bPagination'
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps'

type BTableProps = DataGridProps & React.RefAttributes<HTMLDivElement>

export const BTable: FC<BTableProps> = (props) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        flex: 1,
        minHeight: '0',
        width: '100%',
        height: '100%',
      }}
    >
      <BTableStyled
        disableColumnMenu
        columnHeaderHeight={44}
        rowHeight={72}
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        {...props}
        slots={{
          pagination: BPagination,
          ...props.slots,
        }}
      />
    </Box>
  )
}
