import React from 'react'
import { ReactComponent as WarningIcon } from '../../../../assets/svg/warning.svg'
import { BBadgeProps, StyledBadge } from './bBadge.styled'

export const BBadge: React.FC<BBadgeProps> = ({
  badgeType,
  rounded = 'full',
  label,
  sx,
  ...rest
}) => {
  const showWarningIcon = label === 'Needs Assessment'

  return (
    <StyledBadge
      sx={sx}
      badgeType={badgeType}
      rounded={rounded}
      label={label}
      deleteIcon={showWarningIcon ? <WarningIcon /> : undefined}
      onDelete={showWarningIcon ? () => {} : undefined}
      {...rest}
    />
  )
}
