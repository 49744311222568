import { styled } from '@mui/system'
import React, { FC } from 'react'
import { Box, useTheme } from '@mui/material'

export const TableWrapper = styled('table')(() => {
  const theme = useTheme()
  return {
    width: '100%',
    borderCollapse: 'collapse',
    outline: '1px solid',
    outlineColor: theme.palette.border?.secondary,
    borderRadius: '12px',
    overflow: 'hidden',
    color: theme.palette.text.tertiary_600,
    tableLayout: 'fixed',
    fontFamily: theme.typography?.fontFamily,
  }
})

export const TableHeader = styled('thead')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '28px',
  fontSize: '12px',
  lineHeight: '18px',
  // borderBottom: '1px solid',
  // borderColor: theme.palette.border.secondary,
}))

export const TableBody = styled('tbody')(({ theme }) => ({
  backgroundColor: theme.palette.background.primary,
  // '& > tr:nth-child(even)': {
  //   backgroundColor: '#2a2a3a',
  // },
}))

export const TableRow = styled('tr')(({ theme }) => ({
  '& > th:not(:last-child)': {
    borderRight: '1px solid',
    borderColor: theme.palette.border.secondary,
  },
}))

export const TableHeaderCell = styled('th')(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 500,
  borderBottom: '1px solid',
  borderColor: theme.palette.border.secondary,
}))

const StyledCeil = styled('td')(({ theme }) => ({
  padding: '5px 0px',
  border: '1px solid',
  borderColor: theme.palette.border.secondary,
}))
type TableCellProps = {
  children?: React.ReactNode
  direction?: 'row' | 'column'
  gap?: string | number
  paddingY?: string
  colSpan?: number
}
export const TableCell: FC<TableCellProps> = ({
  children,
  direction = 'column',
  gap = 0.5,
  paddingY,
  colSpan = 1,
}) => {
  return (
    <StyledCeil sx={{ paddingY }} colSpan={colSpan}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: direction,
          alignItems: 'center',
          justifyContent: 'center',
          gap,
        }}
      >
        {children}
      </Box>
    </StyledCeil>
  )
}
