import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const HeadingRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}))

export const HeadingRowButtonContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: 'flex-start',
  gap: theme.spacing(1),
}))
