import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { BBadge } from '../../../Badge/bBadge/bBadge'

export const BonusCellStyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '2',
  color: theme.palette.text.tertiary_600,
}))

export const BonusCellStyledBadge = styled(BBadge, {
  shouldForwardProp: (prop) => prop !== 'salaryPaymentDate',
})<{ salaryPaymentDate: Date | null }>(({ theme, salaryPaymentDate }) => ({
  '& .MuiChip-icon path': {
    stroke: salaryPaymentDate ? theme.palette.icon?.success_500 : undefined,
  },
}))
