import { Link } from 'react-router-dom'
import React, { FC } from 'react'
import { Button } from '@mui/material'
import { ViewStyled } from '../../ui/Button/ViewStyled'

type EmployeeLinkProps = {
  to: string
  target?: string
}

export const EmployeeLink: FC<EmployeeLinkProps> = ({ to, target }) => {
  return (
    <Button
      disableRipple
      sx={ViewStyled}
      component={Link}
      to={to}
      target={target}
    >
      View
    </Button>
  )
}
