import { MappedCompensation, TableRowType } from './common.types'

export const addSpacingToCells = (
  cells: TableRowType[],
  totalCells: MappedCompensation[]
): TableRowType[] => {
  const totalColumns = totalCells.length * 2

  if (cells.length === 0) {
    return [createEmptyCell(totalColumns)]
  }

  if (cells.length === 1) {
    return handleSingleCell(cells[0], totalCells, totalColumns)
  }

  return handleMultipleCells(cells, totalCells, totalColumns)
}

const createEmptyCell = (colSpan: number): TableRowType => ({
  type: 'empty',
  colSpan,
  props: {},
})

const findCellIndex = (
  cell: TableRowType,
  totalCells: MappedCompensation[]
): number =>
  totalCells.findIndex(
    (item) => cell.periodIdentifier === item?.periodIdentifier
  )

const handleSingleCell = (
  cell: TableRowType,
  totalCells: MappedCompensation[],
  totalColumns: number
): TableRowType[] => {
  const index = findCellIndex(cell, totalCells)
  const leftSpan = index * 2
  const rightSpan = totalColumns - cell.colSpan - leftSpan

  return [createEmptyCell(leftSpan), cell, createEmptyCell(rightSpan)].filter(
    (cell) => cell.colSpan > 0
  )
}

const handleMultipleCells = (
  cells: TableRowType[],
  totalCells: MappedCompensation[],
  totalColumns: number
): TableRowType[] => {
  const result: TableRowType[] = []
  let currentColumn = 0

  cells.forEach((cell, index) => {
    if (index === 0) {
      const leftSpan = findCellIndex(cell, totalCells) * 2
      if (leftSpan > 0) {
        result.push(createEmptyCell(leftSpan))
        currentColumn += leftSpan
      }
    }

    result.push(cell)
    currentColumn += cell.colSpan

    if (index < cells.length - 1) {
      const nextCellIndex = findCellIndex(cells[index + 1], totalCells)
      const spacingSpan = nextCellIndex * 2 - currentColumn
      if (spacingSpan > 0) {
        result.push(createEmptyCell(spacingSpan))
        currentColumn += spacingSpan
      }
    }
  })

  if (currentColumn < totalColumns) {
    result.push(createEmptyCell(totalColumns - currentColumn))
  }

  return result
}
