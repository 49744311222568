import apiClient from '../utils/api-client'
import { AxiosResponse } from 'axios'
import { Payment } from '../types/payment/payment'
import { CreatePaymentPayload } from '../types/payment/create-payment-payload'

export class PaymentService {
  static async getAllByUser(userId: string) {
    const { data } = await apiClient.get<AxiosResponse<Payment[]>>(
      `/payments/users/${userId}`
    )
    return data
  }

  static async create(payload: CreatePaymentPayload) {
    await apiClient.post<AxiosResponse<Record<string, never>>>(
      `/payments`,
      payload
    )
  }
}
