import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { FormInput } from '../FormInput'
import { useDateInput } from '../../../../hooks/useDateInput'
import { formatDate } from '../../../../utils/format-date'

export type BirthdayInputProps = {
  name: string
  label: string
}

export const FormDateInput: FC<BirthdayInputProps> = ({ name, label }) => {
  const {
    dayRef,
    monthRef,
    yearRef,
    meta,
    handleChange,
    handleKeyDown,
    handleBlur,
    value,
    getDateParts,
  } = useDateInput({ name, initialValue: '', useFormik: true })
  const [year, month, day] = getDateParts(value)
  const dateFields = [
    {
      name: 'day',
      placeholder: 'DD',
      min: 1,
      max: 31,
      ref: dayRef,
      format: day,
    },
    {
      name: 'month',
      placeholder: 'MM',
      min: 1,
      max: 12,
      ref: monthRef,
      format: month,
    },
    {
      name: 'year',
      placeholder: 'YYYY',
      min: 1900,
      ref: yearRef,
      format: year,
    },
  ]

  return (
    <Box>
      <Typography
        fontWeight={500}
        mb="6px"
        color="text.secondary"
        variant="body2"
      >
        {label}
      </Typography>
      <Box display={'flex'} sx={{ gap: 1.5 }}>
        {dateFields.map((field) => (
          <FormInput
            key={field.name}
            inputRef={field.ref}
            name={`${name}.${field.name}`}
            placeholder={field.placeholder}
            type="number"
            inputProps={{ min: field.min, max: field.max }}
            value={formatDate(field.format)}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            error={meta?.touched && !!meta.error}
          />
        ))}
      </Box>
      {meta?.touched && meta.error ? (
        <Typography
          color="border.error"
          fontSize={12}
          marginX={'14px'}
          mt="4px"
        >
          {meta.error}
        </Typography>
      ) : null}
    </Box>
  )
}
