import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompensationWithPayment } from '../../types/compensation/compensation-with-payment'
import { getCompensationByUserId, getWithPayment } from '../action/compensation'
import { Compensation } from '../../types/compensation/compensation'

export interface CompensationSlice {
  mappedList: CompensationWithPayment[]
  listByUser: Compensation[]
}

const initialState: CompensationSlice = {
  mappedList: [],
  listByUser: [],
}

export const compensationSlice = createSlice({
  name: 'compensation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getWithPayment.fulfilled,
        (state, { payload }: PayloadAction<CompensationWithPayment[]>) => {
          state.mappedList = payload
        }
      )
      .addCase(getWithPayment.rejected, (state) => {
        state.mappedList = []
      })
      .addCase(
        getCompensationByUserId.fulfilled,
        (state, { payload }: PayloadAction<Compensation[]>) => {
          state.listByUser = payload
        }
      )
  },
})

// export const {  } = compensationSlice.actions
export default compensationSlice.reducer
