import { addMonths, parseISO } from 'date-fns'

/**
 * Computes the end date of a probation period by adding a specified number of months to the current date.
 *
 * @param month - The number of months to add to the current date.
 * @param startWith
 * @returns The iso time of the probation end date.
 */
export function getProbationPeriod(
  month: number | string,
  startWith?: string
): string {
  if (typeof month !== 'number' || isNaN(month) || month < 0) {
    month = 0
  }
  const probationEndDate = startWith
    ? parseISO(startWith).toISOString()
    : new Date()

  return addMonths(probationEndDate, month).toISOString()
}
