import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  FilterTabsContainer,
  FilterTabsTab,
  FilterTabsTabPanel,
  FilterTabsWrapper,
} from './FilterTabs.styled'
import { Property } from 'csstype'
import { getIntParam } from '../../../../utils/get-int-param'
import { BTabsLabel } from '../bTabsLabel'
import { useInteractiveModal } from '../../../../providers/interactive.provider'

type TabItem = {
  title: string
  value?: string
  [key: string]: unknown
}

type FilterTabsProps = {
  tabs: TabItem[]
  wrapperMarginX?: number
  Label?: FC<TabItem>
  children?: ReactNode[]
  width?: Property.Width | undefined
  onChange?: (index: number) => void
  tabKey: string
}

export const FilterTabs: FC<FilterTabsProps> = ({
  tabs,
  Label,
  children,
  width,
  wrapperMarginX,
  onChange,
  tabKey,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeId, setActiveId] = useState(() =>
    getIntParam(searchParams.get(tabKey))
  )

  useEffect(() => {
    if (onChange) onChange(activeId)
  }, [activeId, onChange])

  useEffect(() => {
    setActiveId(getIntParam(searchParams.get(tabKey)))
  }, [searchParams, tabKey])
  const { isExistEditing } = useInteractiveModal()
  const handleChange = (event: React.SyntheticEvent, id: number) => {
    if (isExistEditing) {
      return
    }
    setActiveId(id)
    setSearchParams({
      ...searchParams,
      [tabKey]: id.toString(),
    })
    if (onChange) onChange(id)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <>
      <FilterTabsContainer mx={wrapperMarginX} sx={{ width }}>
        <FilterTabsWrapper
          value={activeId}
          onChange={handleChange}
          aria-label="filter tabs"
        >
          {tabs.map((item, index) => (
            <FilterTabsTab
              key={index}
              label={Label ? <Label {...item} /> : <BTabsLabel {...item} />}
              value={index}
              {...a11yProps(index)}
            />
          ))}
        </FilterTabsWrapper>
      </FilterTabsContainer>
      {children && (
        <FilterTabsTabPanel
          role="tabpanel"
          id={`simple-tabpanel-${activeId}`}
          aria-labelledby={`simple-tab-${activeId}`}
        >
          {children[activeId]}
        </FilterTabsTabPanel>
      )}
    </>
  )
}
