import { useAppDispatch, useAppSelector } from '../store'
import { analyzeCriteriaSelector } from '../../store/selector/sales-call'
import { getSalesAnalyzeCriteria } from '../../store/action/sales-call'
import React from 'react'
import { CategoryEnum } from '../../types/sales/analyze-criteria'

export const useAnalyzeCriteria = (category: CategoryEnum) => {
  const [selectedCriteria, setSelectedCriteria] = React.useState<string[]>([])
  const analyzeCriteria = useAppSelector(analyzeCriteriaSelector)
  const dispatch = useAppDispatch()

  const initializeCriteria = React.useCallback(() => {
    if (analyzeCriteria) {
      setSelectedCriteria(
        analyzeCriteria
          .filter(({ default_criteria }) => default_criteria)
          .map(({ key }) => key)
      )
    }
  }, [analyzeCriteria])

  React.useEffect(() => {
    dispatch(getSalesAnalyzeCriteria(category))
  }, [category, dispatch])

  React.useEffect(() => {
    initializeCriteria()
  }, [initializeCriteria])

  const toggleCriteria = (criteria: string) => {
    setSelectedCriteria((prev) =>
      prev.includes(criteria)
        ? prev.filter((item) => item !== criteria)
        : [...prev, criteria]
    )
  }

  const resetCriteria = () => {
    initializeCriteria()
  }

  return {
    selectedCriteria,
    toggleCriteria,
    resetCriteria,
    analyzeCriteria,
  }
}
