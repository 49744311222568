import React, { FC, useMemo } from 'react'
import { StyledTextField } from '../../TextFiled/TextField.styled'
import * as Yup from 'yup'
import { InputAdornment, SvgIcon } from '@mui/material'
import { ReactComponent as DividerIcon } from '../../../../assets/svg/divider.svg'
import { EditActions } from '../InteractiveField/components/EditActions'
import { useDateInput } from '../../../../hooks/useDateInput'
import { BDateInput } from './bDateInput'

interface DateInputProps {
  initialValue: string
  onSave: (data: string) => void
  onCancel: () => void
  validationSchema?: Yup.Schema<unknown>
  error: string | null
  setValue: (value: string) => void
}

const DividerSvgIcon: FC = () => (
  <SvgIcon
    inheritViewBox={true}
    component={DividerIcon}
    sx={{
      width: '1px',
    }}
  />
)

export const DateInput: FC<DateInputProps> = ({
  onSave,
  onCancel,
  initialValue,
  error,
  setValue,
}) => {
  const {
    dayRef,
    monthRef,
    yearRef,
    value,
    handleChange,
    handleKeyDown,
    handleBlur,
  } = useDateInput({
    name: '',
    initialValue: initialValue?.split('T')?.[0] || '',
    onChange: setValue,
    useFormik: false,
  })

  const [year, month, day] = useMemo(() => value.split('-'), [value])

  const renderInputAdornment = () => (
    <InputAdornment position="start">
      <BDateInput
        inputRef={dayRef}
        name="day"
        value={day}
        placeholder="DD"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        sx={{ width: '30px', paddingRight: '10px', boxSizing: 'content-box' }}
      />
      <DividerSvgIcon />
      <BDateInput
        inputRef={monthRef}
        name="month"
        value={month}
        placeholder="MM"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        sx={{ width: '30px', padding: '0 20px', boxSizing: 'content-box' }}
      />
      <DividerSvgIcon />
    </InputAdornment>
  )

  return (
    <StyledTextField
      ref={yearRef}
      name="year"
      fullWidth
      size="small"
      placeholder="YYYY"
      error={Boolean(error)}
      helperText={error}
      value={year}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      type="number"
      sx={{
        '& .MuiInputBase-inputAdornedEnd': {
          paddingLeft: '10px',
        },
      }}
      InputProps={{
        startAdornment: renderInputAdornment(),
        endAdornment: (
          <EditActions onSave={() => onSave(value)} onCancel={onCancel} />
        ),
      }}
    />
  )
}
